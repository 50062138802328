<template>
  <el-dialog
    v-model="DLGVisible"
    :before-close="closeDLG"
    width="40%"
    destroy-on-close
    class="base-dialog"
  >
    <template #header>
      <BaseDialogTitle :title="DLGTitle" :item-name="DLGDictionaryName" />
    </template>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item label="编码名称" prop="name">
        <el-input v-model="ruleForm.name" placeholder="请输入编码名称" />
      </el-form-item>
      <el-form-item label="编码逻辑" prop="logic">
        <el-input v-model="ruleForm.logic" placeholder="请输入编码逻辑" />
      </el-form-item>
      <el-form-item>
        <span>支持且&&，或||，否定!! 逻辑和()</span>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button
          type="primary"
          :disabled="saveButtonDisable"
          @click="handleClickSave"
        >
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, defineExpose } from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import { DICTIONARY_LANGUAGE_LIST } from "@/constant/dictionary.js";
import { ElMessage } from "element-plus";
import { Debounce } from "@/utils/utils";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
import {
  isAllValid,
  isDoubleMatch,
  isLogicSymbolTwice
} from "@/utils/string.js";
const route = useRoute();
const emits = defineEmits(["refresh"]);
const saveButtonDisable = ref(false);
const ruleFormRef = ref(null);

// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("新增编码");
const DLGDictionaryName = route.query.dictionaryName;
const openDLG = (params) => {
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  resetForm();
};
const showSaveButtonDisable = () => {
  saveButtonDisable.value = true;
};
const hideSaveButtonDisable = () => {
  saveButtonDisable.value = false;
};
const ruleForm = ref({
  name: "",
  logic: "",
  objectId: route.params.dictionaryId
});
//输入信息规则
const validateLogic = (rule, value, callback) => {
  console.log("validate", rule, value, callback);
  if (!isAllValid(value)) {
    callback(new Error("存在非法字符"));
  }
  if (!isDoubleMatch(value)) {
    callback(new Error("括号不匹配"));
  }
  if (!isLogicSymbolTwice(value)) {
    callback(new Error("逻辑关系符号（&、|、!）不成对出现"));
  }
  callback();
};
const rules = ref({
  name: [{ required: true, message: "请输入词典名称", trigger: "change" }],
  logic: [
    { required: true, message: "请输入编码逻辑" },
    { validator: validateLogic, trigger: ["change", "blur"] }
  ]
});

// 重置信息
const resetForm = () => {
  ruleFormRef.value.resetFields();
};
const handleClickSave = () => {
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      handleClickSaveDictionary();
    }
  });
};
// 保存功能接口调用
const handleClickSaveDictionary = async (text, row) => {
  showSaveButtonDisable();
  let url = "/config/encoding/dictionary/encoding/save";
  let params = {
    ...ruleForm.value
  };
  let res = await httpPost(url, params);
  if (res.code === 0) {
    emits("refresh");
    closeDLG();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    // row.ifShowLoading = false;
  }
  // else if(res.code===){
  //   ElMessage.warning(res.message);

  // }
  hideSaveButtonDisable();
};
defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped></style>
