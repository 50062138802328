class Dictionary {
  constructor({
    name = "",
    userId = null,
    createTime = null,
    ifEdit = false,
    objectId = null
  }) {
    this.name = name;
    this.userId = userId;
    this.createTime = createTime;
    this.ifEdit = ifEdit;
    this.objectId = objectId;
  }
}
export default Dictionary;
