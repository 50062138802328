export const RESOURCE_TYPE = {
  //文本库
  RESOURCE_LIBRARY: 1,
  //分析
  RESOURCE_ANALYSIS: 2,
  //文本库文件夹
  RESOURCE_LIBRARY_FOLDER: 3,
  //分析文件夹
  RESOURCE_ANALYSIS_FOLDER: 4,
  //记录
  RESOURCE_RECORD: 5,
  //分析模板
  RESOURCE_ANALYSIS_TEMPLATE: 6,
  //分词词典
  RESOURCE_TOKENIZE_DICTIONARY: 7,
  //情感词典
  RESOURCE_SENTIMENT_DICTIONARY: 8,
  //编码词典
  RESOURCE_ENCODING_DICTIONARY: 9,
  //事件词典
  RESOURCE_EVENT_DICTIONARY: 10,
  //常用词语表
  RESOURCE_COMMON_WORD_TABLE: 11,
  //分类训练集
  RESOURCE_CATEGORY_TRAIN_DATASET: 12,
  //分类模型
  RESOURCE_CATEGORY_MODEL: 13
};
