const CONFIG_MAP_LIST = [
  // path为页面跳转路径
  {
    name: "分词词典",
    digest:
      "中文的分词存在歧异，需要进行调整以满足需求。用户可以自定义地添加约束规则，以便得到更符合自己要求的结果。",
    url: require("@/assets/imgs/1-gray.png"),
    path: "word-segmentation-dictionary"
  },
  {
    name: "情感词典",
    digest:
      "创建用户想要的词典，在不同的词典中可以设置添加不同的词汇，而且可以定义每个词汇的分数。",
    url: require("@/assets/imgs/2-gray.png"),
    path: "/sentiment-dictionary"
  },
  {
    name: "编码词典",
    digest:
      "自动编码功能使用的配置，可以在此处自定义规则，对文本进行自定义编码。",
    url: require("@/assets/imgs/3-gray.png"),
    path: "/encoding-dictionary"
  },
  {
    name: "事件词典",
    digest:
      "管理事件的分类规则。在此处对事件的实施者、动作、承受者进行定义，从而捕获具有对应特征的事件。",
    url: require("@/assets/imgs/4-gray.png"),
    path: "/event-dictionary"
  },
  {
    name: "常用词语表",
    digest:
      "管理常用的关键词组合。关键词组合可作为文本输入，用于对特定关键词进行词频统计、相似分析、共现分析等。",
    url: require("@/assets/imgs/5-gray.png"),
    path: "/common-word-dictionary"
  },

  {
    name: "分类训练集",
    digest:
      "创建用户想要的规则，在规则中添加不同的分类，在每个分类中可以添加一条或者多条不同的文本进行训练。",
    url: require("@/assets/imgs/6-gray.png"),
    path: "/category-data-set"
  }
];
export { CONFIG_MAP_LIST };
