<template>
  <div>
    <PageTitle :title="title" />
    <div class="latest-list-box">
      <el-tabs v-model="activeTab">
        <el-tab-pane
          v-for="tabItem in tabList"
          :key="tabItem.name"
          :label="tabItem.label"
          :name="tabItem.name"
        >
          <template #label>
            <div>
              <span>
                <i :class="['iconfont', tabItem.icon, 'tab-icon']"></i>
                <span>{{ tabItem.label }}</span>
                <el-popover
                  placement="top-start"
                  :width="200"
                  trigger="hover"
                  :content="tabItem.content"
                >
                  <template #reference>
                    <i class="iconfont icon-wenhao"></i>
                  </template>
                </el-popover>
              </span>
            </div>
          </template>
          <div>
            <component
              :is="tabItem.component"
              ref="componentRef"
              :info="dictionaryInfo"
            ></component>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script setup>
import {
  onMounted,
  ref,
  defineProps,
  defineEmits,
  shallowRef,
  watch,
  nextTick
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";

import PageTitle from "@/components/base/PageTitle.vue";
import EventsTable from "./components/EventsTable.vue";
import SynonymWordsTable from "./components/SynonymWordsTable.vue";
const route = useRoute();
const title = ref(route.query.dictionaryName);
const objectId = ref(route.params.dictionaryId);
const dictionaryInfo = ref(null);
const getDictionaryInfo = async () => {
  let params = {
    objectId: objectId.value
  };
  let url = "/config/event/dictionary/get/name";
  let res = await httpPost(url, params);
  dictionaryInfo.value = res.data;
};
getDictionaryInfo();
// 面包屑
const emits = defineEmits(["setBreadcrumbList"]);
const setBreadcrumbList = () => {
  let BreadcrumbList = [
    { title: "配置", to: { name: "AnalysisConfigIndex" } },
    { title: "事件词典", to: { name: "EventDictionaryIndex" } },
    { title: title },
    { title: "数据" }
  ];
  emits("setBreadcrumbList", BreadcrumbList);
};
watch(
  () => title,
  (newVal) => {
    setBreadcrumbList();
  },
  {
    immediate: true
  }
);
const EVENTS = 1;
const SYNONYM_WORDS = 2;
const activeTab = ref(EVENTS);
const tabList = ref([
  {
    icon: "icon-eventnote",
    label: "事件",
    name: EVENTS,
    content: "事件是由一系列近义动词及其动宾关系构成的某一类行为",
    component: shallowRef(EventsTable)
  },
  {
    icon: "icon-wenbenku-congwenjiandaoru",
    label: "同义词",
    name: SYNONYM_WORDS,
    content:
      "同义词是将词义相近的动词或名词组成同义词集，在事件规则中可以直接引用",
    component: shallowRef(SynonymWordsTable)
  }
]);
</script>

<style lang="scss" scoped>
.el-tabs {
  --el-tabs-header-height: 60px;
}
.latest-list-box {
  .tab-icon {
    vertical-align: middle;
    font-size: 24px;
    margin-right: 8px;
  }
  span {
    font-size: 14px;
  }
  .icon-wenhao {
    font-size: 10px;
    margin-left: 5px;
  }
}
</style>
