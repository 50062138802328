<template>
  <div class="list-table">
    <div class="option-bar">
      <div class="button-group">
        <span>
          <el-button
            class="button-item"
            type="primary"
            @click="handleClickAddSentimentDictionaryData"
            v-show="!ifSystemUser"
          >
            新增
          </el-button>

          <el-dropdown
            class="button-item"
            @command="handleClickBatchCommand"
            v-show="!ifSystemUser"
          >
            <el-button>
              批量操作
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="batchImport">
                  批量导入
                </el-dropdown-item>
                <el-dropdown-item command="batchDelete">
                  批量删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button
            class="button-item"
            @click="handleClickBatchExport"
            v-show="!ifSystemUser"
          >
            全部导出
          </el-button>
        </span>
      </div>
      <div>
        情感极性：
        <el-select
          class="search-input"
          v-model="sentimentDictionaryDataState.tendency"
          placeholder="请选择"
          @keyup.enter="getSentimentDictaryDataList"
          clearable
        >
          <el-option
            v-for="option in sentimentTendencyOptions"
            :label="option"
            :value="option"
            :key="option"
          ></el-option>
        </el-select>
        情感值:
        <el-input-number
          class="search-conditon-input"
          v-model="sentimentDictionaryDataState.sentimentLower"
          :max="sentimentLowerMax"
          controls-position="right"
          placeholder="下限"
        />
        <i class="iconfont icon-jianhao1"></i>
        <el-input-number
          class="search-conditon-input"
          v-model="sentimentDictionaryDataState.sentimentUpper"
          :min="sentimentUpperMin"
          controls-position="right"
          placeholder="上限"
        />
        <el-input
          class="search-input"
          v-model="sentimentDictionaryDataState.keyword"
          placeholder="请输入关键词"
          @keyup.enter="getSentimentDictaryDataList"
          clearable
        />
        <el-button type="primary" @click="getSentimentDictaryDataList">
          查询
        </el-button>
      </div>
    </div>
    <el-table
      :data="sentimentDictaryDataList"
      style="width: 100%"
      header-cell-class-name="list-table-header"
      @selection-change="handleSelectionChange"
      v-loading="tableLoading"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="word" label="情感词" />
      <el-table-column prop="tendency" label="情感极性" />
      <el-table-column prop="score" label="情感值" />
      <el-table-column label="操作" width="150" v-if="!ifSystemUser">
        <template #default="scope">
          <div class="list-item-btn">
            <el-button
              link
              type="primary"
              @click="handleClickEditSentimentDictionaryData(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              link
              type="primary"
              @click="handleClickDelete(false, scope.row)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
  </div>
  <SentimentDictionaryDataDialogManage
    ref="sentimentDictionaryDataDialogManageRef"
    @submit="getSentimentDictaryDataList"
  />
  <!-- <SentimentDictionaryDataDialogImport
    ref="sentimentDictionaryDataDialogImportRef"
    @submit="getSentimentDictaryDataList"
  /> -->
  <DialogImport
    ref="sentimentDictionaryDataDialogImportRef"
    :item-name="info ? info.name : ''"
    :upload-params="{
      id: objectId,
      resourceType: RESOURCE_TYPE.RESOURCE_SENTIMENT_DICTIONARY
    }"
    :template-obj="{
      tHeader: ['情感词', '情感极性', '情感值'],
      filterVal: ['word', 'tendency', 'score'],
      list: [{ word: '开心', tendency: '正向', score: '10' }],
      fileName: '情感词汇模板文件'
    }"
    import-url="/config/sentiment/dictionary/importConfirm"
    @refresh="getSentimentDictaryDataList"
  ></DialogImport>
</template>

<script setup>
import { reactive, ref, onMounted, computed, defineProps } from "vue";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { httpPost } from "@/api/httpService.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { ArrowDown } from "@element-plus/icons-vue";
import { export2Excel } from "@/utils/excel.js";
import { SYSTEM_USER_ID } from "@/constant/system";
import SentimentDictionaryDataDialogManage from "./SentimentDictionaryDataDialogManage.vue";
import SentimentDictionaryDataDialogImport from "./SentimentDictionaryDataDialogImport.vue";
import DialogImport from "@/components/base/DialogImport.vue";
import { RESOURCE_TYPE } from "@/constant/upload/resource_type.js";
import { downloadFile } from "@/utils/downloadFile.js";

const props = defineProps({
  info: {
    type: Object
  }
});

// 权限相关
// 该词典拥有者是否为系统
const ifSystemUser = computed(() => {
  if (props.info) {
    return props.info.userId == SYSTEM_USER_ID;
  } else {
    return true;
  }
});

// 表格loading效果
const tableLoading = ref(false);
const hideTableLoading = () => {
  tableLoading.value = false;
};
const showTableLoading = () => {
  tableLoading.value = true;
};
// 获取情感词典数据列表
const route = useRoute();
const objectId = route.params.dictionaryId;
const sentimentDictionaryDataState = ref({
  sentimentLower: null,
  sentimentUpper: null,
  keyword: null,
  tendency: null
});
const sentimentLowerMax = computed(() => {
  return sentimentDictionaryDataState.value.sentimentUpper == null
    ? Math.max
    : sentimentDictionaryDataState.value.sentimentUpper;
  // if (sentimentDictionaryDataState.value.sentimentUpper == null) {
  //   return Math.max;
  // } else {
  //   return sentimentDictionaryDataState.value.sentimentUpper;
  // }
});
const sentimentUpperMin = computed(() => {
  return sentimentDictionaryDataState.value.sentimentLower == null
    ? Math.min
    : sentimentDictionaryDataState.value.sentimentLower;
});
const sentimentTendencyOptions = ref([]);
const getSentimentTendencyOptions = async () => {
  let res = await httpPost("/config/sentiment/dictionary/word/type/get/list", {
    objectId: objectId
  });
  if (res.code == 0) {
    sentimentTendencyOptions.value = res.data;
  }
};

const sentimentDictaryDataList = ref([]);
const getSentimentDictaryDataList = async () => {
  showTableLoading();
  getSentimentTendencyOptions();
  let params = {
    ...sentimentDictionaryDataState.value,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize,
    objectId
  };
  let res = await httpPost(
    "/config/sentiment/dictionary/word/get/list",
    params
  );
  if (res.code == 0) {
    sentimentDictaryDataList.value = res.data.sentimentWordList;
    paginationState.total = res.data.total;
    hideTableLoading();
  }
};
// 编辑数据
const handleClickEditSentimentDictionaryData = (row) => {
  sentimentDictionaryDataDialogManageRef.value.openDLG("EDIT", row);
};
// 新增数据
const sentimentDictionaryDataDialogManageRef = ref(null);
const handleClickAddSentimentDictionaryData = () => {
  sentimentDictionaryDataDialogManageRef.value.openDLG("ADD");
};
// 批量操作
const handleClickBatchCommand = (command) => {
  switch (command) {
    case "batchImport":
      handleClickBatchImport();
      break;
    case "batchDelete":
      handleClickDelete(true, null);
      break;
  }
};
// 批量导入
const sentimentDictionaryDataDialogImportRef = ref(null);
const handleClickBatchImport = () => {
  sentimentDictionaryDataDialogImportRef.value.openDLG();
};
// 批量导出
const handleClickBatchExport = () => {
  downloadFile(`config/sentiment/dictionary/export?objectId=${objectId}`)
  // httpPost("/config/sentiment/dictionary/word/get/list", {
  //   objectId: objectId
  // })
  //   .then((res) => {
  //     if (res.code == 0) {
  //       //表头
  //       let tHeader = ["情感词", "情感极性", "情感值"];
  //       //列名
  //       let filterVal = [];
  //       let temp = res.data.sentimentWordList;

  //       for (let key in temp[0]) {
  //         filterVal.push(key);
  //       }
  //       //数据
  //       export2Excel(tHeader, filterVal, temp, `情感词典${objectId}`);
  //     }
  //   })
  //   .catch((err) => {
  //     ElMessage({
  //       type: "error",
  //       message: "系统异常！"
  //     });
  //   });
};
// 删除或批量删除
const handleClickDelete = (ifBatch, row) => {
  if (ifBatch && sentimentDictaryDataSelectionList.value.length == 0) {
    ElMessage.warning("请选择您要删除的情感词");
    return;
  }
  confirmDeleteBox("此操作将删除您所选情感词，是否继续？").then(async () => {
    let params;
    if (ifBatch) {
      params = {
        objectId,
        words: sentimentDictaryDataSelectionList.value
      };
    } else {
      params = {
        objectId,
        words: [row.word]
      };
    }
    let res = await httpPost(
      "/config/sentiment/dictionary/word/delete",
      params
    );
    if (res.code == 0) {
      ElMessage.success("删除成功");
      getSentimentDictaryDataList();
    }
  });
};
// 分页信息
const paginationState = reactive({
  pageNum: 1,
  pageSize: 10,
  pageSizeList: [10, 20, 30, 40],
  total: 0
});
// 改变页面容纳数据量
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getSentimentDictaryDataList();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getSentimentDictaryDataList();
};
// 多选操作
const sentimentDictaryDataSelectionList = ref([]);
const handleSelectionChange = (val) => {
  sentimentDictaryDataSelectionList.value = [];
  val.forEach((item) => {
    sentimentDictaryDataSelectionList.value.push(item.word);
  });
};
onMounted(() => {
  getSentimentDictaryDataList();
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
.option-bar {
  flex-flow: column-reverse;
  gap: 12px;
}
.search-input {
  width: 120px;
  margin-right: 12px;
}
.search-conditon-input {
  width: 116px;
  margin: 0 16px;
}
.button-group {
  .button-item {
    float: right;
  }
}
</style>
