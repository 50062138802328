<template>
  <div class="list-table">
    <div class="option-bar">
      <!-- <TagDropdown
        ref="TagDropdownRef"
        v-loading="categoryTagLoading"
        :data="categoryList"
        :checkedList="[categoryName]"
        :disabledList="[]"
        @checked-tag="handleClickTag"
        @add-tag="handleAddTag"
        @delete-tag="handleDeleteTag"
        @edit-tag="handleEditTag"
      ></TagDropdown> -->
      <TagList
        ref="TagListRef"
        v-loading="categoryTagLoading"
        :addable="!ifSystemUser"
        @tag-add="handleAddTag"
      >
        <TagListItem
          label="全部"
          :dropable="false"
          :checked="isAllChecked"
          @tag-click="handleClickAll"
        ></TagListItem>
        <TagListItem
          v-for="(categoryItem, categoryIndex) in categoryList"
          ref="TagListItemRef"
          :key="categoryIndex"
          :label="categoryItem.name"
          :checked="categoryItem.isChecked"
          :dropable="!ifSystemUser"
          :ifEdit="categoryItem.ifEdit"
          @tag-edit="
            (oldVal, newVal) => handleEditTag(categoryItem, oldVal, newVal)
          "
          @tag-click="handleClickTag(categoryItem)"
        >
          <template #dropdown-menu>
            <el-dropdown-item @click="handleDeleteTag(categoryItem)">
              删除
            </el-dropdown-item>
            <el-dropdown-item @click="showEditInput(categoryItem)">
              重命名
            </el-dropdown-item>
          </template>
        </TagListItem>
      </TagList>
    </div>
    <div class="head-style">
      <el-checkbox
        v-model="checkAll"
        :indeterminate="isIndeterminate"
        @change="handleCheckAllChange"
      >
        选中当前页
      </el-checkbox>
      <div class="button-group">
        <span>
          <!-- <el-select
            class="input-select"
            v-model="categoryName"
            clearable
            placeholder="请选择分类"
            @change="getCategoryDataSetTextList"
          >
            <el-option
              v-for="item in getCategoryOptions(categoryList)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select> -->
          <el-input
            v-model="keyword"
            class="input-length"
            placeholder="请输入关键词"
            @keyup.enter="getCategoryDataSetTextList"
            @input="getCategoryDataSetTextList"
            clearable
          >
            <template #suffix>
              <el-icon @click="getCategoryDataSetTextList" :size="14">
                <Search />
              </el-icon>
            </template>
          </el-input>
          <el-button @click="handleClickBatchExport" v-if="!ifSystemUser">
            全部导出
          </el-button>
          <el-dropdown @command="handleClickBatchCommand" v-if="!ifSystemUser">
            <el-button>
              批量操作
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="batchImport">
                  批量导入
                </el-dropdown-item>
                <el-dropdown-item command="batchDelete">
                  批量删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button
            type="primary"
            @click="handleClickAddText"
            v-if="!ifSystemUser"
          >
            添加文本
          </el-button>
        </span>
      </div>
    </div>
    <div v-if="textLists.length > 0" class="categoryTraining-table-list">
      <div
        class="categoryTraining-item"
        v-for="(item, index) in textLists"
        :key="index"
      >
        <el-checkbox-group
          v-model="checkedItembox"
          @change="handleCheckedItembox"
          v-loading="pageLoading"
        >
          <el-checkbox :label="item.objectId" size="large"><br /></el-checkbox>
        </el-checkbox-group>
        <div class="categoryTraining-item-main">
          <div class="content" :class="{ two: !item.ifChecked }">
            {{ item.content }}
          </div>
          <div class="bottom">
            <div class="category">分类：{{ item.categoryName }}</div>
            <div class="function">
              <div v-if="!item.ifChecked">
                <span @click="handleClickCheckText(item.objectId)">查看</span>
              </div>
              <div v-else>
                <span @click="handleClickCheckText(item.objectId)">收起</span>
              </div>
              <span @click="handleClickEditText(item)" v-if="!ifSystemUser">
                编辑
              </span>
              <span
                @click="handleClickDeleteText(false, item)"
                v-if="!ifSystemUser"
              >
                删除
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="content-blank">
        <EmptyStatus></EmptyStatus>
      </div>
    </div>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
    <CategoryDataSetTextTableDialogManage
      ref="CategoryDataSetTextTableDialogManageRef"
      @refresh="getCategoryDataSetTextList"
    />
  </div>
  <DialogImport
    ref="dialogImportRef"
    :item-name="info ? info.name : ''"
    :upload-params="{
      id: objectId,
      resourceType: RESOURCE_TYPE.RESOURCE_CATEGORY_TRAIN_DATASET
    }"
    :template-obj="{
      tHeader: ['文本分类', '文本内容'],
      filterVal: ['categoryName', 'content'],
      list: [{ categoryName: '分类', content: '正文' }],
      fileName: '分类训练集模板文件'
    }"
    import-url="/config/category/dataset/importConfirm"
    @refresh="getCategoryDataSetTextList"
  ></DialogImport>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  nextTick,
  onMounted,
  defineExpose,
  computed
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import { SYSTEM_USER_ID } from "@/constant/system";
import { Debounce } from "@/utils/utils";
import {
  ElOption,
  ElCheckbox,
  ElCheckboxGroup,
  ElInput,
  ElSelect,
  ElIcon,
  ElButton,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElPagination,
  ElMessage
} from "element-plus";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { downloadFile } from "@/utils/downloadFile.js";
import CategoryDataSetTextTableDialogManage from "./CategoryDataSetTextTableDialogManage.vue";
import TagList from "@/components/tagList/TagList.vue";
import TagListItem from "@/components/tagList/TagListItem.vue";
import {
  DATA_ERROR,
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
import { ElMessageBox } from "element-plus";
import EmptyStatus from "@/components/base/EmptyStatus.vue";
import DialogImport from "@/components/base/DialogImport.vue";
import { RESOURCE_TYPE } from "@/constant/upload/resource_type.js";

const props = defineProps({
  info: {
    type: Object
  }
});

const route = useRoute();

let objectId = route.params.categoryTrainDatasetObjectId;

// 权限相关
// 该词典拥有者是否为系统
const ifSystemUser = computed(() => {
  console.log(props);
  if (props.info) {
    return props.info.userId == SYSTEM_USER_ID;
  } else {
    return true;
  }
});

// 文本分类标签属性
const tagBoxRef = ref(null);
// 关键字
const keyword = ref("");
// 文本列表
const textLists = ref([]);
// const checkedCategoryDataSetText = ref([]);
// 分类下拉框列表
const categorySelectList = ref([]);
// 下拉框选中的值
const categoryName = ref("");
// 多选列表（已勾选）
const categoryDataSetSelectionList = ref([]);

const checkAll = ref(false);
const isIndeterminate = ref(false);
const checkedItembox = ref([]);
// 选中当前页
const handleCheckAllChange = (val) => {
  // console.log(textLists.value);
  if (val) {
    textLists.value.forEach((item) => {
      checkedItembox.value.push(item.objectId);
    });
  } else {
    checkedItembox.value = [];
  }
  // console.log(checkedItembox.value);
  isIndeterminate.value = false;
};
//选中一个
const handleCheckedItembox = (value) => {
  const checkedCount = value.length;
  checkAll.value = checkedCount === textLists.value.length;
  isIndeterminate.value =
    checkedCount > 0 && checkedCount < textLists.value.length;
};
// loading效果
const pageLoading = ref(false);
const hidePageLoading = () => {
  pageLoading.value = false;
};
const showPageLoading = () => {
  pageLoading.value = true;
};

// 分类训练集标签列表
const categoryList = ref([]);
// 获取文本列表
const getCategoryDataSetTextList = async () => {
  showPageLoading();
  let categoryNameTemp = categoryName.value;
  if (categoryNameTemp == "全部") {
    categoryNameTemp = null;
  }
  let params = {
    keyword: keyword.value,
    categoryName: categoryNameTemp,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize,
    objectId
  };
  let res = await httpPost("/config/category/dataset/text/get/list", params);
  if (res.code == 0) {
    checkedItembox.value = [];
    isIndeterminate.value = false;
    checkAll.value = false;
    textLists.value = res.data.categoryTextDTOList;
    paginationState.total = res.data.total;
    // categoryList.value = fillTagChecked(categoryList);
  }
  hidePageLoading();
};

//控制标签的input编辑框
// const TagListItemRef = ref(null);
// const inputEditRef = ref("");
// const oldValue = ref("");
const showEditInput = (item) => {
  item.ifEdit = true;
  // oldValue.value = item.name;
  // nextTick(() => {
  //   TagListItemRef.value.$refs.inputEditRef.input.focus();
  // });
};

//子组件=>选中事件分类标签的名字
const isAllChecked = ref(true);
const handleClickTag = (item) => {
  //使所有tag处于未选中状态
  categoryList.value.forEach((tempItem) => {
    if (tempItem.name === item.name) {
      tempItem.isChecked = true;
    } else {
      tempItem.isChecked = false;
    }
  });
  isAllChecked.value = false;
  categoryName.value = item.name;
  getCategoryDataSetTextList();
};
const handleClickAll = () => {
  //使所有tag处于未选中状态
  categoryList.value.forEach((tempItem) => {
    tempItem.isChecked = false;
  });
  categoryName.value = "全部";
  isAllChecked.value = true;
  getCategoryDataSetTextList();
};

// 标签选择器的loading相关
const categoryTagLoading = ref(true);
const showTagLoading = () => {
  categoryTagLoading.value = true;
};
const hideTagLoading = () => {
  categoryTagLoading.value = false;
};
// 获取分类训练集标签列表
const getCategoryDataSetTags = async () => {
  showTagLoading();
  let params = {
    objectId
  };
  let res = await httpPost("/config/category/dataset/get/name", params);
  if (res.code == 0) {
    categoryList.value = res.data.categoryList.map((item) => ({
      name: item.name,
      isChecked: false,
      ifEdit: false
    }));
    //如果没有“全部”标签则加上，如果有则改为选中
    // if (!categoryList.value.some((item) => item.name === "全部")) {
    //   categoryList.value.unshift({ name: "全部", isChecked: true, type: 0 });
    // } else {
    //   categoryList.value.forEach((item) => {
    //     if (item.name === "全部") {
    //       item.isChecked = true;
    //     }
    //   });
    // }
  }
  hideTagLoading();
};
// const getCategoryOptions = computed(() => (categoryList) => {
//   return categoryList.map((item) => ({
//     value: item.name,
//     label: item.name
//   }));
// });
// 新增分类训练集分类标签接口调用
const handleAddTag = async (inputCategoryValue, inputCategoryVisible) => {
  let url = "/config/category/dataset/category/save";
  let params = {
    objectId,
    categoryName: inputCategoryValue.value
  };
  showTagLoading();
  let res = await httpPost(url, params);
  if (res.code === 0) {
    ElMessage.success(res.message);
    inputCategoryVisible.value = false;
    inputCategoryValue.value = "";
    categoryName.value = "全部";
    isAllChecked.value = true;
    getCategoryDataSetTextList();
    getCategoryDataSetTags();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
  }
  hideTagLoading();
};
// 删除分类训练集分类标签接口调用
const handleDeleteTag = Debounce((tag, index) => {
  confirmDeleteBox("是否确认删除选中分类？").then(async () => {
    let params = {
      objectId,
      name: tag.name
    };
    showTagLoading();
    let url = "/config/category/dataset/category/delete";
    let res = await httpPost(url, params);
    if (res.code == 0) {
      if (tag.name === categoryName.value) {
        categoryName.value = "全部";
        isAllChecked.value = true;
      }
      ElMessage.success("删除成功");
    }
    getCategoryDataSetTextList();
    getCategoryDataSetTags();
    hideTagLoading();
  });
});
// 重命名标签接口
const handleEditTag = Debounce(async (item, oldValue, inputEditValue) => {
  if (inputEditValue.value !== "") {
    let params = {
      objectId,
      oldCategoryName: oldValue.value,
      categoryName: inputEditValue.value
    };
    showTagLoading();
    let url = "/config/category/dataset/category/save";
    let res = await httpPost(url, params);
    if (res.code == 0) {
      ElMessage.success(res.message);
      item.ifEdit = false;
      inputEditValue.value = "";
      if (item.name === categoryName.value) {
        categoryName.value = "全部";
        isAllChecked.value = true;
      }
      getCategoryDataSetTextList();
      getCategoryDataSetTags();
    } else if (
      res.code == ERROR_CODE_NAME_DUPLICATE ||
      res.code == PARAM_VALID_ERROR
    ) {
      //系统已知错误,用户重新编辑
      ElMessage.warning(res.message);
    }
    hideTagLoading();
  } else {
    item.ifEdit = false;
  }
});

// 获取添加文本弹框的属性
const CategoryDataSetTextTableDialogManageRef = ref(null);
// 编辑文本弹框
const handleClickEditText = (row) => {
  CategoryDataSetTextTableDialogManageRef.value.openDLG("EDIT", row);
};
// 添加文本弹框
const handleClickAddText = (params) => {
  CategoryDataSetTextTableDialogManageRef.value.openDLG("ADD");
};
// 查看文本
const handleClickCheckText = (objectId) => {
  textLists.value.forEach((item) => {
    if (!item.ifChecked) {
      if (item.objectId == objectId) {
        item.ifChecked = true;
      }
    } else {
      item.ifChecked = false;
    }
  });
};

// 点击批量操作
const handleClickBatchCommand = (command) => {
  console.log(command);
  switch (command) {
    case "batchImport":
      handleClickBatchImport();
      break;
    case "batchDelete":
      handleClickDeleteText(true, null);
      break;
  }
};
// 批量导出
const handleClickBatchExport = () => {
  downloadFile(`config/category/dataset/export?objectId=${objectId}`);
};
const dialogImportRef = ref(null);
// 批量导入
const handleClickBatchImport = () => {
  console.log("open");
  dialogImportRef.value.openDLG();
};
// 点击删除功能
const handleClickDeleteText = (ifBatch, row) => {
  if (ifBatch && checkedItembox.value.length == 0) {
    ElMessage.warning("请选择您要删除的文本");
    return;
  }
  confirmDeleteBox("此操作将删除您所选的文本，是否继续？").then(async () => {
    let url = "/config/category/dataset/text/delete";
    let params;
    if (ifBatch) {
      params = {
        datasetObjectId: objectId, //分类训练集的objectId
        objectIdList: checkedItembox.value //分类文本的objectId列表
      };
    } else {
      params = {
        datasetObjectId: objectId,
        objectIdList: [row.objectId]
      };
    }
    // console.log(params);
    let res = await httpPost(url, params);
    if (res.code === 0) {
      ElMessage.success("删除成功");
      getCategoryDataSetTextList();
    }
  });
};

// 分页信息
const paginationState = reactive({
  pageNum: 1,
  pageSize: 40,
  pageSizeList: [40, 80],
  total: 0
});
// 改变页面容纳数据量
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getCategoryDataSetTextList();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getCategoryDataSetTextList();
};

onMounted(() => {
  getCategoryDataSetTextList();
  getCategoryDataSetTags();
});
</script>

<style lang="scss" scoped>
.pagination-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.word-icon {
  font-size: 12px;
}

.head-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 40px 0;
}

.content-blank {
  text-align: center;
}

.categoryTraining-table-list {
  line-height: 28px;

  .two {
    font-size: 14px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .categoryTraining-item {
    display: flex;
    margin-bottom: 20px;

    .el-checkbox {
      width: 50px;
    }

    .categoryTraining-item-main {
      flex: 1;

      .content {
        margin-bottom: 10px;
      }

      .content:hover {
        background-color: #fdecec;
      }

      .bottom {
        display: flex;
        justify-content: space-between;

        .function {
          display: flex;
          visibility: hidden;
          cursor: pointer;

          span {
            color: #e94743;
            margin: 0 10px;
          }
        }
      }
    }
  }

  .categoryTraining-item:hover .function {
    visibility: visible !important;
  }

  // .el-checkbox-group {
  //   div {
  //     margin-bottom: 10px;
  //   }

  //   .el-checkbox {
  //     align-items: flex-start;
  //     height: auto;

  //     ::v-deep .el-checkbox__label {
  //       line-height: 20px;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       display: -webkit-box;
  //       white-space: normal;
  //       -webkit-line-clamp: 2;
  //       -webkit-box-orient: vertical;
  //     }
  //   }
  // }

  // .table-list-operate {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   font-weight: normal;
  //   padding: 0 0 0 20px;

  //   .table-list-operate-button {
  //     .el-button--text {
  //       color: #e34046;
  //     }
  //   }
  // }
}

.input-select {
  margin-right: 12px;
}

.input-length {
  width: 231px;
}

.button-group {
  .el-button {
    margin-left: 12px;
  }
}

.text-lists {
  display: flex;
  // align-items: center;
  // justify-content: space-between;
}

.label-check {
  display: flex;
  align-items: center;
  // justify-content: center;
  padding: 5px 20px;
}

.check-item {
  width: 100%;
  padding: 10px 15px 28px 15px;
  border-bottom: 1px solid rgba(187, 187, 187, 0.42);
}

.check-item :deep(.el-checkbox__label) {
  text-align: center;
  width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  height: 15px;
}

.content-title {
  font-size: $main-title-font-size;
  color: $main-title-text-color;
  margin-bottom: 14px;
  font-weight: $main-font-weight;
}
</style>
