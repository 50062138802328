<template>
  <div id="particulars">
    <div class="main-wrapper">
      <!--页面头部-->
      <div class="particulars-header">
        <div class="particulars-header-title">
          <PageTitle :title="title" />
        </div>
        <!--右侧功能区-->
        <div class="particulars-header-tool">
          <div v-if="modelDetailed.userId == currentUserId">
            <StatusTag
              :type="statusMap[modelDetailed.analysisStatus].type"
              :label="statusMap[modelDetailed.analysisStatus].label"
            ></StatusTag>
            <div v-if="!ifSystemUser">
              <el-button
                v-if="
                  modelRunState === false && modelDetailed.analysisStatus === 0
                "
                @click="startDrill(1)"
                type="primary"
                text
              >
                <el-icon color="red" size="25px"><VideoPlay /></el-icon>
                <span>开始训练</span>
              </el-button>
              <el-button v-else @click="modelAnewRun" type="primary" text>
                <el-icon color="red" size="25px"><VideoPlay /></el-icon>
                <span>重新训练</span>
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <!--配置信息卡片-->
      <div class="particulars-message">
        <p>
          <span class="message-key">模型基本信息：</span>
        </p>
        <p>
          <span class="message-key">分类训练集：</span>
          <span class="message-value">
            {{ modelDetailed.categoryTrainDatasetName }}
          </span>
          <span class="message-key">分类算法：</span>
          <span class="message-value">
            {{ modelDetailed.categoryAlgorithm }}
          </span>
          <span class="message-key">采样比例：</span>
          <span class="message-value">{{ modelDetailed.samplingPercent }}</span>
          <span class="message-key">学习率：</span>
          <span class="message-value">{{ modelDetailed.learningRate }}</span>
        </p>
        <p>
          <span class="message-key">分词算法：</span>
          <span class="message-value">
            {{
              ["基本分词", "精准分词", "nlp分词", "面向索引分词"][
                modelDetailed.tokenizeMethod - 1
              ]
            }}
          </span>
          <span class="message-key">分词粒度：</span>
          <span class="message-value">
            {{
              ["包含单字", "至少双字"][modelDetailed.tokenizeGranularity - 1]
            }}
          </span>
          <span class="message-key">自定义词典：</span>
          <span class="message-value">
            {{ modelDetailed.tokenizeDictionaryName }}
          </span>
        </p>
      </div>
      <div class="chart-list">
        <h2 class="chart-list-title">模型指标</h2>
        <!-- <el-row type="flex" justify="space-between"> -->
        <el-row type="flex" :gutter="20" justify="center">
          <el-col :span="12">
            <line-chart
              class="chart-content"
              ref="setRefLineChart1"
              :chartInfo="accuracyLineChartInfo"
            ></line-chart>
          </el-col>
          <el-col :span="12">
            <line-chart
              class="chart-content"
              ref="setRefLineChart2"
              :chartInfo="lossLineChartInfo"
            ></line-chart>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20" justify="center">
          <el-col :span="12">
            <line-chart
              class="chart-content"
              ref="setRefLineChart3"
              :chartInfo="valAccuracyLineChartInfo"
            ></line-chart>
          </el-col>
          <el-col :span="12">
            <line-chart
              class="chart-content"
              ref="setRefLineChart4"
              :chartInfo="valLossLineChartInfo"
            ></line-chart>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { httpPost } from "@/api/httpService";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  onMounted,
  reactive,
  toRefs,
  ref,
  onBeforeUnmount,
  defineProps,
  computed
} from "vue";
import { useRouter, useRoute } from "vue-router";
import LineChart from "../../../../components/chart/LineChart.vue";
import StatusTag from "../../../../components/base/StatusTag.vue";
import { SYSTEM_USER_ID } from "@/constant/system";
import { VideoPlay } from "@element-plus/icons-vue";
import PageTitle from "@/components/base/PageTitle";

export default {
  name: "CategoryDataSetModelDetailPage",
  components: { LineChart, StatusTag, VideoPlay, PageTitle },
  methods: {},
  props: {
    info: Object
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const categoryTrainDatasetObjectId =
      route.params.categoryTrainDatasetObjectId;
    const title = route.query.DataSetName;
    // 权限相关
    // 该词典拥有者是否为系统
    const ifSystemUser = computed(() => {
      if (props.info) {
        return props.info.userId == SYSTEM_USER_ID;
      } else {
        return true;
      }
    });
    let statusMap = reactive({
      0: { type: "info", label: "已创建" },
      1: { type: "warning", label: "进行中" },
      2: { type: "success", label: "已完成" },
      3: { type: "danger", label: "异常" }
    });
    // const instance = getCurrentInstance();
    // const cookies = instance.appContext.config.globalProperties.$VueCookies;
    const cookieStr = document.cookie; // 获取所有cookie
    const cookies = cookieStr.split("; "); // 将cookie字符串转为数组
    let userInfo = "";
    for (const cookie of cookies) {
      // 遍历数组
      const [name, value] = cookie.split("="); // 分离cookie的name和value
      if (name === "userInfo") {
        // 如果cookie的name是userInfo
        userInfo = decodeURIComponent(value); // 将cookie的value解码为字符串
        break;
      }
    }
    const userInfoObj = JSON.parse(userInfo); // 将JSON字符串转为JSON对象

    let setRefLineChart1 = ref(null);
    let setRefLineChart2 = ref(null);
    let setRefLineChart3 = ref(null);
    let setRefLineChart4 = ref(null);
    let state = reactive({
      currentUserId: userInfoObj.userId,
      objectId: null,
      accuracyLineChartInfo: null,
      lossLineChartInfo: {},
      valAccuracyLineChartInfo: {},
      valLossLineChartInfo: {},
      poll: null
    });
    //模型数据
    let modelData = reactive({
      objectId: String, // 🆔
      modelDetailed: {
        objectId: null, //模型唯一ID
        name: null, //模型名称
        userId: null, //用户id
        categoryTrainDatasetObjectId, //分类训练集id
        categoryLanguage: null, //分类语言，0为简体中文，1为繁体中文（暂时隐藏），2为英文
        categoryAlgorithm: null, //分类算法
        samplingPercent: null, //抽样百分比
        learningRate: null, //学习率
        analysisStatus: 0, //模型分析的状态，0为已创建；1为进行中；2为已完成；3为异常
        categoryTrainDatasetName: null, //分类训练集名称
        createTime: null, //创建时间
        tokenizeMethod: null, //分词方法，1. 基本分词，2. 精准分词，3. nlp分词，4. 面向索引分词
        tokenizeGranularity: null, //分词粒度，1为包含单字，2为至少双字
        tokenizeDictionaryObjectId: null, //分词词典id
        tokenizeDictionaryName: null, //分词词典名称
        startTime: null, //分析开始时间
        endTime: null, //分析结束时间
        modelFileId: null, //分类模型在GridFs里的id
        vocabularyFileId: null, //分类模型词典在GridFs里的id
        idToLabel: null, //分类模型词典在GridFs里的id
        errorCode: null, //错误码
        errorMessage: null, //错误消息
        progress: null //进度条
        // accuracyRate: null, //正确率
        // fscore: null, //F值
        // iterativeProcess: null, // 迭代过程
        // recallRate: null, //召回率
        // tokenizeConfigName: null, // 分词配置
        // threshold: null //阈值
      },
      modelRunState: false //开始训练按钮状态更改
    });

    //⚠️ 🐞 模型执行次数记录
    let logState = reactive({
      log: [0]
    });

    /**
     * @author: RenLishisan
     * @description:  ⚠️ 🐞 测试 记录运行日志记录
     * @time: 2021/11/12 2:51 下午
     **/
    function modelRun() {
      const item = logState.log.length;
      logState.log.push(item);
    }

    /**
     * @author: RenLishisan
     * @description: ⚠️ 🐞 测试 开始模拟日志记录
     * @time: 2021/11/12 2:55 下午
     **/

    // let poll; //⚠️ 🐞 设置模拟迭代过程 定时器
    //开始训练
    function startDrill(type) {
      let msg;
      if (type == 1) {
        msg = "是否确认开始训练?";
      } else {
        msg = "是否确认重新训练?";
      }
      ElMessageBox.confirm(msg, {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "info"
      })
        .then(() => {
          modelData.modelRunState = true;
          httpPost("/config/category/dataset/model/train", {
            objectId: modelData.objectId
          }).then((res) => {
            if (res.code === 0) {
              ElMessage({ message: "训练模型已成功运行！", type: "success" });
              state.poll = setInterval(() => {
                modelRun(); //⚠️ 🐞 模拟迭代过程次数
                getCategoryModel(); //每隔2秒重新刷新页面数据
              }, 5000);
            }
          });
          // ElMessage({
          //   type: 'info',
          //   message: 'Delete completed',
          // })
        })
        .catch(() => {
          // ElMessage({
          //   type: 'info',
          //   message: 'Delete canceled',
          // })
        });
    }

    // 重新训练 或 暂停训练
    function modelAnewRun() {
      modelData.modelRunState = true;
      logState.log = [0];
      startDrill(2);
      clearInterval(state.poll); //⏲ 清除模拟定时器
    }

    // 根据分类模型id，获取对应的分类模型
    function getCategoryModel() {
      httpPost("/config/category/dataset/model/get/" + modelData.objectId).then(
        (res) => {
          if (res.code === 0) {
            modelData.modelDetailed = res.data;
            // state.userId = res.data.userId;
            if (modelData.modelRunState === false) {
              ElMessage({ message: "模型数据加载成功！", type: "success" });
            }
            let {
              accuracyLineChart,
              lossLineChart,
              valAccuracyLineChart,
              valLossLineChart
            } = res.data;
            state.accuracyLineChartInfo = {
              title: accuracyLineChart.title,
              colNames: accuracyLineChart.xaxisList,
              data: accuracyLineChart.yaxisList
            };
            state.lossLineChartInfo = {
              title: lossLineChart.title,
              colNames: lossLineChart.xaxisList,
              data: lossLineChart.yaxisList
            };
            state.valAccuracyLineChartInfo = {
              title: valAccuracyLineChart.title,
              colNames: valAccuracyLineChart.xaxisList,
              data: valAccuracyLineChart.yaxisList
            };
            state.valLossLineChartInfo = {
              title: valLossLineChart.title,
              colNames: valLossLineChart.xaxisList,
              data: valLossLineChart.yaxisList
            };
            setRefLineChart1.value.initChartView();
            setRefLineChart2.value.initChartView();
            setRefLineChart3.value.initChartView();
            setRefLineChart4.value.initChartView();
          } else {
            if (modelData.modelRunState === false) {
              ElMessage({
                message: "模型数据加载异常，请刷新页面再试！",
                type: "error"
              });
            }
          }
        }
      );
    }

    //初始化页面
    onMounted(() => {
      if (route.query.objectId) {
        state.objectId = route.query.objectId;
        modelData.objectId = route.query.objectId;
        getCategoryModel();
      } else {
        router.push({
          name: "CategoryDataSetModelIndex"
        });
      }
    });
    onBeforeUnmount(() => {
      clearInterval(state.poll); //⏲ 清除模拟定时器
    });
    return {
      setRefLineChart1,
      setRefLineChart2,
      setRefLineChart3,
      setRefLineChart4,
      ...toRefs(state),
      // getModel,
      ...toRefs(logState),
      ...toRefs(modelData),
      ifSystemUser,
      title,
      statusMap,
      modelAnewRun,
      startDrill,
      getCategoryModel
    };
  }
};
</script>

<style lang="scss" scoped>
#particulars {
  .main-wrapper {
    //页面头部
    .particulars-header {
      display: flex;
      // justify-content: space-between;
      //头部工具箱
      .particulars-header-tool {
        margin-left: 50px;
        div {
          display: flex;
          // justify-content: flex-end;
          .el-button {
            margin-bottom: 5px;
          }
        }
      }
    }

    //信息展示卡片
    .particulars-message {
      //height: 198px;
      padding: 13px;
      display: flex;
      flex-direction: column;
      //border: 1px solid #bbbbbb;
      background-color: #f9f9f9;
      margin-bottom: 20px;
      p {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .message-key {
          font-weight: bold;
          margin-right: 10px;
          margin-top: 10px;
          font-size: 15px;
        }

        .item {
          flex: 1;
          text-align: right;
        }

        .message-value {
          margin-right: 100px;
          margin-top: 10px;
        }
      }
    }

    .chart-list {
      .chart-list-title {
        font-size: 16px;
        margin-bottom: 20px;
      }
      .chart-content {
        border: 1px solid #d5d5d5;
        // height: 420px;
        padding-top: 20px;

        margin-bottom: 20px;
      }
    }
    //数据仪表盘
    .particulars-exponent {
      height: 180px;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .particulars-exponent-data {
        width: 220px;
        height: 123px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid #bbbbbb;

        .exponent-data-percent {
          font-size: 32px;
          margin-bottom: 19px;
          color: #d11d1d;
        }
      }
    }

    //执行日志
    .particulars-runLog {
      p {
        margin: 13px;
      }

      .particulars-runLog-log {
        height: 440px;
        overflow: auto;
        border: 1px solid #bbbbbb;
      }
    }
  }
}
</style>
