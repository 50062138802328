<template>
  <div class="manage-form">
    <el-form
      ref="formRef"
      :model="form"
      :rules="formRules"
      label-width="120px"
      style="width: 90%"
    >
      <div class="form-top">
        <div class="form-left">
          <el-form-item prop="categoryName" label="事件分类：">
            <el-select
              v-model="form.categoryName"
              class="search-conditon-select"
              placeholder="请选择事件分类"
            >
              <el-option
                v-for="categoryItem in categoryOptions"
                :key="categoryItem.value"
                :label="categoryItem.label"
                :value="categoryItem.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="encoding" label="事件编码：" class="custom-input">
            <el-input
              v-model="form.encoding"
              placeholder="请输入事件编码"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="name" label="事件名称：" class="custom-input">
            <el-input
              v-model="form.name"
              placeholder="请输入事件名称"
              clearable
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-right">
          <el-alert title="逻辑连接符使用举例：" type="info" :closable="false">
            <template #title>
              <strong>逻辑连接符使用举例：</strong>
              <div class="alert-description">
                1.
                参与者规则若为：A&&(B||C)，则意味着参与者包含B或者C，且同时必须包含A；
              </div>
              <div class="alert-description">
                2. #举办#，即意味着规则引用名称为“举办”的同义词组
              </div>
            </template>
          </el-alert>
        </div>
      </div>
      <div class="form-bottom">
        <el-form-item
          prop="eventRule"
          label="事件规则："
          class="form-eventRule"
        >
          <el-form-item
            prop="participantRule"
            label="参与者："
            class="form-participant"
          >
            <el-autocomplete
              class="auto-complete"
              v-model="form.participantRule"
              :fetch-suggestions="queryParticipantSynonymSearch"
              @select="handleParticipantSynonymSelect"
              placeholder="请输入参与者规则，支持且&&、或||、否定!!逻辑和()；两个#可直接引用同义词组"
              clearable
            >
              <template #default="{ item }">
                <div>{{ item.name }}</div>
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item prop="actionList" label="行为：" class="form-action">
            <div
              v-for="(actionItem, actionIndex) in form.actionList"
              :key="actionIndex"
              class="form-action-item"
            >
              <el-autocomplete
                v-model="actionItem.actionRuleItem"
                class="action-left"
                :fetch-suggestions="
                  (queryString, cb) =>
                    queryActionSynonymSearch(queryString, cb, actionIndex)
                "
                @select="
                  (value) => handleActionSynonymSelect(value, actionIndex)
                "
                placeholder="请输入行为规则，支持且&&、或||、否定!!逻辑和()；两个#可直接引用同义词组"
                clearable
              >
                <template #default="{ item }">
                  <div>{{ item.name }}</div>
                </template>
              </el-autocomplete>
              <el-input
                v-model="actionItem.subEncodingItem"
                class="action-right"
                label="子编码"
                placeholder="子编码"
                clearable
              />
              <i
                class="iconfont icon-minus-circle"
                @click="removeActionItem(actionIndex)"
              ></i>
              <i
                class="iconfont icon-plus-circle"
                @click="addActionItem(actionIndex)"
              ></i>
            </div>
          </el-form-item>
          <el-form-item label="验证：" prop="eventTest" class="form-test">
            <el-input
              v-model="form.eventTest"
              placeholder="可输入可能包含事件的文本，验证事件提取规则，不超过300字。"
              type="textarea"
              :rows="4"
            />
            <div class="form-test-desc">
              <div>{{ testNotification }}</div>
              <div v-for="(testItem, testIndex) in testResult" :key="testIndex">
                事件{{ testIndex + 1 }}：实施者（{{
                  testItem.actorNames.join("，")
                }}），承受者（{{
                  testItem.suffererNames.join("，")
                }}），行为（{{ testItem.act }}），时间（{{
                  testItem.actTime.timeList.join("，")
                }}），地点（{{ testItem.actLocationList.join("，") }}）
              </div>
              <!-- <div>事件一：（A，B，行为，时间，地点），事件描述</div> -->
              <!-- <div>事件二：（A，B，行为，时间，地点），事件描述</div> -->
            </div>
            <div class="form-test-icon">
              <el-tooltip content="清空" placement="top">
                <el-button link @click="clearEventTest">
                  <i class="iconfont icon-trash"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip content="开始验证" placement="top">
                <el-button
                  link
                  @click="handleClickTest"
                  :disabled="form.eventTest == null || form.eventTest == ''"
                >
                  <i class="iconfont icon-fenximoxing-yunhang"></i>
                </el-button>
              </el-tooltip>
            </div>
          </el-form-item>
        </el-form-item>
      </div>
    </el-form>
    <span class="form-footer">
      <el-button @click="goToEventTablePage">取消</el-button>
      <el-button
        type="primary"
        @click="handleClickConfirm"
        :disabled="saveButtonDisable"
      >
        保存
      </el-button>
    </span>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  nextTick,
  onMounted,
  onBeforeUnmount
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRouter, useRoute } from "vue-router";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import { Debounce } from "@/utils/utils";
import { ElMessage } from "element-plus";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR,
  ERROR_CODE_LANGUAGE_ERROR
} from "@/constant/error_code.js";
import { async } from "@antv/x6/lib/registry/marker/async";
import { socketUrl } from "@/api/httpService.js";

const emits = defineEmits(["submit"]);
const route = useRoute();
const router = useRouter();

let dictionaryName = "";
let objectId = "";
let formType = ""; //新增or编辑
let oldEventName = ""; //原先的事件名称
if (route.path.split("/")[2] == "edit") {
  objectId = route.query.dictionaryId;
  dictionaryName = route.query.dictionaryName;
  oldEventName = route.params.eventName;
  formType = "EDIT";
} else {
  objectId = route.params.dictionaryId;
  dictionaryName = route.query.dictionaryName;
  formType = "ADD";
}
// 表格相关
const form = ref({
  categoryName: "",
  encoding: "",
  name: "",
  participantRule: "",
  actionList: [{ actionRuleItem: "", subEncodingItem: "" }],
  actionRuleList: [""],
  subEncodingList: [""],
  eventTest: "",
  participantRuleString: "", //存储已输入内容，与选中的拼接成完整字符串
  actionRuleString: [] //存储已输入内容，与选中的拼接成完整字符串
});
const formRef = ref(null);

//事件分类
const categoryOptions = ref([]);
const saveButtonDisable = ref(false);
const showSaveButtonDisable = () => {
  saveButtonDisable.value = true;
};
const hideSaveButtonDisable = () => {
  saveButtonDisable.value = false;
};

//表单验证
const validateCategory = (rule, value, callback) => {
  if (form.value.type === "") {
    callback(new Error("请选择事件分类"));
  } else {
    callback();
  }
};
const validateAction = (rule, value, callback) => {
  form.value.actionList.forEach(async (item) => {
    if (item.actionRuleItem === "") {
      callback(new Error("请输入行为规则"));
    } else if (await validateRule(item.actionRuleItem)) {
      callback(new Error(await validateRule(item.actionRuleItem)));
    } else if (item.subEncodingItem === "") {
      callback(new Error("请输入子编码"));
    } else {
      callback();
    }
  });
};
const validateParticipant = async (rule, value, callback) => {
  if (form.value.participantRule === "") {
    callback(new Error("请输入参与者规则"));
  } else if (await validateRule(form.value.participantRule)) {
    callback(new Error(await validateRule(form.value.participantRule)));
  } else {
    callback();
  }
};
//验证事件提取规则
const validateRule = async (eventRuleString) => {
  let params = {
    eventRuleString
  };
  let res = await httpPost(
    "/config/event/dictionary/event/rule/verify",
    params
  );
  return res.data;
};
const formRules = ref({
  categoryName: [
    { required: true, validator: validateCategory, trigger: "blur" }
  ],
  encoding: [{ required: true, message: "请输入事件编码", trigger: "change" }],
  name: [{ required: true, message: "请输入事件名称", trigger: "change" }],
  participantRule: [
    { required: true, validator: validateParticipant, trigger: "change" }
  ],
  actionList: [{ required: true, validator: validateAction, trigger: "change" }]
});

//参与者规则和行为规则，输入#显示同义词组下拉列表
//1：参与者同义词，2：行为同义词
const queryParticipantSynonymSearch = async (queryString, cb) => {
  //每奇数个#,就触发getSynonymWordsList显示同义词组下拉列表
  form.value.participantRuleString = queryString;
  const count = queryString.split("#").length - 1;
  if (count % 2 !== 0) {
    let synonymOptions = await getSynonymWordsList(1, queryString);
    cb(synonymOptions);
  } else {
    cb([]);
  }
};
const queryActionSynonymSearch = async (queryString, cb, index) => {
  form.value.actionRuleString[index] = queryString;
  const count = queryString.split("#").length - 1;
  if (count % 2 !== 0) {
    let synonymOptions = await getSynonymWordsList(2, queryString);
    cb(synonymOptions);
  } else {
    cb([]);
  }
};
//将最后一个#字符后面的字符替换成选中的内容
const replaceAfterLastHash = (str, replaceStr) => {
  const lastIndex = str.lastIndexOf("#");
  if (lastIndex === -1) return str; // 如果字符串中没有 # 字符，直接返回原字符串
  return str.slice(0, lastIndex + 1) + replaceStr;
};
const handleParticipantSynonymSelect = (item) => {
  form.value.participantRule = replaceAfterLastHash(
    form.value.participantRuleString,
    item.name
  );
};
const handleActionSynonymSelect = (item, index) => {
  form.value.actionList[index].actionRuleItem = replaceAfterLastHash(
    form.value.actionRuleString[index],
    item.name
  );
};
// 获取同义词数据列表
const getSynonymWordsList = async (type, queryString) => {
  let keyword = queryString.substring(queryString.lastIndexOf("#") + 1);
  if (keyword === "") {
    keyword = null;
  }
  let params = {
    keyword: keyword,
    type: type,
    objectId
  };
  let res = await httpPost("/config/event/dictionary/synonym/search", params);
  if (res.code == 0) {
    return res.data;
  }
};
//点击加/减号新增/移除行为规则列表及子编码列表
const addActionItem = Debounce((actionIndex) => {
  form.value.actionList.splice(actionIndex + 1, 0, {
    actionRuleItem: "",
    subEncodingItem: ""
  });
});
const removeActionItem = Debounce((actionIndex) => {
  form.value.actionList.splice(actionIndex, 1);
});
//清空验证框
const clearEventTest = () => {
  form.value.eventTest = "";
};

// 重置表格
const resetForm = () => {
  formRef.value.resetFields();
  form.value = {
    categoryName: "",
    encoding: "",
    name: "",
    participantRule: "",
    actionList: [{ actionRuleItem: "", subEncodingItem: "" }],
    actionRuleList: [""],
    subEncodingList: [""],
    eventTest: "",
    participantRuleString: "",
    actionRuleString: []
  };
  formType = "";
};
// 获取事件标签列表
const getEventCategoryList = async () => {
  let params = {
    objectId
  };
  let res = await httpPost("/config/event/dictionary/get/name", params);
  if (res.code == 0) {
    let categoryList = res.data.categoryList.map((item) => ({
      name: item.name,
      isChecked: false
    }));
    categoryOptions.value = categoryList.map((item) => {
      return { value: item.name, label: item.name };
    });
  }
};
//获取该事件详情
const formData = ref({});
const getEventDetailList = async () => {
  let params = {
    objectId,
    eventName: oldEventName
  };
  let res = await httpPost("/config/event/dictionary/event/get", params);
  if (res.code == 0) {
    formData.value = res.data;
  }
};

//初始化页面
const initForm = async () => {
  getEventCategoryList();
  if (formType == "EDIT") {
    await getEventDetailList();
    // console.log(formData.value);
    form.value = JSON.parse(JSON.stringify(formData.value));
    let actionList = [];
    form.value.actionRuleList.forEach((value, index) => {
      actionList.push({
        actionRuleItem: value,
        subEncodingItem: form.value.subEncodingList[index]
      });
    });
    form.value.actionList = actionList;
  }
};
// 点击确认
const handleClickConfirm = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      showSaveButtonDisable();
      addEvents();
    }
  });
};
// 新增/修改事件
const addEvents = Debounce(async () => {
  //新增事件时处理行为规则及子编码数据结构
  if (formType == "ADD") {
    form.value.actionList.forEach((item) => {
      form.value.actionRuleList.push(item.actionRuleItem);
      form.value.subEncodingList.push(item.subEncodingItem);
    });
    form.value.actionRuleList.splice(0, 1).reverse();
    form.value.subEncodingList.splice(0, 1).reverse();
  } else if (formType == "EDIT") {
    form.value.actionRuleList = [];
    form.value.subEncodingList = [];
    form.value.actionList.forEach((item) => {
      form.value.actionRuleList.push(item.actionRuleItem);
      form.value.subEncodingList.push(item.subEncodingItem);
    });
  }
  let eventName = form.value.name;
  let params = {
    ...form.value,
    eventName,
    oldEventName: oldEventName,
    objectId
  };
  // console.log(params);
  let res = await httpPost("/config/event/dictionary/event/save", params);
  if (res.code == 0) {
    if (formType == "ADD") {
      ElMessage.success("新增成功");
    } else {
      ElMessage.success("编辑成功");
    }
    emits("submit");
    goToEventTablePage();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR ||
    res.code == ERROR_CODE_LANGUAGE_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    // row.ifShowLoading = false;
  }
  hideSaveButtonDisable();
});

const testNotification = ref("");
const testResult = ref([]);
//前端建立webSocket连接
let websocket = null;
let serverTimeoutObj = null;

//创建 心跳检测
// const startHeartCheck = () => {
//   serverTimeoutObj = setInterval(() => {
//     if (websocket.readyState == 1) {
//       websocket.send("ping");
//       resetHeartCheck();
//     } else {
//       createWebSocket();
//     }
//   }, 55000);
// };
// const resetHeartCheck = () => {
//   clearInterval(serverTimeoutObj);
// };
let heartCheck = {
  serverTimeoutObj: null,
  reset: function () {
    clearTimeout(this.serverTimeoutObj);
    return this;
  },
  start: function () {
    this.serverTimeoutObj = setInterval(function () {
      if (websocket.readyState == 1) {
        // 如果获取到消息，说明连接是正常的，重置心跳检测
        websocket.send("ping");
        heartCheck.reset().start();
      } else {
        createWebSocket();
      }
    }, 55000);
  }
};

//监听页面关闭事件
const onBeforeUnload = () => {
  window.sessionStorage.setItem("socketKey", "N");
  // websocket.onclose();
  //that.exit();
};

//解析 cookie
const cookieStr = document.cookie; // 获取所有cookie
const cookies = cookieStr.split("; "); // 将cookie字符串转为数组
let userInfo = "";
let tokenWeb = "";
for (const cookie of cookies) {
  // 遍历数组
  const [name, value] = cookie.split("="); // 分离cookie的name和value
  if (name === "userInfo") {
    // 如果cookie的name是userInfo
    userInfo = decodeURIComponent(value); // 将cookie的value解码为字符串
  }
  if (name === "tokenWeb") {
    tokenWeb = decodeURIComponent(value);
  }
}
const userInfoObj = JSON.parse(userInfo); // 将JSON字符串转为JSON对象
// console.log(userInfoObj, tokenWeb);

//创建 WebSocket
const createWebSocket = () => {
  if ("WebSocket" in window) {
    let socketKey = window.sessionStorage.getItem("socketKey");
    // let wsKey = cookies.get("userInfo").wsKey;
    // let userId = cookies.get("userInfo").userId;
    let wsKey = userInfoObj.wsKey;
    let userId = userInfoObj.userId;
    // console.log("sk", socketKey);

    if (socketKey == null || socketKey === "N") {
      websocket = new WebSocket(
        socketUrl +
          "/websocket/" +
          userId +
          // "/" +
          // socketKey +
          "?access_token=" +
          tokenWeb
        // cookies.get("tokenWeb")
      );
      websocket.onerror = function () {
        window.sessionStorage.setItem("socketKey", "N");
        console.log("=============消息接收失败==============");
      };
      websocket.onopen = () => {
        heartCheck.reset().start();
        window.sessionStorage.setItem("socketKey", "Y");
        console.log("==============成功建立消息通信==========");
      };
      //接收到消息的回调方法
      websocket.onmessage = function (event) {
        // 如果获取到消息，说明连接是正常的，重置心跳检测
        // if (event.data == 'ping') {
        //   heartCheck.reset().start();
        // }
        // const data =
        //   '[{"complexActorIndexes":[],"actIndex":1,"suffererNames":["一杯茶"],"encodingList":["Ab"],"actQuantityList":[],"simpleActorNames":["我"],"actTime":{"timeIndexList":[],"timeList":[],"isFuture":false},"actMannList":[],"actModList":[],"complexActorNames":["linda","jay"],"actorIndexes":[[0,1]],"suffererIndexes":[[3,6]],"actLocationList":["北京","上海"],"act":"拿起","actFrequencyList":[],"actIsNegative":false,"simpleSuffererIndexes":[[3,6]],"simpleSuffererNames":["一杯茶"],"state":1,"actorNames":["我"],"complexSuffererNames":["茶","ok"],"simpleActorIndexes":[[0,1]],"complexSuffererIndexes":[[5,6]]},{"complexActorIndexes":[],"actIndex":7,"suffererNames":[],"encodingList":["Ac"],"actQuantityList":["一口"],"simpleActorNames":["我"],"actTime":{"timeIndexList":[],"timeList":[],"isFuture":false},"actMannList":[],"actModList":[],"complexActorNames":[],"actorIndexes":[[0,1]],"suffererIndexes":[],"actLocationList":[],"act":"喝","actFrequencyList":[],"actIsNegative":false,"simpleSuffererIndexes":[],"simpleSuffererNames":[],"state":1,"actorNames":["我"],"complexSuffererNames":[],"simpleActorIndexes":[[0,1]],"complexSuffererIndexes":[]}]';
        if (event.data !== "ping") {
          testNotification.value = "结果已生成：";
          testResult.value = JSON.parse(event.data);
          console.log(testResult.value);
        }
      };
      websocket.onclose = function () {
        heartCheck.reset();
        window.sessionStorage.setItem("socketKey", "N");
        console.log("==============连接关闭=================");
      };
    }
    // else {
    //   websocket = window.websocket;
    //   if (websocket) {
    //     websocket.message = function (event) {
    //       // console.log("on message2", event);
    //     };
    //   }
    // }
    // console.log("sk", socketKey);
  }
};

//点击开始验证
const handleClickTest = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      testEventTest();
    }
  });
};
//验证
const testEventTest = async () => {
  //处理行为规则及子编码数据结构
  if (formType == "ADD") {
    form.value.actionList.forEach((item) => {
      form.value.actionRuleList.push(item.actionRuleItem);
      form.value.subEncodingList.push(item.subEncodingItem);
    });
    form.value.actionRuleList.splice(0, 1).reverse();
    form.value.subEncodingList.splice(0, 1).reverse();
  } else if (formType == "EDIT") {
    form.value.actionRuleList = [];
    form.value.subEncodingList = [];
    form.value.actionList.forEach((item) => {
      form.value.actionRuleList.push(item.actionRuleItem);
      form.value.subEncodingList.push(item.subEncodingItem);
    });
  }
  // console.log(form.value);
  createWebSocket();
  let params = {
    objectId,
    event: form.value,
    text: form.value.eventTest
  };
  let res = await httpPost("/config/event/dictionary/event/extract", params);
  if (res.code == 0) {
    ElMessage.success(res.message);
    testNotification.value = "开始提取，该过程耗时较长，请耐心等待...";
    // console.log(res);
  }
};

//返回事件列表页面
const goToEventTablePage = () => {
  router.push({
    name: "EventDictionaryDataIndex",
    params: {
      dictionaryId: objectId
    },
    query: {
      dictionaryName: dictionaryName
    }
  });
};
onMounted(() => {
  initForm();
});
onBeforeUnmount(() => {
  onBeforeUnload();
});
</script>
<style lang="scss" scoped>
.iconfont {
  // cursor: pointer;
}
.custom-input {
  width: 95%;
}
.form-top {
  display: flex;
  .form-left {
    flex: 1;
  }
  .form-right {
    margin-left: 20px;
    flex: 1;
  }
}
.form-bottom {
  .form-eventRule {
    display: flex;
    flex-direction: column;
    .form-participant {
      width: 100%;
      :deep(.auto-complete) {
        width: 100%;
      }
    }
    .form-action {
      margin-top: 15px;
      width: 100%;
      .form-action-item {
        display: flex;
        width: 100%;
        margin-top: 5px;
        :deep(.action-left) {
          flex: 6;
        }
        .action-right {
          margin-left: 10px;
          flex: 1;
        }
        .iconfont {
          margin-left: 10px;
        }
      }
    }
    .form-test {
      width: 100%;
      margin-top: 15px;
      .form-test-icon {
        margin-left: auto;
        .iconfont {
          font-size: 25px;
          // margin-left: 10px;
        }
      }
    }
  }
}
.alert-description {
  margin-top: 15px;
}
.form-footer {
  display: flex;
  justify-content: flex-end;
  margin-right: 10%;
}
</style>
