<template>
  <el-dialog v-model="DLGVisible" :before-close="closeDLG" class="base-dialog">
    <template #header>
      <BaseDialogTitle :title="DLGTitle" :item-name="DLGDictionaryName" />
    </template>
    <el-form
      ref="formRef"
      :model="form"
      :rules="formRules"
      label-width="120px"
      style="width: 90%"
    >
      <el-form-item prop="word" label="情感词：">
        <el-input
          v-model="form.word"
          placeholder="请输入情感词"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="score" label="情感值：">
        <el-input-number
          size="default"
          v-model="form.score"
          placeholder="请输入情感值"
          clearable
        ></el-input-number>
      </el-form-item>
      <el-form-item prop="tendency" label="情感极性：">
        <el-input
          v-model="form.tendency"
          placeholder="请输入情感极性"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button type="primary" @click="handleClickConfirm">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  nextTick
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import { Debounce } from "@/utils/utils";
import { ElMessage } from "element-plus";
const emits = defineEmits(["submit"]);
const route = useRoute();
// 表格相关
const form = ref({
  word: "",
  score: 0,
  tendency: ""
});
const formRef = ref(null);
let formType; //新增or编辑
// 正数校验
// const validateScore = (rule, value, callback) => {
//   let reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
//   if (reg.test(value)) {
//     callback();
//   } else {
//     callback(new Error("情感值需为正数"));
//   }
// };
const formRules = ref({
  word: [{ required: true, message: "请输入情感词", trigger: "blur" }],
  score: [
    { required: true, message: "请输入情感值", trigger: "blur" }
    // { validator: validateScore, trigger: "blur" }
  ],
  tendency: [{ required: true, message: "请输入情感极性", trigger: "blur" }]
});

// 重置表格
const resetForm = () => {
  formRef.value.resetFields();
  form.value = {
    word: "",
    score: 0,
    tendency: ""
  };
};
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("新增情感词");
const DLGDictionaryName = route.query.dictionaryName;
const openDLG = (type, params) => {
  formType = type;
  if (type == "EDIT") {
    DLGTitle.value = "编辑情感词";
    form.value = JSON.parse(JSON.stringify(params));
  } else {
    DLGTitle.value = "新增情感词";
  }
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  nextTick(() => {
    resetForm();
  });
};
// 点击确认
const handleClickConfirm = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      handleClickAddSentimentDictionaryData();
    }
  });
};
// 新增情感词
let objectId = route.params.dictionaryId;
const handleClickAddSentimentDictionaryData = Debounce(async () => {
  let params = {
    ...form.value,
    objectId
  };
  let res = await httpPost("/config/sentiment/dictionary/word/save", params);
  if (res.code == 0) {
    if (formType == "ADD") {
      ElMessage.success("新增成功");
    } else {
      ElMessage.success("编辑成功");
    }
    emits("submit");
    closeDLG();
  }
});
defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped></style>
