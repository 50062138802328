<template>
  <el-dialog
    class="base-dialog"
    v-model="DLGVisible"
    :title="DLGTitle"
    :before-close="closeDLG"
  >
    <template #header>
      <BaseDialogTitle :title="DLGTitle" :item-name="itemName" />
    </template>
    <BaseUpload
      ref="baseUploadRef"
      :upload-params="uploadParams"
      :template-obj="templateObj"
      @change="handleFileChange"
      @remove="handleFileRemove"
      :loading="uploadLoading"
    ></BaseUpload>
    <div class="invalid-wrapper" v-if="ifShowInvalidMessage">
      <slot name="invalid-message">
        <div class="invalid-message">
          导入文件标题行与模板文件不一致，导入失败。可重新上传文件
          <!-- 或者点击下载
          <el-link
            :underline="false"
            type="danger"
            @click="handleClickDownLoadTemplateFile()"
          >
            模板文件
          </el-link> -->
          。
        </div>
      </slot>
      <div class="table-title">表格示例</div>
      <el-divider />
      <slot name="invalid-result">
        <el-table :data="templateObj.list">
          <el-table-column
            v-for="(headerItem, index) in templateObj.tHeader"
            :key="headerItem"
            :label="headerItem"
            :prop="templateObj.filterVal[index]"
          ></el-table-column>
        </el-table>
      </slot>
    </div>
    <div class="valid-wrapper">
      <div class="right-result" v-if="ifShowRightTable">
        <div class="table-title">
          <span>
            合计
            <span class="num-color">{{ importResultObj.successNum }}</span>
            条记录
          </span>
          <span>数据预览（前十条）</span>
        </div>
        <el-divider />
        <el-table
          :data="importResultObj.importRightContent[0]"
          v-if="ifShowRightTable"
        >
          <el-table-column
            v-for="headerItem in templateObj.tHeader"
            :key="headerItem"
            :label="headerItem"
            :prop="headerItem"
            show-overflow-tooltip
          ></el-table-column>
          <!-- <el-table-column label="错误原因" prop="错误原因"></el-table-column> -->
        </el-table>
      </div>
      <div class="error-result" v-if="ifShowErrorTable">
        <div class="table-title">
          错误信息（{{ importResultObj.errorNum }}）
        </div>
        <el-divider />

        <el-table
          :data="importResultObj.importErrorContent[0]"
          v-if="ifShowErrorTable"
        >
          <el-table-column
            v-for="headerItem in Object.keys(
              importResultObj.importErrorContent[0][0]
            )"
            v-bind="getColumnWidth(headerItem)"
            :key="headerItem"
            :label="headerItem"
            :prop="headerItem"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
        <el-button
          link
          type="primary"
          class="download-btn"
          @click="downloadErrorFile"
        >
          <i class="iconfont icon-shanchucuowuwenjian"></i>
          错误信息文件
        </el-button>
      </div>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button
          type="primary"
          @click="handleClickImport"
          :disabled="importButtonDisabled"
        >
          导入
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  computed
} from "vue";
import { httpPost } from "@/api/httpService.js";

import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import BaseUpload from "./BaseUpload.vue";
import { Debounce } from "../../utils/utils";
import { ElMessage } from "element-plus";
import { IMPORT_EXCEL_TABLE_TITLE_ERROR } from "@/constant/error_code";
import { downloadFile } from "@/utils/downloadFile.js";

const emits = defineEmits(["refresh"]);
const props = defineProps({
  itemName: {
    type: String,
    default: () => ""
  },
  DLGTitle: {
    type: String,
    default: () => "批量导入"
  },
  uploadParams: {
    type: Object
  },
  importUrl: {
    type: String
  },
  // 模板相关内容
  templateObj: {
    type: Object
  }
});
// 弹窗相关
const DLGVisible = ref(false);
// const DLGTitle = ref("");
const openDLG = (params) => {
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  resetDialog();
  // resetForm();
};

const baseUploadRef = ref(null);
const uploadLoading = ref(false);
const showUploadLoading = () => {
  uploadLoading.value = true;
};
const hideUploadLoading = () => {
  uploadLoading.value = false;
};
const contentRedisKey = ref(null); // 暂存rediskey
const ifShowInvalidMessage = ref(false); // 是否显示文件表头有误的信息
const importButtonDisabled = computed(() => {
  if (ifShowInvalidMessage.value || importResultObj.value.successNum <= 0) {
    return true;
  }
  return false;
  // return importResultObj.value.successNum <= 0;
});
const ifShowRightTable = computed(() => {
  if (ifShowInvalidMessage.value) {
    return false;
  }
  if (
    importResultObj.value.errorNum == 0 &&
    importResultObj.value.successNum > 0
  ) {
    // 如果存在导入错误数据中有任意一个表格行数不为0
    return true;
  }
  return false;
});
const ifShowErrorTable = computed(() => {
  if (ifShowInvalidMessage.value) {
    return false;
  }
  if (importResultObj.value.errorNum > 0) {
    // 如果存在导入错误数据中有任意一个表格行数不为0
    return true;
  }
  return false;
});

class ImportResult {
  constructor(
    options = {
      redisKey: "", // 暂存rediskey
      importErrorContent: [[]],
      importRightContent: [[]],
      successNum: 0, //成功上传条数
      errorNum: 0 // 上传后错误数据条数
    }
  ) {
    for (let key of Object.keys(options)) {
      this[key] = options[key];
    }
  }
}
const importResultObj = ref(new ImportResult()); // 上传后后端返回结果\
// console.log(importResultObj.value);
// 获取表格列宽
const getColumnWidth = (header) => {
  switch (header) {
    case "行数":
      return { width: 80, fixed: true };
    case "错误原因":
      return { "min-width": 140, fixed: true };
    default:
      return { width: 120 };
  }
};
const showInvalidMessage = () => {
  ifShowInvalidMessage.value = true;
};
const hideInvalidMessage = () => {
  ifShowInvalidMessage.value = false;
};

const handleFileChange = async (file) => {
  let formData = new FormData();
  formData.append("file", file.raw);
  for (let key in props.uploadParams) {
    formData.append(key, props.uploadParams[key]);
  }
  showUploadLoading();
  // formData.append("objectId", props.objectId);
  try {
    let res = await httpPost(
      "/attachment/uploadExcelDataToRedis",
      formData,
      "multipart/form-data"
    );
    if (res.code == 0) {
      // 上传成功，此时可以拿到上传正确的数据、错误的数据（含原因），以及rediskey
      ElMessage.success("上传文件成功");
      hideInvalidMessage();
      console.log("res", res.data);
      importResultObj.value = res.data;
      contentRedisKey.value = importResultObj.value.redisKey;
    } else if (res.code == IMPORT_EXCEL_TABLE_TITLE_ERROR) {
      ElMessage.warning("上传文件表头有误");
      importResultObj.value = new ImportResult();
      console.log("result", importResultObj.value);

      showInvalidMessage();
    }

    hideUploadLoading();
  } catch (error) {
    hideUploadLoading();
  }
};
//重置文件状态
const resetDialog = () => {
  importResultObj.value = new ImportResult(); // 上传后后端返回结果\
  hideInvalidMessage();
};
// 文件删除时
const handleFileRemove = () => {
  console.log("rremove");
  resetDialog();
};
// 点击下载模板文件
const handleClickDownLoadTemplateFile = () => {
  baseUploadRef.value.handleClickDownLoadTemplateFile();
};
const downloadErrorFile = () => {
  downloadFile(
    `/attachment/exportRedisErrorData?redisKey=${contentRedisKey.value}`
  );
};

// 点击确认
const handleClickImport = Debounce(async () => {
  let params = {
    contentRedisKey: contentRedisKey.value
  };
  let res = await httpPost(props.importUrl, params);
  emits("refresh");
  closeDLG();
  console.log(res);
});

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
$bold-color: #333333;
$num-color: $theme-color;
.invalid-wrapper {
  .invalid-message {
    // display: flex;
    // padding: 20px;
    // justify-content: center;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: $bold-color;
    font-family: SourceHanSansSC-bold;
    .el-link {
      font-size: 14px;
      font-weight: bold;
      margin-top: -3px;
    }
  }
}
.valid-wrapper {
  .right-result {
    .table-title {
      display: flex;
      justify-content: space-between;
      .num-color {
        color: $num-color;
      }
    }
  }
  .error-result {
    .download-btn {
      margin-top: 16px;
      .iconfont {
        font-size: 24px;
        margin-right: 4px;
      }
    }
  }
}
.table-title {
  text-align: end;
  font-weight: 600;
  color: $bold-color;
}
</style>
