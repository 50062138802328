<template>
  <div class="list-table">
    <div class="option-bar">
      <div>
        <el-input
          class="search-input"
          v-model="keywords"
          placeholder="输入分类训练集名称进行检索"
          @keyup.enter="getCategoryDataSetList"
          clearable
        />
        <el-button type="primary" @click="getCategoryDataSetList">
          搜索
        </el-button>
      </div>
      <div>
        <!-- <el-button plain @click="handleClickBatchDeleteDataSet()">
          批量删除
        </el-button> -->
        <el-button type="primary" @click="handleClickAddDataSet">
          新建训练集
        </el-button>
      </div>
    </div>
    <el-table
      :data="categoryDataSetList"
      style="width: 100%"
      header-cell-class-name="list-table-header"
      @selection-change="handleSelectionChange"
      v-loading="tableLoading"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="分类训练集名称">
        <template #default="scope">
          <TextEditor
            :text="scope.row.name"
            :editable="scope.row.userId != SYSTEM_USER_ID"
            v-model:edit="scope.row.ifEdit"
            v-model:loading="scope.row.ifShowLoading"
            @save="(text) => handleClickSaveListItemName(text, scope.row)"
            @cancel="handleClickCancelRename(scope)"
          ></TextEditor>
        </template>
      </el-table-column>
      <el-table-column prop="categoryLanguage" label="语言">
        <template #default="scope">
          {{ DICTIONARY_LANGUAGE[scope.row.categoryLanguage] }}
        </template>
      </el-table-column>
      <el-table-column prop="userId" label="创建人">
        <template #default="scope">
          {{ ifSystem(scope.row.userId) ? "系统" : username }}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <div class="list-item-btn" v-if="!scope.row.ifEdit">
            <el-button
              link
              type="primary"
              @click="handleClickCategoryDataSetText(scope.row)"
            >
              数据
            </el-button>
            <el-button
              link
              type="primary"
              @click="handleClickCategoryDataSetModel(scope.row)"
            >
              模型
            </el-button>
            <span class="list-item-dropdown" v-if="!ifSystem(scope.row.userId)">
              <el-dropdown
                @command="(command) => handleCommand(scope.row, command)"
              >
                <el-button link>
                  更多
                  <el-icon class="el-icon--right"><arrow-down /></el-icon>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      v-if="scope.row.userId != SYSTEM_USER_ID"
                      command="delete"
                    >
                      删除
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="scope.row.userId != SYSTEM_USER_ID"
                      command="rename"
                    >
                      重命名
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
    <CategoryDataSetDialogAdd
      ref="CategoryDataSetDialogAddRef"
      @refresh="getCategoryDataSetList"
    />
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  onMounted,
  computed
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { DICTIONARY_LANGUAGE } from "@/constant/dictionary.js";
import { ElMessage } from "element-plus";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { httpPost } from "@/api/httpService.js";
import Dictionary from "@/class/Dictionary";
import { Debounce } from "@/utils/utils";
import { CONTENT_TYPE } from "@/constant/text_library";
import { SYSTEM_USER_ID } from "@/constant/system";
import TextEditor from "@/components/base/TextEditor.vue";
import CategoryDataSetDialogAdd from "./CategoryDataSetDialogAdd.vue";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
import VueCookies from "vue-cookies";
const router = useRouter();
// 表格loading效果
const tableLoading = ref(false);
// 分类数据集列表
const categoryDataSetList = ref([]);
// 关键字
const keywords = ref("");
// 多选列表（已勾选）
const categoryDataSetSelectionList = ref([]);
// 获取新增弹框的属性
const CategoryDataSetDialogAddRef = ref(null);
// 获取用户名
const username = ref("xxxxx");
let userInfo = VueCookies.get("userInfo");
if (userInfo) {
  username.value = userInfo.name;
}
// 判断当前是否为系统
const ifSystem = computed(() => (userId) => {
  return userId == SYSTEM_USER_ID;
});
// 隐藏加载特效
const hideTableLoading = () => {
  tableLoading.value = false;
};
// 显示加载特效
const showTableLoading = () => {
  tableLoading.value = true;
};
// 获取分类训练集列表
const getCategoryDataSetList = async () => {
  showTableLoading();
  let params = {
    ifOnlyGetNotNull: false,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize,
    keywords: keywords.value
  };
  let res = await httpPost("/config/category/dataset/get/list", params);
  if (res.code == 0) {
    console.log(res);
    categoryDataSetList.value = res.data.categoryTrainDatasetList;
    paginationState.total = res.data.total;
    hideTableLoading();
  }
};
// 打开新增训练集弹框
const handleClickAddDataSet = () => {
  CategoryDataSetDialogAddRef.value.openDLG();
};
// 点击数据跳转
const handleClickCategoryDataSetText = (row) => {
  router.push({
    name: "CategoryDataSetTextIndex",
    params: {
      categoryTrainDatasetObjectId: row.objectId
    },
    query: {
      DataSetName: row.name
    }
  });
};
// 点击模型跳转
const handleClickCategoryDataSetModel = (row) => {
  router.push({
    name: "CategoryDataSetModelIndex",
    params: {
      categoryTrainDatasetObjectId: row.objectId
    },
    query: {
      DataSetName: row.name
    }
  });
};
// 点击更多
const handleCommand = (row, command) => {
  switch (command) {
    case "delete":
      handleClickDelete(row);
      break;
    case "rename":
      showEditor(row);
      break;
  }
};
// 显示重命名输入框
const showEditor = (row) => {
  row.newName = row.name;
  row.ifEdit = true;
};
// 保存重命名
const handleClickSaveListItemName = Debounce(async (text, row) => {
  if (!text) {
    ElMessage.warning("名称不能为空");
    row.ifShowLoading = false;
    return;
  }
  let params = {
    objectId: row.objectId,
    name: text,
    categoryLanguage: row.categoryLanguage
  };
  let res = await httpPost("/config/category/dataset/save", params);
  if (res.code == 0) {
    //保存成功,更新单行，并重新获取最新列表数据
    ElMessage.success(res.message);
    row.name = text;
    row.ifEdit = false;
    getCategoryDataSetList();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    row.ifShowLoading = false;
  } else {
    //系统未知错误,由外部捕获错误，然后重新获取列表数据
    getCategoryDataSetList();
  }
});
// 取消重命名操作
const handleClickCancelRename = (row) => {
  row.ifEdit = false;
};
// 调用接口，删除功能
const batchDeleteCategoryDataSet = (list) => {
  confirmDeleteBox("此操作将删除您所选的自定义分类训练集，是否继续？").then(
    async () => {
      let url = "/config/category/dataset/delete";
      let params = {
        objectIdList: list
      };
      let res = await httpPost(url, params);
      if (res.code === 0) {
        ElMessage.success("删除成功");
        getCategoryDataSetList();
      }
    }
  );
};
// 点击删除按钮
const handleClickDelete = (row) => {
  batchDeleteCategoryDataSet([row.objectId]);
};
// 多选操作
const handleSelectionChange = (val) => {
  categoryDataSetSelectionList.value = val;
};
// 点击批量删除按钮
const handleClickBatchDeleteDataSet = () => {
  const arr = [];
  categoryDataSetSelectionList.value.forEach((item) => {
    if (item.userId !== SYSTEM_USER_ID) {
      arr.push(item.objectId);
    }
  });
  if (arr.length === 0) {
    ElMessage.warning("请选择您要删除的自定义分类训练集");
    return;
  }
  batchDeleteCategoryDataSet(arr);
};
// 分页信息
const paginationState = reactive({
  pageNum: 1,
  pageSize: 10,
  pageSizeList: [10, 20, 30, 40],
  total: 0
});
// 改变页面容纳数据量
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getCategoryDataSetList();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getCategoryDataSetList();
};
onMounted(() => {
  getCategoryDataSetList();
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
.search-input {
  width: 424px;
}
</style>
