<template>
  <div>
    <PageTitle :title="title" />
    <WordSegmentationDictionaryTable />
  </div>
</template>
<script setup>
import { onMounted, ref, defineProps, defineEmits } from "vue";
import { httpPost } from "@/api/httpService.js";
import WordSegmentationDictionaryTable from "./components/WordSegmentationDictionaryTable.vue";
import PageTitle from "@/components/base/PageTitle.vue";

const title = "分词词典";
// 面包屑
const emits = defineEmits(["setBreadcrumbList"]);
const setBreadcrumbList = () => {
  let BreadcrumbList = [
    {
      title: "配置",
      to: {
        name: "AnalysisConfigIndex"
      }
    },
    { title: "分词词典" }
  ];
  emits("setBreadcrumbList", BreadcrumbList);
};
setBreadcrumbList();
</script>

<style lang="scss" scoped></style>
