<template>
  <PageTitle :title="dictionaryInfo.name" />
  <CommonWordDictionaryDataBox />
</template>

<script setup>
import { defineEmits, onMounted, ref, watch, provide } from "vue";
import { useRoute } from "vue-router";
import PageTitle from "@/components/base/PageTitle";
import CommonWordListDataTable from "./components/CommonWordListDataTable.vue";
import CommonWordDetailTable from "./components/CommonWordDetailTable.vue";
import CommonWordDictionaryDataBox from "./components/CommonWordDictionaryDataBox.vue";
import { httpPost } from "@/api/httpService";

const route = useRoute();
const emits = defineEmits(["setBreadcrumbList"]);

// 常用词典相关信息
const dictionaryInfo = ref({
  objectId: route.params.dictionaryId,
  name: route.query.name
});
const getDictionaryInfo = async () => {
  let url = "/config/common/table/get/name";
  let params = { objectId: dictionaryInfo.value.objectId };
  let res = await httpPost(url, params);
  dictionaryInfo.value = res.data;
  return res;
};
getDictionaryInfo();
provide("info", dictionaryInfo);

// 面包屑
const setBreadcrumbList = () => {
  let BreadcrumbList = [
    { title: "配置", to: { name: "AnalysisConfigIndex" } },
    { title: "常用词语表", to: { name: "CommonWordIndex" } },
    { title: dictionaryInfo.value.name },
    { title: "数据" }
  ];
  emits("setBreadcrumbList", BreadcrumbList);
};
watch(
  () => dictionaryInfo,
  (newVal) => {
    setBreadcrumbList();
  },
  {
    deep: true
  }
);
</script>

<style lang="scss" scoped></style>
