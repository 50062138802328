<template>
  <PageTitle :title="title" />
  <SentimentDictionaryDataTable :info="dictionaryInfo" />
</template>

<script setup>
import { defineEmits, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import PageTitle from "@/components/base/PageTitle";
import SentimentDictionaryDataTable from "./components/SentimentDictionaryDataTable.vue";
import { httpPost } from "@/api/httpService";
const route = useRoute();
const title = ref(route.query.name);
const objectId = ref(route.params.dictionaryId);
const dictionaryInfo = ref(null);
const getDictionaryInfo = async () => {
  let params = {
    objectId: objectId.value
  };
  let url = "/config/sentiment/dictionary/get/name";
  let res = await httpPost(url, params);
  dictionaryInfo.value = res.data;
  title.value = dictionaryInfo.value.name;
  console.log(res);
};
getDictionaryInfo();
// 面包屑
const emits = defineEmits(["setBreadcrumbList"]);

const setBreadcrumbList = () => {
  let BreadcrumbList = [
    { title: "配置", to: { name: "AnalysisConfigIndex" } },
    { title: "情感词典", to: { name: "SentimentDictionaryIndex" } },
    { title: title },
    { title: "数据" }
  ];
  emits("setBreadcrumbList", BreadcrumbList);
};
watch(
  () => title,
  (newVal) => {
    setBreadcrumbList();
  },
  {
    immediate: true
  }
);

onMounted(() => {});
</script>

<style lang="scss" scoped></style>
