<template>
  <PageTitle :title="title" />
  <SentimentDictionaryTable />
</template>

<script setup>
import { defineEmits, onMounted } from "vue";
import PageTitle from "@/components/base/PageTitle";
import SentimentDictionaryTable from "./components/SentimentDictionaryTable.vue";
const title = "情感词典";
// 面包屑
const emits = defineEmits(["setBreadcrumbList"]);
const setBreadcrumbList = () => {
  let BreadcrumbList = [
    { title: "配置", to: { name: "AnalysisConfigIndex" } },
    { title: title }
  ];
  emits("setBreadcrumbList", BreadcrumbList);
};
onMounted(() => {
  setBreadcrumbList();
});
</script>

<style lang="scss" scoped></style>
