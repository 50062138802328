<template>
  <div class="list-table">
    <div class="table-margin">
      <div class="button-group table-margin" style="float: right">
        <span>
          <el-button @click="handleClickBatchExport" v-show="!ifSystemUser">
            全部导出
          </el-button>
          <el-dropdown
            @command="handleClickBatchCommand"
            v-show="!ifSystemUser"
          >
            <el-button>
              批量操作
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="batchImport">
                  批量导入
                </el-dropdown-item>
                <el-dropdown-item command="batchDelete">
                  批量删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button
            type="primary"
            @click="handleClickAddSegmentationDictionaryData"
            v-show="!ifSystemUser"
          >
            新增
          </el-button>
        </span>
      </div>
      <div style="float: left">
        词性:
        <el-select
          v-model="segmentationDictionaryDataState.natureList"
          multiple
          clearable
          collapse-tags
          collapse-tags-tooltip
          class="search-conditon-select"
          placeholder="请选择词性"
        >
          <el-option
            v-for="natureItem in natureList"
            :key="natureItem.value"
            :label="natureItem.label"
            :value="natureItem.value"
          />
        </el-select>
        <el-input-number
          class="search-conditon-input"
          v-model="segmentationDictionaryDataState.weightLower"
          :max="sentimentLowerMax"
          :min="0"
          controls-position="right"
          placeholder="下限"
        />
        <i class="iconfont icon-jianhao1"></i>
        <el-input-number
          class="search-conditon-input"
          v-model="segmentationDictionaryDataState.weightUpper"
          :min="sentimentUpperMin"
          controls-position="right"
          placeholder="上限"
        />
        <el-input
          class="search-input"
          v-model="segmentationDictionaryDataState.keyword"
          placeholder="请输入关键词"
          @keyup.enter="getSegmentationDictaryDataList"
          clearable
        />
        <el-button type="primary" @click="getSegmentationDictaryDataList">
          查询
        </el-button>
      </div>
    </div>
    <el-table
      :data="segmentationDictaryDataList"
      class="word-table"
      header-cell-class-name="list-table-header"
      @selection-change="handleSelectionChange"
      v-loading="tableLoading"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="word" label="词组名称" />
      <el-table-column prop="natureString" label="词性" />
      <el-table-column prop="weight" label="权重" />
      <el-table-column label="操作" width="150" v-if="!ifSystemUser">
        <template #default="scope">
          <div class="list-item-btn">
            <el-button
              link
              type="primary"
              @click="handleClickEditSegmentationDictionaryData(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              link
              type="primary"
              @click="handleClickDelete(false, scope.row)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
  </div>
  <CustomWordsDialogManage
    ref="customWordsDialogManageRef"
    @submit="refreshPage"
  />
  <WordSegmentationDLGImport
    ref="dialogImportRef"
    :item-name="info ? info.name : ''"
    :upload-params="{
      id: objectId,
      resourceType: RESOURCE_TYPE.RESOURCE_TOKENIZE_DICTIONARY
    }"
    :template-obj="{
      sheets: [
        {
          tHeader: ['词组名称', '词性', '权重'],
          filterVal: ['name', 'category', 'value'],
          list: [{ name: '自定义词组', category: '动词', value: 1000 }],
          sheetName: '自定义词'
        },
        {
          tHeader: ['停用词'],
          filterVal: ['name'],
          list: [{ name: '自定义停用词' }],
          sheetName: '停用词'
        },
        {
          tHeader: ['停用词性'],
          filterVal: ['category'],
          list: [{ category: '动词' }],
          sheetName: '停用词性'
        }
      ],

      fileName: '自定义词模板文件'
    }"
    import-url="/config/tokenize/dictionary/importConfirm"
    @refresh="refreshPage"
  ></WordSegmentationDLGImport>
  <!-- <SentimentDictionaryDataDialogImport
    ref="sentimentDictionaryDataDialogImportRef"
    @submit="getSegmentationDictaryDataList"
  /> -->
</template>

<script setup>
import {
  reactive,
  ref,
  onMounted,
  computed,
  defineProps,
  defineExpose
} from "vue";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { httpPost } from "@/api/httpService.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { ArrowDown } from "@element-plus/icons-vue";
import { export2Excel } from "@/utils/excel.js";
import { downloadFile } from "@/utils/downloadFile.js";
import { SYSTEM_USER_ID } from "@/constant/system";
import CustomWordsDialogManage from "./CustomWordsDialogManage.vue";
import WordSegmentationDLGImport from "./WordSegmentationDLGImport.vue";
import { RESOURCE_TYPE } from "@/constant/upload/resource_type.js";
// import SentimentDictionaryDataDialogImport from "./SentimentDictionaryDataDialogImport.vue";

const props = defineProps({
  info: {
    type: Object
  }
});

// 权限相关
// 该词典拥有者是否为系统
const ifSystemUser = computed(() => {
  if (props.info) {
    return props.info.userId == SYSTEM_USER_ID;
  } else {
    return true;
  }
});

// 表格loading效果
const tableLoading = ref(false);
const hideTableLoading = () => {
  tableLoading.value = false;
};
const showTableLoading = () => {
  tableLoading.value = true;
};

// 获取词性
const natureList = ref([]);
const getNatureList = async () => {
  let res = await httpPost(
    `config/tokenize/dictionary/nature/get/list/${objectId}`
  );
  if (res.code == 0) {
    natureList.value = res.data;
  }
};
const route = useRoute();
const objectId = route.params.dictionaryId;
const segmentationDictionaryDataState = ref({
  natureList: null,
  weightLower: null,
  weightUpper: null,
  keyword: null
});
// 设置上限
const sentimentLowerMax = computed(() => {
  return segmentationDictionaryDataState.value.weightUpper == null
    ? Math.max
    : segmentationDictionaryDataState.value.weightUpper;
  // if (segmentationDictionaryDataState.value.weightUpper == null) {
  //   return Math.max;
  // } else {
  //   return segmentationDictionaryDataState.value.weightUpper;
  // }
});
// 设置下限
const sentimentUpperMin = computed(() => {
  return segmentationDictionaryDataState.value.weightLower == null
    ? 0
    : segmentationDictionaryDataState.value.weightLower;
});
// 获取分词词典数据列表
const segmentationDictaryDataList = ref([]);
const getSegmentationDictaryDataList = async () => {
  showTableLoading();
  let params = {
    ...segmentationDictionaryDataState.value,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize,
    objectId
  };
  let res = await httpPost(
    "/config/tokenize/dictionary/custom/get/list",
    params
  );
  if (res.code == 0) {
    segmentationDictaryDataList.value = res.data.resultList;
    paginationState.total = res.data.total;
    hideTableLoading();
  }
};
const customWordsDialogManageRef = ref(null);
// 编辑数据
const handleClickEditSegmentationDictionaryData = (row) => {
  customWordsDialogManageRef.value.openDLG("EDIT", row);
};
// 新增数据
const handleClickAddSegmentationDictionaryData = () => {
  customWordsDialogManageRef.value.openDLG("ADD");
};
// 批量操作
const handleClickBatchCommand = (command) => {
  switch (command) {
    case "batchImport":
      handleClickBatchImport();
      break;
    case "batchDelete":
      handleClickDelete(true, null);
      break;
  }
};
// 批量导入
const dialogImportRef = ref(null);
// 批量导入
const handleClickBatchImport = () => {
  console.log("open");
  dialogImportRef.value.openDLG();
};
// 批量导出
const handleClickBatchExport = () => {
  downloadFile("config/tokenize/dictionary/export?objectId=" + objectId);
};
// 删除或批量删除
const handleClickDelete = (ifBatch, row) => {
  if (ifBatch && sentimentDictaryDataSelectionList.value.length == 0) {
    ElMessage.warning("请选择您要删除的情感词");
    return;
  }
  confirmDeleteBox("此操作将删除您所选情感词，是否继续？").then(async () => {
    let params;
    if (ifBatch) {
      params = {
        objectId,
        words: sentimentDictaryDataSelectionList.value
      };
    } else {
      params = {
        objectId,
        words: [{ word: row.word, nature: row.nature }]
      };
    }
    let res = await httpPost(
      "/config/tokenize/dictionary/custom/delete",
      params
    );
    if (res.code == 0) {
      ElMessage.success("删除成功");
      getSegmentationDictaryDataList();
    }
  });
};
// 刷新页面
const refreshPage = () => {
  getSegmentationDictaryDataList();
  getNatureList();
};
// 分页信息
const paginationState = reactive({
  pageNum: 1,
  pageSize: 10,
  pageSizeList: [10, 20, 30, 40],
  total: 0
});
// 改变页面容纳数据量
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getSegmentationDictaryDataList();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getSegmentationDictaryDataList();
};
// 多选操作
const sentimentDictaryDataSelectionList = ref([]);
const handleSelectionChange = (val) => {
  sentimentDictaryDataSelectionList.value = [];
  val.forEach((item) => {
    sentimentDictaryDataSelectionList.value.push({
      word: item.word,
      nature: item.nature
    });
  });
};

const init = () => {
  getNatureList();
  getSegmentationDictaryDataList();
};
defineExpose({ init });

// onMounted(() => {
//   getNatureList();
//   getSegmentationDictaryDataList();
// });
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
.search-input {
  width: 150px;
  margin: 0 16px 0 8px;
}
.search-conditon-input {
  width: 106px;
  margin: 0 4px;
  vertical-align: middle;
}
.search-conditon-select {
  width: 150px;
  margin: 0 8px;
}
.table-margin {
  margin-bottom: 20px;
}
.button-group {
  .el-button {
    margin-left: 12px;
  }
}
.word-table {
  width: 100%;
  padding-top: 20px;
}
</style>
