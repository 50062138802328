<template>
  <div class="common-word-dictionary-data-box">
    <div v-show="pageType == PAGE_TYPE_MAP.VIEW">
      <div class="tip">双击可编辑词语</div>
      <div class="option-bar">
        <el-input
          class="keyword-input"
          v-model="keyword"
          placeholder="请输入关键词"
          @keydown.enter="getCommonWordList"
        >
          <template #suffix>
            <el-icon style="cursor: pointer" @click="getCommonWordList">
              <search />
            </el-icon>
          </template>
        </el-input>
        <div class="button-group">
          <el-button @click="handleClickBatchDelete">批量删除</el-button>
          <el-button @click="handleClickBatchEdit">批量编辑</el-button>
          <el-button type="primary" @click="handleClickAddWord">
            新增词语
          </el-button>
        </div>
      </div>
      <TagSelectorBox
        ref="tagSelectorBoxRef"
        v-loading="selectorBoxLoading"
        :data="commonWordList"
        :closable="true"
        :selectable="true"
        :editable="true"
        @change-tag="handleChangeWord"
        @delete="handleDeleteWord"
      >
        <template #header></template>
        <template #suffix>
          <div></div>
        </template>
      </TagSelectorBox>
    </div>
    <div v-show="pageType == PAGE_TYPE_MAP.BATCH_EDIT">
      <div class="option-bar">
        <div></div>
        <div class="button-group">
          <el-button type="primary" @click="changePageToView">返回</el-button>
        </div>
      </div>
      <div>
        <TagSelectorBox
          ref="EditTypeTagSelectorBoxRef"
          v-loading="selectorBoxLoading"
          :data="selectedWordList"
          :closable="false"
          :selectable="false"
          :editable="true"
          @change-tag="handleChangeSelectedWord"
          @delete="handleDeleteWord"
          disabled-tip="该词语可能被修改"
          min-height="340px"
        >
          <template #header></template>
          <template #suffix>
            <div>
              请直接双击编辑词语，如果编辑后的词语与已有的词语重复或者编辑后的词语之间存在重复，系统会自动为您去重。
            </div>
          </template>
        </TagSelectorBox>
      </div>
    </div>
    <div
      v-show="pageType == PAGE_TYPE_MAP.ADD"
      class="new-common-word-container"
    >
      <div class="option-bar">
        <div></div>
        <div class="button-group">
          <el-button @click="changePageToView">取消</el-button>
          <el-button type="primary" @click="handleClickSaveNewWordList">
            保存
          </el-button>
        </div>
      </div>
      <div>
        <!-- {{ commonWordList }} -->
        <el-input
          v-if="commonWordList && commonWordList.length > 0"
          type="textarea"
          :rows="wordTextAreaRows"
          v-model="commonWordList"
          disabled
          resize="none"
          class="common-word-list-textarea"
        ></el-input>
        <el-input
          type="textarea"
          :rows="wordTextAreaRows"
          v-model="newCommonWordListStr"
          resize="none"
          class="new-common-word-list-textarea"
          placeholder='请输入常用词语，多个词语用英文逗号"，"或者换行符隔开均可。如果新增的词语与已有的词语重复或者新增的词语之间存在重复，系统会自动为您去重。'
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  inject,
  watch,
  computed,
  nextTick
} from "vue";
import { httpPost } from "@/api/httpService.js";

import TagSelectorBox from "@/components/selector/TagSelectorBox.vue";
import {
  DATA_ERROR,
  ERROR_CODE_NAME_DUPLICATE
} from "@/constant/error_code.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { ElMessage, ElMessageBox } from "element-plus";

// 页面模式
const PAGE_TYPE_MAP = {
  VIEW: 1, // 浏览模式
  BATCH_EDIT: 2, // 批量编辑模式
  ADD: 3 // 新增词语模式
};
const pageType = ref(PAGE_TYPE_MAP.VIEW);
// 切换页面状态
const changePageType = (type) => {
  pageType.value = type;
};

const dictionaryInfo = inject("info");

const tagSelectorBoxRef = ref(null);
const commonWordList = ref([]); //常用词列表
const keyword = ref(""); //关键词
// 标签选择器的loading相关
const selectorBoxLoading = ref(true);
const showTagSelectorBox = () => {
  selectorBoxLoading.value = true;
};
const hideTagSelectorBox = () => {
  selectorBoxLoading.value = false;
};
// 获取常用词列表
const getCommonWordList = async () => {
  showTagSelectorBox();
  let params = {
    objectId: dictionaryInfo.value.objectId,
    keyword: keyword.value
  };
  let res = await httpPost("/config/common/table/word/get/list", params);
  if (res.code == 0) {
    commonWordList.value = res.data;
  }
  hideTagSelectorBox();
};
// console.log("dictionary inject", dictionaryInfo);
watch(
  () => dictionaryInfo.value.objectId,
  (newVal, oldVal) => {
    console.log("watch dic", newVal, oldVal);
    if (newVal != oldVal) {
      getCommonWordList();
    }
  },
  {
    immediate: true,
    deep: true
  }
);
// 选中常用词列表
const selectedWordList = ref(null);
// 改变常用词
const handleChangeWord = async (newVal, oldVal, index) => {
  if (!newVal) {
    ElMessage("词语不能为空！");
    return;
  }
  console.log("handle chagne word", newVal, oldVal, index);
  let wordUpdateMap = {};
  wordUpdateMap[oldVal] = newVal;
  let res = await updateCommonWord(
    dictionaryInfo.value.objectId,
    wordUpdateMap
  );
  if (res.code == 0) {
    ElMessage.success(res.message);
    getCommonWordList();
  } else if (res.code == DATA_ERROR) {
    ElMessageBox.confirm(res.message, "提示", {
      confirmButtonText: "刷新页面",
      showClose: false,
      showCancelButton: false
    }).finally(() => {
      getCommonWordList();
    });
  } else if (res.code == ERROR_CODE_NAME_DUPLICATE) {
    ElMessage.warning(res.message);
    getCommonWordList();
  } else {
    getCommonWordList();
  }
};

// 更新常用词
const updateCommonWord = async (objectId, wordUpdateMap) => {
  let params = {
    objectId,
    wordUpdateMap
  };
  let url = "/config/common/table/word/update";
  let res = await httpPost(url, params);
  return res;
};
// 点击删除单个常用词
const handleDeleteWord = (word, index) => {
  confirmDeleteBox("是否确认删除选中词语？").then(async () => {
    let res = await deleteCommonWord(dictionaryInfo.value.objectId, [word]);
    if (res.code == 0) {
      ElMessage.success("删除成功");
    }
    getCommonWordList();
  });
};
// 点击批量删除常用词
const handleClickBatchDelete = () => {
  selectedWordList.value = tagSelectorBoxRef.value.getSelectedTagList();
  if (selectedWordList.value.length == 0) {
    ElMessage.warning("请先选择词语！");
    return;
  }
  confirmDeleteBox("是否确认删除选中词语？").then(async () => {
    let res = await deleteCommonWord(
      dictionaryInfo.value.objectId,
      selectedWordList.value
    );
    if (res.code == 0) {
      ElMessage.success("删除成功");
    }
    getCommonWordList();
  });
};
// 删除常用词
const deleteCommonWord = async (objectId, wordList) => {
  let params = {
    objectId,
    wordList
  };
  let url = "/config/common/table/word/delete";
  let res = await httpPost(url, params);
  return res;
};
// 点击批量编辑
const handleClickBatchEdit = () => {
  selectedWordList.value = tagSelectorBoxRef.value.getSelectedTagList();
  if (selectedWordList.value.length == 0) {
    ElMessage.warning("请先选择词语！");
    return;
  }
  changePageType(PAGE_TYPE_MAP.BATCH_EDIT);
};

// 编辑模式
const EditTypeTagSelectorBoxRef = ref(null);
// 改变常用词(批量编辑模式下)
const handleChangeSelectedWord = async (newVal, oldVal, index) => {
  console.log("handle chagne word", newVal, oldVal, index);
  let wordUpdateMap = {};
  wordUpdateMap[oldVal] = newVal;
  let res = await updateCommonWord(
    dictionaryInfo.value.objectId,
    wordUpdateMap
  );
  if (res.code == 0) {
    ElMessage.success(res.message);
    selectedWordList.value[index] = newVal;
    getCommonWordList();
    // EditTypeTagSelectorBoxRef.value.setTagDisabled(index);
  } else if (res.code == DATA_ERROR) {
    EditTypeTagSelectorBoxRef.value.setTagDisabled(index);
  } else if (res.code == ERROR_CODE_NAME_DUPLICATE) {
    ElMessage.warning(res.message);
    EditTypeTagSelectorBoxRef.value.init();
  }
};
const changePageToView = () => {
  changePageType(PAGE_TYPE_MAP.VIEW);
  selectedWordList.value = [];
  newCommonWordListStr.value = [];
};

// 新增状态
const handleClickAddWord = () => {
  changePageType(PAGE_TYPE_MAP.ADD);
};
const newCommonWordListStr = ref("");
const newCommonWordList = computed(() => {
  let wordList = newCommonWordListStr.value.split(/[\n,，]/g);
  wordList = wordList.filter((word) => {
    if (word == "" || word.match(/^[ ]*$/)) {
      return false;
    } else {
      return true;
    }
  });
  return wordList;
});
const wordTextAreaRows = computed(() => {
  //TODO
  return 9;
});
const handleClickSaveNewWordList = async () => {
  if (!newCommonWordList.value || newCommonWordList.value.length == 0) {
    ElMessage.warning("请先添加词语");
    return;
  }
  let res = await saveNewWordList(
    dictionaryInfo.value.objectId,
    newCommonWordList.value
  );
  if (res.code == 0) {
    ElMessage.success(res.message);
    getCommonWordList();
    changePageToView();
    newCommonWordListStr.value = "";
  }
};
const saveNewWordList = async (objectId, wordList) => {
  let url = "/config/common/table/word/add",
    params = { objectId, wordList };
  let res = await httpPost(url, params);
  return res;
};
</script>

<style lang="scss" scoped>
.common-word-dictionary-data-box {
  .tip {
    margin-bottom: 25px;
  }
  .option-bar {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    .keyword-input {
      width: 230px;
    }
    .button-group {
      display: inline-block;
    }
  }
  .new-common-word-container {
    .common-word-list-textarea {
      :deep(.el-textarea__inner) {
        border-radius: 0px;
        border: 1px red;
        background: inherit;
      }
    }

    .new-common-word-list-textarea {
      :deep(.el-textarea__inner) {
        // background: red;
        border-radius: 0px;
        // border-bottom-left-radius: 0px;
        // border-bottom: 0px white;
      }
    }
  }
}
</style>
