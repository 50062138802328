<template>
  <PageTitle :title="title" />
  <CommonWordListTable />
</template>

<script setup>
import { defineEmits, onMounted } from "vue";
import PageTitle from "@/components/base/PageTitle";
import CommonWordListTable from "./components/CommonWordListTable.vue";
const title = "常用词语表";
// 面包屑
const emits = defineEmits(["setBreadcrumbList"]);
const setBreadcrumbList = () => {
  let BreadcrumbList = [
    { title: "配置", to: { name: "AnalysisConfigIndex" } },
    { title: title }
  ];
  emits("setBreadcrumbList", BreadcrumbList);
};
setBreadcrumbList();
</script>

<style lang="scss" scoped></style>
