<template>
  <div>
    <div class="head-style">
      <div>
        <el-checkbox
          v-model="checkAll"
          :indeterminate="isIndeterminate"
          @change="handleCheckAllChange"
        >
          选中当前页
        </el-checkbox>
      </div>
      <div class="button-group">
        <span>
          <el-input
            v-model="keyword"
            class="input-length"
            placeholder="请输入关键词"
            @keyup.enter="getStopWords"
          >
            <template #suffix>
              <el-icon @click="getStopWords" :size="14">
                <Search />
              </el-icon>
            </template>
          </el-input>
          <el-button @click="handleClickBatchExport" v-show="!ifSystemUser">
            全部导出
          </el-button>
          <el-button @click="handleClickBatchDlelte" v-show="!ifSystemUser">
            批量删除
          </el-button>
          <el-button
            type="primary"
            @click="handleClickAddStopWords"
            v-show="!ifSystemUser"
          >
            新增
          </el-button>
        </span>
      </div>
    </div>

    <div class="content-title" v-if="stopWords.length === 0 && !pageLoading">
      <EmptyStatus></EmptyStatus>
    </div>
    <el-checkbox-group
      v-model="checkedStopWords"
      @change="handleCheckedStopWordsChange"
      v-loading="pageLoading"
    >
      <el-row class="stop-words">
        <el-col
          :span="6"
          v-for="stopWord in stopWords"
          :key="stopWord"
          :label="stopWord"
          class="label-check"
        >
          <el-checkbox :label="stopWord" size="large" class="check-item">
            {{ stopWord }}
          </el-checkbox>
        </el-col>
      </el-row>
    </el-checkbox-group>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
    <StopWordsDialogAdd ref="StopWordsDialogAddRef" @submit="getStopWords()" />
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  defineExpose
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import { SYSTEM_USER_ID } from "@/constant/system";
import StopWordsDialogAdd from "./StopWordsDialogAdd";
import { ElMessage } from "element-plus";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { downloadFile } from "@/utils/downloadFile.js";
import EmptyStatus from "@/components/base/EmptyStatus.vue";

const props = defineProps({
  info: {
    type: Object
  }
});
const route = useRoute();
const objectId = route.params.dictionaryId;
// 权限相关
// 该词典拥有者是否为系统
const ifSystemUser = computed(() => {
  if (props.info) {
    return props.info.userId == SYSTEM_USER_ID;
  } else {
    return true;
  }
});
const paginationState = reactive({
  pageNum: 1,
  pageSize: 40,
  pageSizeList: [40, 80],
  total: 0
});
// 改变页面容纳数据量
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getStopWords();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getStopWords();
};
const checkAll = ref(false);
const isIndeterminate = ref(false);
const checkedStopWords = ref([]);
const stopWords = ref([]);

const handleCheckAllChange = (val) => {
  checkedStopWords.value = val ? stopWords.value : [];
  isIndeterminate.value = false;
};
const handleCheckedStopWordsChange = (value) => {
  const checkedCount = value.length;
  checkAll.value = checkedCount === stopWords.value.length;
  isIndeterminate.value =
    checkedCount > 0 && checkedCount < stopWords.value.length;
};
// loading效果
const pageLoading = ref(false);
const hidePageLoading = () => {
  pageLoading.value = false;
};
const showPageLoading = () => {
  pageLoading.value = true;
};
const keyword = ref(null);
const getStopWords = async () => {
  // console.log("getStopWordsa");
  showPageLoading();
  let params = {
    keyword: keyword.value,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize,
    objectId
  };
  let res = await httpPost("/config/tokenize/dictionary/stop/get/list", params);
  if (res.code == 0) {
    checkedStopWords.value = [];
    isIndeterminate.value = false;
    checkAll.value = false;
    stopWords.value = res.data.resultList;
    paginationState.total = res.data.total;
  }
  hidePageLoading();
};

const handleClickBatchDlelte = () => {
  if (checkedStopWords.value.length == 0) {
    ElMessage.warning("请选择您要删除的停用词");
    return;
  }
  confirmDeleteBox("此操作将删除您所选停用词，是否继续？").then(async () => {
    let params = {
      objectId,
      words: checkedStopWords.value
    };

    let res = await httpPost("/config/tokenize/dictionary/stop/delete", params);
    if (res.code == 0) {
      ElMessage.success("删除成功");
      getStopWords();
    }
  });
};
const StopWordsDialogAddRef = ref("");
const handleClickAddStopWords = () => {
  StopWordsDialogAddRef.value.openDLG();
};
// 批量导出
const handleClickBatchExport = () => {
  downloadFile("config/tokenize/dictionary/export?objectId=" + objectId);
};
const init = () => {
  getStopWords();
};
defineExpose({ init });
// onMounted(() => {
//   getStopWords();
// });
</script>

<style lang="scss" scoped>
.pagination-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.head-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 40px 0;
}

.input-length {
  width: 231px;
}
.button-group {
  .el-button {
    margin-left: 12px;
  }
}
.stop-words {
  display: flex;
  // align-items: center;
  // justify-content: space-between;
}
.label-check {
  display: flex;
  align-items: center;
  // justify-content: center;
  padding: 5px 20px;
}
.check-item {
  width: 100%;
  padding: 10px 15px 28px 15px;
  border-bottom: 1px solid rgba(187, 187, 187, 0.42);
}
.check-item :deep(.el-checkbox__label) {
  text-align: center;
  width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  height: 15px;
}
// .content-title {
//   font-size: $main-title-font-size;
//   color: $main-title-text-color;
//   margin-bottom: 14px;
//   font-weight: $main-font-weight;
// }
</style>
