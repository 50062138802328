<template>
  <div class="bar-tag">
    <div class="tag-add" v-if="addable">
      <el-input
        v-if="inputAddVisible"
        ref="InputAddRef"
        class="tag-input"
        size="default"
        v-model="inputAddValue"
        @keyup.enter="handleAddTag"
        @blur="handleAddTag"
        style="margin-left: 0px"
      />
      <el-button
        v-else
        class="tag-input"
        @click="showAddInput"
        text
        bg
        style="margin-left: 0px"
      >
        + 新增分类
      </el-button>
      <slot></slot>
    </div>
    <div></div>
    <!-- <el-check-tag
      class="tag-check"
      v-for="(item, index) in data"
      :checked="
        item.isChecked || checkedList.includes(item.name) ? true : false
      "
      :key="index"
      @click="handleClickTag(item, index)"
    >
      <TagDropdownItem
        ref="TagDropdownBoxRef"
        :dataItem="item"
        @delete="handleDeleteTag"
        @edit="handleEditTag"
        :disabled="disabledList.includes(item.name) ? true : false"
      ></TagDropdownItem>
    </el-check-tag> -->
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  watch,
  computed,
  nextTick,
  onMounted
} from "vue";

const emits = defineEmits(["tag-add"]);
// const emits = defineEmits(["checked-tag", "add-tag", "delete-tag", "edit-tag"]);

const props = defineProps({
  //   //父组件的categoryList
  //   data: {
  //     type: Array,
  //     default: () => []
  //   },
  //   //父组件的筛选categoryNameList
  //   checkedList: {
  //     type: Array,
  //     default: () => []
  //   },
  //   //父组件的禁用categoryNameList
  //   disabledList: {
  //     type: Array,
  //     default: () => []
  //   }
  addable: {
    type: Boolean,
    default: () => false
  }
});
// const setup = (props) => {
//   const inputValue = computed(() => props.inputAddValue);
//   return {
//     inputValue
//   };
// };
//分类标签
const inputAddValue = ref("");
const inputAddVisible = ref(false);
const InputAddRef = ref("");
const showAddInput = () => {
  inputAddVisible.value = true;
  nextTick(() => {
    InputAddRef.value.input.focus();
  });
};
// const categoryList = ref([]);
// watch(
//   () => props.data,
//   (newVal) => {
//     categoryList.value = newVal;
//   },
//   {
//     immediate: true
//   }
// );
//选中事件标签
// const handleClickTag = (item, index) => {
//   emits("checked-tag", item);
// };
//触发父组件的事件分类标签接口调用
const handleAddTag = () => {
  if (inputAddValue.value) {
    emits("tag-add", inputAddValue, inputAddVisible);
  } else {
    inputAddVisible.value = false;
    inputAddValue.value = "";
  }
};
// const handleDeleteTag = (item) => {
//   emits("delete-tag", item);
// };
// const handleEditTag = (item, inputEditValue, inputEditVisible) => {
//   emits("edit-tag", item, inputEditValue, inputEditVisible);
// };
</script>

<style lang="scss" scoped>
.bar-tag {
  display: flex;
  flex: 1 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .tag-input {
    margin-left: 15px;
    margin-bottom: 10px;
    width: 100px;
    position: relative;
    top: 4px;
  }
}
</style>
