<template>
  <div>
    <PageTitle :title="title" />
    <div class="latest-list-box">
      <el-tabs v-model="activeTab" @tab-change="initComponent">
        <el-tab-pane
          v-for="tabItem in tabList"
          :key="tabItem.name"
          :label="tabItem.label"
          :name="tabItem.name"
        >
          <template #label>
            <span>
              <i :class="['iconfont', tabItem.icon, 'tab-icon']"></i>
              <span>{{ tabItem.label }}</span>
            </span>
          </template>
          <div>
            <component
              :is="tabItem.component"
              :info="dictionaryInfo"
              ref="componentRef"
            ></component>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script setup>
import {
  onMounted,
  ref,
  defineProps,
  defineEmits,
  shallowRef,
  watch,
  nextTick
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";

import PageTitle from "@/components/base/PageTitle.vue";
import CustomWords from "./components/CustomWords.vue";
import StopWords from "./components/StopWords.vue";
import StopLines from "./components/StopLines.vue";
import { LANGUAGE } from "@/constant/dictionary";
const route = useRoute();
const title = ref(route.query.dictionaryName);
const objectId = ref(route.params.dictionaryId);
const dictionaryInfo = ref(null);
const getDictionaryInfo = async () => {
  let params = {
    objectId: objectId.value
  };
  let url = "/config/tokenize/dictionary/get/name";
  let res = await httpPost(url, params);
  dictionaryInfo.value = res.data;
  title.value = dictionaryInfo.value.name;
  setTableList();
  initComponent();
  console.log("getDictionaryInfo", res);
};
getDictionaryInfo();
// 面包屑
const emits = defineEmits(["setBreadcrumbList"]);

const setBreadcrumbList = () => {
  let BreadcrumbList = [
    { title: "配置", to: { name: "AnalysisConfigIndex" } },
    { title: "分词词典", to: { name: "WordSegmentationDictionary" } },
    { title: title },
    { title: "数据" }
  ];
  emits("setBreadcrumbList", BreadcrumbList);
};
watch(
  () => title,
  (newVal) => {
    setBreadcrumbList();
  },
  {
    immediate: true
  }
);
const CUSTOM_WORDS = 0;
const STOP_WORDS = 1;
const STOP_LINES = 2;
const activeTab = ref(CUSTOM_WORDS);
const tabList = ref([
  {
    icon: "icon-wenbenku-congwenjiandaoru",
    label: "自定义词",
    name: CUSTOM_WORDS,
    component: shallowRef(CustomWords)
  },
  {
    icon: "icon-wenbenku-congyuncaijidaoru",
    label: "停用词",
    name: STOP_WORDS,
    component: shallowRef(StopWords)
  },
  {
    icon: "icon-congruiyanshujupingtaidaoru",
    label: "停用词性",
    name: STOP_LINES,
    component: shallowRef(StopLines)
  }
]);
const setTableList = () => {
  if (dictionaryInfo.value.tokenizeLanguage === LANGUAGE.ENGLISH) {
    tabList.value.splice(0, 1);
    activeTab.value = STOP_WORDS;
  }
};
const componentRef = ref(null);
const initComponent = (val) => {
  componentRef.value[activeTab.value].init();
};
// onMounted(() => {
//   initComponent();
// });
</script>

<style lang="scss" scoped>
.el-tabs {
  --el-tabs-header-height: 60px;
}
.latest-list-box {
  .tab-icon {
    vertical-align: middle;
    font-size: 24px;
    margin-right: 8px;
  }
  span {
    font-size: 14px;
  }
}
</style>
