<template>
  <div>
    <el-button :color="color" class="status-btn" :type="type" round>
      {{ label }}
    </el-button>
  </div>
</template>

<script setup>
import { ref, defineProps, computed } from "vue";
const props = defineProps({
  // create-info success
  type: {
    type: String,
    default: "info"
  },
  label: {
    type: String,
    default: "已创建"
  }
});
const color = computed(() => {
  switch (props.type) {
    case "modified":
      return "#3894FF";
    default:
      return null;
  }
});
// const type = ref(props.type);
// switch (type.value) {
//   case "0":
//     type.value = "info";
//     break;
//   case "1":
//     type.value = "warning";
//     break;
//   case "2":
//     type.value = "success";
//     break;
//   case "3":
//     type.value = "danger";
//     break;
// }
</script>

<style lang="scss" scoped>
:deep(.el-button) {
  width: 80px;
  height: 28px;
  pointer-events: none;
  color: white;
}
</style>
