const DICTIONARY_LANGUAGE = ["简体中文", "繁体中文", "英文"];
const DICTIONARY_LANGUAGE_LIST = [
  { value: 0, label: "简体中文" },
  { value: 2, label: "英文" }
];
const LANGUAGE = {
  SIMPLIFIED_CHINESE: 0,
  ENGLISH: 2
};
export { DICTIONARY_LANGUAGE, DICTIONARY_LANGUAGE_LIST, LANGUAGE };
