import { baseUrl } from "@/api/httpService.js";
import VueCookies from "vue-cookies";
/**
 * @function 下载附件
 * @param url 路由地址，需要拼接参数
 * @param baseUrlUse 请求头地址，默认为全局请求头地址
 * @param isToken 是否拼接token，默认为true
 */
function downloadFile(url, baseUrlUse = baseUrl, isToken = true) {
  console.log(url, baseUrlUse, isToken);
  const token = isToken ? "&access_token=" + VueCookies.get("tokenWeb") : "";
  const href = baseUrl + url + token;
  window.location.href = href;
}

export { downloadFile };
