<template>
  <el-dialog v-model="DLGVisible" :before-close="closeDLG" class="base-dialog">
    <template #header>
      <BaseDialogTitle :title="DLGTitle" :item-name="DLGDictionaryName" />
    </template>
    <el-form
      ref="formRef"
      :model="form"
      :rules="formRules"
      label-width="120px"
      style="width: 90%"
    >
      <el-form-item prop="name" label="同义词名称：">
        <el-input
          v-model="form.name"
          placeholder="请输入同义词名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="type" label="同义词类型：">
        <el-select
          v-model="form.type"
          class="search-conditon-select"
          placeholder="请选择同义词类型"
        >
          <el-option
            v-for="synonymItem in synonymOptions"
            :key="synonymItem.value"
            :label="synonymItem.label"
            :value="synonymItem.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="同义词集：" prop="alias">
        <el-input
          v-model="form.alias"
          placeholder="请输入同义词集，多个词语用英文逗号“,”或者换行符隔开均可。"
          type="textarea"
          :rows="5"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button
          type="primary"
          @click="handleClickConfirm"
          :disabled="saveButtonDisable"
        >
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  nextTick
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import { Debounce } from "@/utils/utils";
import { ElMessage } from "element-plus";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR,
  ERROR_CODE_LANGUAGE_ERROR
} from "@/constant/error_code.js";
const emits = defineEmits(["submit"]);
const route = useRoute();
let objectId = route.params.dictionaryId;
// 表格相关
const form = ref({
  name: "",
  type: "",
  alias: []
});
const formRef = ref(null);
let formType; //新增or编辑
let oldSynonymName; //原先的名称（编辑）
//同义词类型
const synonymOptions = ref([
  {
    value: 1,
    label: "参与者同义词"
  },
  {
    value: 2,
    label: "行为同义词"
  }
]);
const saveButtonDisable = ref(false);
const showSaveButtonDisable = () => {
  saveButtonDisable.value = true;
};
const hideSaveButtonDisable = () => {
  saveButtonDisable.value = false;
};

//表单验证
const validateNature = (rule, value, callback) => {
  if (form.value.type === "") {
    callback(new Error("请选择同义词类型"));
  } else {
    callback();
  }
};
const formRules = ref({
  name: [{ required: true, message: "请输入同义词名称", trigger: "change" }],
  type: [{ required: true, validator: validateNature, trigger: "blur" }],
  alias: [{ required: true, message: "请输入同义词集", trigger: "change" }]
});
// 重置表格
const resetForm = () => {
  formRef.value.resetFields();
  form.value = {
    name: "",
    type: "",
    alias: []
  };
  formType = "";
};
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("新增同义词");
const DLGDictionaryName = route.query.dictionaryName;
const openDLG = (type, params) => {
  formType = type;
  if (params) {
    oldSynonymName = params.name;
  }
  if (type == "EDIT") {
    DLGTitle.value = "编辑同义词";
    form.value = JSON.parse(JSON.stringify(params));
  } else {
    DLGTitle.value = "新增同义词";
  }
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  nextTick(() => {
    resetForm();
  });
};
// 点击确认
const handleClickConfirm = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      showSaveButtonDisable();
      handleClickAddSynonymWords();
    }
  });
};
// 新增/修改同义词
const handleClickAddSynonymWords = Debounce(async () => {
  if (!Array.isArray(form.value.alias)) {
    let aliasArr;
    aliasArr = form.value.alias.split(/[\n,，]/g);
    form.value.alias = aliasArr;
  }
  let params = {
    ...form.value,
    oldSynonymName,
    objectId
  };
  let res = await httpPost("/config/event/dictionary/synonym/save", params);
  if (res.code == 0) {
    if (formType == "ADD") {
      ElMessage.success("新增成功");
    } else {
      ElMessage.success("编辑成功");
    }
    emits("submit");
    closeDLG();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR ||
    res.code == ERROR_CODE_LANGUAGE_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    // row.ifShowLoading = false;
  }
  hideSaveButtonDisable();
});
defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped></style>
