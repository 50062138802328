<template>
  <div class="config-wrapper">
    <ConfigCard
      v-for="cardItem in CONFIG_MAP_LIST"
      :key="cardItem.name"
      :cardIconUrl="cardItem.url"
      :cardName="cardItem.name"
      :cardDigest="cardItem.digest"
      :cardConfigPath="cardItem.path"
      @click="handleGoToConfig(cardItem.path)"
    />
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import ConfigCard from "./ConfigCard.vue";
import { CONFIG_MAP_LIST } from "@/constant/config_map.js";
// TODO 跳转到词典页面
const router = useRouter();
const handleGoToConfig = (path) => {
  router.push({
    path: path
  });
};
</script>

<style lang="scss" scoped>
.config-wrapper {
  height: 100%;
  width: 100%;
  display: grid;
  padding-left: 20px;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 40px;
  grid-template-rows: repeat(auto-fill, 300px);
}
</style>
