<template>
  <PageTitle :title="title" />
  <EncodingDictionaryTable />
</template>

<script setup>
import { onMounted, defineEmits } from "vue";
import EncodingDictionaryTable from "./components/EncodingDictionaryTable.vue";
import PageTitle from "@/components/base/PageTitle";
const title = "编码词典";
// 面包屑
const emits = defineEmits(["setBreadcrumbList"]);
const setBreadcrumbList = () => {
  let BreadcrumbList = [
    { title: "配置", to: { name: "AnalysisConfigIndex" } },
    { title: title }
  ];
  emits("setBreadcrumbList", BreadcrumbList);
};
setBreadcrumbList();
</script>

<style lang="scss" scoped></style>
