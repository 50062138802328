<template>
  <div class="list-table">
    <div class="option-bar">
      <div>
        <el-input
          class="search-input"
          v-model="keywords"
          placeholder="输入关键词"
          @keyup.enter="getCategoryDataSetModelList"
          clearable
        />
        <el-button type="primary" @click="getCategoryDataSetModelList">
          查询
        </el-button>
      </div>
      <div v-if="!ifSystemUser">
        <el-button plain @click="handleClickBatchDeleteDataSet()">
          批量删除
        </el-button>
        <el-button type="primary" @click="handleClickAddDataSetModel()">
          新增模型
        </el-button>
      </div>
    </div>
    <el-table
      :data="categoryDataSetModelList"
      style="width: 100%"
      header-cell-class-name="list-table-header"
      @selection-change="handleSelectionChange"
      v-loading="tableLoading"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="name" label="模型名称">
        <template #default="scope">
          <TextEditor
            :text="scope.row.name"
            :editable="scope.row.userId != SYSTEM_USER_ID"
            v-model:edit="scope.row.ifEdit"
            v-model:loading="scope.row.ifShowLoading"
            @save="(text) => handleClickSaveListItemName(text, scope.row)"
          ></TextEditor>
        </template>
      </el-table-column>
      <el-table-column prop="categoryAlgorithm" label="分类算法" />
      <el-table-column prop="samplingPercent" label="采样比例" />
      <el-table-column prop="learningRate" label="学习率" />
      <el-table-column prop="analysisStatus" label="状态">
        <template #default="scope">
          <StatusTag
            :type="statusMap[scope.row.analysisStatus].type"
            :label="statusMap[scope.row.analysisStatus].label"
          ></StatusTag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250">
        <template #default="scope">
          <div class="list-item-btn">
            <el-button
              link
              type="primary"
              @click="handleClickDetail(scope.row)"
            >
              详情
            </el-button>
            <el-button
              link
              type="primary"
              @click="handleClickEditDataRecord(scope.row)"
              v-if="!ifSystemUser"
            >
              编辑
            </el-button>
            <el-button
              link
              type="primary"
              @click="handleClickDelete(scope.row)"
              v-if="!ifSystemUser"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
    <CategoryDataSetModelDialogAdd
      ref="CategoryDataSetModelDialogAddRef"
      @refresh="getCategoryDataSetModelList"
    />
    <CategoryDataSetModelDialogEdit
      ref="CategoryDataSetModelDialogEditRef"
      @submit="getCategoryDataSetModelList"
    />
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  onMounted,
  computed
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { DICTIONARY_LANGUAGE } from "@/constant/dictionary.js";
import { ElMessage } from "element-plus";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { httpPost } from "@/api/httpService.js";
import Dictionary from "@/class/Dictionary";
import { Debounce } from "@/utils/utils";
import { CONTENT_TYPE } from "@/constant/text_library";
import { SYSTEM_USER_ID } from "@/constant/system";
import StatusTag from "@/components/base/StatusTag.vue";
import TextEditor from "@/components/base/TextEditor.vue";
import CategoryDataSetModelDialogAdd from "./CategoryDataSetModelDialogAdd.vue";
import CategoryDataSetModelDialogEdit from "./CategoryDataSetModelDialogEdit.vue";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
import VueCookies from "vue-cookies";
const router = useRouter();
const route = useRoute();
let statusMap = {
  0: { type: "info", label: "已创建" },
  1: { type: "warning", label: "进行中" },
  2: { type: "success", label: "成功" },
  3: { type: "danger", label: "异常" }
};
let categoryTrainDatasetObjectId = route.params.categoryTrainDatasetObjectId;

// 表格loading效果
const tableLoading = ref(false);
// 分类数据集模型列表
const categoryDataSetModelList = ref([]);
// 关键字
const keywords = ref("");
// 所属分类训练集Id
// const categoryTrainDatasetObjectId = ref("");
// 多选列表（已勾选）
const categoryDataSetSelectionList = ref([]);
// 获取新增弹框的属性
const CategoryDataSetModelDialogAddRef = ref(null);
// 获取编辑弹框的属性
const CategoryDataSetModelDialogEditRef = ref(null);
// 获取用户名
const username = ref("xxxxx");
let userInfo = VueCookies.get("userInfo");
if (userInfo) {
  username.value = userInfo.name;
}
// 权限相关
const props = defineProps({
  info: {
    type: Object
  }
});
// 该词典拥有者是否为系统
const ifSystemUser = computed(() => {
  console.log(props);
  if (props.info) {
    return props.info.userId == SYSTEM_USER_ID;
  } else {
    return true;
  }
});
// 隐藏加载特效
const hideTableLoading = () => {
  tableLoading.value = false;
};
// 显示加载特效
const showTableLoading = () => {
  tableLoading.value = true;
};
// 获取分类训练集模型列表
const getCategoryDataSetModelList = async () => {
  showTableLoading();
  let params = {
    ifOnlyGetFinishedModel: false,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize,
    keywords: keywords.value,
    categoryTrainDatasetObjectId
  };
  let res = await httpPost("/config/category/dataset/model/get/list", params);
  hideTableLoading();
  if (res.code == 0) {
    console.log(res.data);
    categoryDataSetModelList.value = res.data.categoryModelList;
    paginationState.total = res.data.total;
  }
};
// 打开新增训练集模型弹框
const handleClickAddDataSetModel = () => {
  CategoryDataSetModelDialogAddRef.value.openDLG();
};
// 打开编辑训练集模型弹框
const handleClickEditDataRecord = (row) => {
  CategoryDataSetModelDialogEditRef.value.openDLG("EDIT", row);
};
// 点击单元格重命名
const handleClickSaveListItemName = Debounce(async (text, row) => {
  if (!text) {
    ElMessage.warning("名称不能为空");
    row.ifShowLoading = false;
    return;
  }
  let params = {
    name: text,
    objectId: row.objectId,
    categoryAlgorithm: row.categoryAlgorithm,
    learningRate: row.learningRate,
    samplingPercent: row.samplingPercent
  };
  let res = await httpPost("/config/category/dataset/model/save", params);
  if (res.code == 0) {
    //保存成功,更新单行，并重新获取最新列表数据
    ElMessage.success(res.message);
    row.name = text;
    row.ifEdit = false;
    getCategoryDataSetModelList();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    row.ifShowLoading = false;
  } else {
    //系统未知错误,由外部捕获错误，然后重新获取列表数据
    getCategoryDataSetModelList();
  }
});
// 点击删除按钮
const handleClickDelete = (row) => {
  batchDeleteCategoryDataSet([row.objectId]);
};
// 多选操作
const handleSelectionChange = (val) => {
  categoryDataSetSelectionList.value = val;
};
// 点击批量删除按钮
const handleClickBatchDeleteDataSet = () => {
  const arr = [];
  categoryDataSetSelectionList.value.forEach((item) => {
    if (item.userId !== SYSTEM_USER_ID) {
      arr.push(item.objectId);
    }
  });
  if (arr.length === 0) {
    ElMessage.warning("请选择您要删除的自定义分类训练集模型");
    return;
  }
  batchDeleteCategoryDataSet(arr);
};
// 调用接口，删除功能
const batchDeleteCategoryDataSet = (list) => {
  confirmDeleteBox("此操作将删除您所选的自定义分类训练集模型，是否继续？").then(
    async () => {
      let url = "/config/category/dataset/model/delete";
      let params = {
        objectIdList: list
      };
      let res = await httpPost(url, params);
      if (res.code === 0) {
        ElMessage.success("删除成功");
        getCategoryDataSetModelList();
      }
    }
  );
};
// 点击详情跳转
const handleClickDetail = (row) => {
  router.push({
    name: "CategoryDataSetModelDetailIndex",
    params: {
      categoryTrainDatasetObjectId
    },
    query: {
      objectId: row.objectId,
      DataSetName: row.name
    }
  });
};

// 分页信息
const paginationState = reactive({
  pageNum: 1,
  pageSize: 10,
  pageSizeList: [10, 20, 30, 40],
  total: 0
});
// 改变页面容纳数据量
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getCategoryDataSetModelList();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getCategoryDataSetModelList();
};
onMounted(() => {
  getCategoryDataSetModelList();
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
.search-input {
  width: 216px;
}
.search-conditon-input {
  width: 116px;
  margin: 0 16px;
}
</style>
