<template>
  <div>
    <div class="button-group head-style">
      <span>
        <el-button
          class="button-item"
          type="primary"
          link
          @click="getStopLines"
        >
          <span class="iconfont icon-fenximoxing-chexiao icon"></span>
          取消
        </el-button>
        <el-button
          class="button-item"
          type="primary"
          link
          @click="saveStopLines"
          v-show="!ifSystemUser"
        >
          <span class="iconfont icon-fenximoxing-baocun icon"></span>

          保存
        </el-button>
      </span>
    </div>
    <el-checkbox-group v-model="checkedStopLines" v-loading="pageLoading">
      <el-row class="stop-lines">
        <el-col
          :span="8"
          v-for="stopLine in stopLines"
          :key="stopLine"
          :label="stopLine"
          class="label-check"
        >
          <el-checkbox :label="stopLine" size="large" class="check-item">
            {{ stopLine.label }}
          </el-checkbox>
        </el-col>
      </el-row>
    </el-checkbox-group>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  onMounted,
  defineExpose,
  computed
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import { SYSTEM_USER_ID } from "@/constant/system";

import { ElMessage } from "element-plus";
import { confirmDeleteBox } from "@/utils/messageBox.js";
const props = defineProps({
  info: {
    type: Object
  }
});
const route = useRoute();
const objectId = route.params.dictionaryId;
// 权限相关
// 该词典拥有者是否为系统
const ifSystemUser = computed(() => {
  if (props.info) {
    return props.info.userId == SYSTEM_USER_ID;
  } else {
    return true;
  }
});
const stopLines = ref([]);
const checkedStopLines = ref([]);
// loading效果
const pageLoading = ref(false);
const hidePageLoading = () => {
  pageLoading.value = false;
};
const showPageLoading = () => {
  pageLoading.value = true;
};
const getStopLines = async () => {
  showPageLoading();
  let params = {
    objectId
  };
  let res = await httpPost(
    "/config/tokenize/dictionary/stop/nature/get/list",
    params
  );
  if (res.code == 0) {
    stopLines.value = res.data.natureList;
    checkedStopLines.value = res.data.selectedNatureList.map((item) => {
      return stopLines.value.find((_) => _.value === item);
    });
  }
  hidePageLoading();
};

const saveStopLines = async () => {
  const natureList = checkedStopLines.value.map((_) => {
    return _?.value;
  });
  console.log(natureList);
  let params = {
    natureList,
    objectId
  };
  let res = await httpPost(
    "/config/tokenize/dictionary/stop/nature/save",
    params
  );
  if (res.code == 0) {
    ElMessage.success("保存成功");
    getStopLines();
  }
};
const init = () => {
  getStopLines();
};
defineExpose({ init });
// onMounted(() => {
//   getStopLines();
// });
</script>

<style lang="scss" scoped>
.head-style {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 15px 50px 20px 0;
}
.stop-lines {
  display: flex;
  margin-top: 20px;
  // align-items: center;
  // justify-content: space-between;
}
.label-check {
  display: flex;
  align-items: center;
  // justify-content: center;
  padding: 5px 20px;
}
.check-item {
  width: 100%;
  padding: 10px 15px 28px 15px;
  border-bottom: 1px solid rgba(187, 187, 187, 0.42);
}
.check-item :deep(.el-checkbox__label) {
  text-align: center;
  width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  height: 15px;
}
.button-group {
  .el-button {
    margin-left: 12px;
  }
}
.button-item {
  font-size: 14px;
  font-family: SourceHanSansSC-regular;
}
.icon {
  margin-right: 10px;
  font-size: 20px;
}
</style>
