<template>
  <el-dialog
    v-model="DLGVisible"
    :before-close="closeDLG"
    width="40%"
    destroy-on-close
    class="base-dialog"
  >
    <template #header>
      <BaseDialogTitle :title="DLGTitle" :item-name="DLGDictionaryName" />
    </template>
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="120px">
      <el-form-item label="文本分类：" prop="categoryName">
        <el-select
          class="input-select"
          v-model="form.categoryName"
          clearable
          placeholder="请选择文本分类"
        >
          <el-option
            v-for="item in categorySelectList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="文本内容：" prop="content">
        <el-input
          v-model="form.content"
          placeholder="请输入文本内容"
          type="textarea"
          :rows="4"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button
          type="primary"
          :disabled="saveButtonDisable"
          @click="handleClickSave"
        >
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  onMounted,
  nextTick
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import { DICTIONARY_LANGUAGE_LIST } from "@/constant/dictionary.js";
import {
  ElMessage,
  ElForm,
  ElInput,
  ElFormItem,
  ElSelect,
  ElOption
} from "element-plus";
import { Debounce } from "@/utils/utils";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
const route = useRoute();
const emits = defineEmits(["refresh"]);
let objectId;
let datasetObjectId = route.params.categoryTrainDatasetObjectId;
// 分类下拉框列表
const categorySelectList = ref([]);
// 下拉框选中的值
const checkedOption = ref([]);
// 关键字
const keyword = ref("");
const formRef = ref(null);
let formType; //新增or编辑

const saveButtonDisable = ref(false);
const showSaveButtonDisable = () => {
  saveButtonDisable.value = true;
};
const hideSaveButtonDisable = () => {
  saveButtonDisable.value = false;
};
const form = ref({
  categoryName: "",
  content: ""
});
//输入信息规则
const formRules = ref({
  categoryName: [{ required: true, message: "请选择分类", trigger: "blur" }],
  content: [{ required: true, message: "请输入内容", trigger: "change" }]
});
// 重置信息
const resetForm = () => {
  formRef.value.resetFields();
  form.value = {
    categoryName: "",
    content: ""
  };
  formType = "";
};
// 获取分类下拉框列表
const getCategorySelectList = async () => {
  let params = {
    objectId: datasetObjectId
  };
  let res = await httpPost("/config/category/dataset/get/name", params);
  if (res.code == 0) {
    categorySelectList.value = res.data.categoryList.map((item) => {
      return { value: item.name, label: item.name };
    });
  }
};
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("添加文本");
const DLGDictionaryName = route.query.DataSetName;
const openDLG = (type, params) => {
  console.log(params);
  formType = type;
  getCategorySelectList();
  if (type == "EDIT") {
    DLGTitle.value = "编辑文本";
    objectId = params.objectId;
    form.value = JSON.parse(JSON.stringify(params));
  } else {
    objectId = null;
    DLGTitle.value = "添加文本";
  }
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  nextTick(() => {
    resetForm();
  });
};
// 点击确认
const handleClickSave = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      showSaveButtonDisable();
      saveText();
    }
  });
};
// 保存功能接口调用
const saveText = async (text, row) => {
  showSaveButtonDisable();
  let url = "/config/category/dataset/text/save";
  let params = {
    ...form.value,
    objectId,
    datasetObjectId
  };
  console.log(params);
  let res = await httpPost(url, params);
  if (res.code === 0) {
    if (formType == "ADD") {
      ElMessage.success("添加成功");
    } else {
      ElMessage.success("编辑成功");
    }
    emits("refresh");
    closeDLG();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
  }
  hideSaveButtonDisable();
};
const paginationState = reactive({
  pageNum: 1,
  pageSize: 40,
  pageSizeList: [40, 80],
  total: 0
});
onMounted(() => {
  getCategorySelectList();
});
defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped></style>
