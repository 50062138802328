<template>
  <el-dialog
    v-model="DLGVisible"
    :before-close="closeDLG"
    width="40%"
    destroy-on-close
    class="base-dialog"
  >
    <template #header>
      <BaseDialogTitle :title="DLGTitle" :item-name="DLGCategoryModelName" />
    </template>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item label="模型名称：" prop="name">
        <el-input v-model="ruleForm.name" placeholder="请输入模型名称" />
      </el-form-item>
      <el-form-item label="分类算法：" prop="categoryAlgorithm">
        <el-select
          v-model="ruleForm.categoryAlgorithm"
          placeholder="请选择分类算法"
        >
          <el-option
            v-for="item in categoryAlgorithmList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="samplingPercent" label="采样比例：">
        <el-input-number
          v-model="ruleForm.samplingPercent"
          :precision="1"
          :step="0.1"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item prop="learningRate" label="学习率：">
        <el-input-number
          v-model="ruleForm.learningRate"
          :precision="3"
          :step="0.001"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="分词算法：">
        <el-radio-group v-model="ruleForm.tokenizeMethod">
          <el-radio :label="1">基本分词</el-radio>
          <el-radio :label="2">精准分词</el-radio>
          <el-radio :label="3">NLP分词</el-radio>
          <el-radio :label="4">面向索引分词</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="分词粒度：">
        <el-radio-group v-model="ruleForm.tokenizeGranularity">
          <el-radio :label="1">包含单字</el-radio>
          <el-radio :label="2">至少双字</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="自定义词典：">
        <el-select
          v-model="ruleForm.tokenizeDictionaryName"
          placeholder="系统常用中文分词词典"
        >
          <el-option
            v-for="tokenizeDictionaryListItem in tokenizeDictionaryList"
            :key="tokenizeDictionaryListItem.value"
            :label="tokenizeDictionaryListItem.label"
            :value="tokenizeDictionaryListItem.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button
          type="primary"
          :disabled="saveButtonDisable"
          @click="handleClickSave"
        >
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  onMounted
} from "vue";
import { httpPost } from "@/api/httpService.js";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import { ElMessage, ElOption, formContextKey } from "element-plus";
import { useRoute } from "vue-router";
import { Debounce } from "@/utils/utils";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR,
  ERROR_CODE_LANGUAGE_ERROR
} from "@/constant/error_code.js";

const route = useRoute();
const emits = defineEmits(["submit"]);
const categoryTrainDatasetObjectId = route.params.categoryTrainDatasetObjectId;
//分词词典列表
const tokenizeDictionaryList = ref([]);
const tokenizeMethod = ref(0);
const tokenizeGranularity = ref(0);
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("编辑分类模型");
const DLGCategoryModelName = route.query.DataSetName;
const ruleFormRef = ref(null);
const ruleForm = ref({
  name: "",
  objectId: "",
  categoryAlgorithm: "",
  tokenizeMethod: 0,
  tokenizeGranularity: 0,
  tokenizeDictionaryObjectId: "",
  tokenizeDictionaryName: "",
  samplingPercent: 0,
  learningRate: 0,
  tokenizeDictionaryList: [],
  categoryAlgorithmList: []
});
const categoryAlgorithmList = reactive([
  {
    value: "BP神经网络",
    label: "BP神经网络"
  }
]);
const openDLG = (type, params) => {
  if (type == "EDIT") {
    ruleForm.value = JSON.parse(JSON.stringify(params));
    console.log(ruleForm.value);
  }
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  // resetForm();
};
const saveButtonDisable = ref(false);
const showSaveButtonDisable = () => {
  saveButtonDisable.value = true;
};
const hideSaveButtonDisable = () => {
  saveButtonDisable.value = false;
};
//获取下拉框分词词典列表
const getDictionaryList = async (index) => {
  let res = await httpPost(`config/tokenize/dictionary/get/list`);
  if (res.code == 0) {
    tokenizeDictionaryList.value = res.data.tokenizeDictionaryList.map(
      (item) => {
        return { value: item.objectId, label: item.name };
      }
    );
  }
};

// // 点击单选按钮获取分词算法
// const handleTokenizeMethodSelect = () => {
//   let isTokenizeMethodSelected = tokenizeMethod.value;
//   return isTokenizeMethodSelected;
// };

// // 点击单选按钮获取分词粒度
// const handleTokenizeGranularitySelect = () => {
//   let isTokenizeGranularitySelect = tokenizeGranularity.value;
//   return isTokenizeGranularitySelect;
// };
// 输入信息规则
const rules = ref({
  name: [
    { required: true, message: "请输入分类训练集名称", trigger: "change" }
  ],
  categoryAlgorithm: [{ required: true }],
  samplingPercent: [
    { required: true, message: "请输入采样比例", trigger: "change" }
  ],
  learningRate: [{ required: true, message: "请输入学习率", trigger: "change" }]
});
/* const resetForm = () => {
  ruleFormRef.value.resetFields();
}; */
// 点击保存按钮，修改模型
const handleClickSave = () => {
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      handleClickEditCategoryDataSetModel();
    }
  });
};
// 编辑功能接口调用
const handleClickEditCategoryDataSetModel = Debounce(async () => {
  ruleForm.value.tokenizeDictionaryObjectId =
    ruleForm.value.tokenizeDictionaryName;
  let params = {
    name: ruleForm.value.name,
    objectId: ruleForm.value.objectId,
    categoryAlgorithm: ruleForm.value.categoryAlgorithm,
    samplingPercent: ruleForm.value.samplingPercent,
    learningRate: ruleForm.value.learningRate,
    tokenizeDictionaryObjectId: ruleForm.value.tokenizeDictionaryObjectId,
    // tokenizeDictionaryName: ruleForm.value.tokenizeDictionaryName,
    categoryTrainDatasetObjectId,
    tokenizeMethod: ruleForm.value.tokenizeMethod,
    tokenizeGranularity: ruleForm.value.tokenizeGranularity
  };
  console.log(params);
  let res = await httpPost("/config/category/dataset/model/save", params);
  if (res.code == 0) {
    ElMessage.success("编辑成功");
    emits("submit");
    closeDLG();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR ||
    res.code == ERROR_CODE_LANGUAGE_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
  }
});
/* const handleClickSaveDictionary = async (text, row) => {
  showSaveButtonDisable();
  let url = "/config/category/dataset/model/save";
  let params = {
    name: ruleForm.value.name,
    categoryAlgorithm: ruleForm.value.categoryAlgorithm,
    samplingPercent: ruleForm.value.samplingPercent,
    learningRate: ruleForm.value.learningRate,
    tokenizeDictionaryObjectId: ruleForm.value.tokenizeDictionaryList,
    // tokenizeMethod: handleTokenizeMethodSelect(),
    // tokenizeGranularity: handleTokenizeGranularitySelect(),
    categoryTrainDatasetObjectId
  };
  let res = await httpPost(url, params);
  if (res.code === 0) {
    emits("refresh");
    closeDLG();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
  }
  hideSaveButtonDisable();
}; */
onMounted(() => {
  getDictionaryList();
});
defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped></style>
