<template>
  <el-dialog v-model="DLGVisible" :before-close="closeDLG" class="base-dialog">
    <template #header>
      <BaseDialogTitle :title="DLGTitle" :item-name="DLGDictionaryName" />
    </template>
    <div>
      <el-input
        v-model="word"
        :rows="7"
        type="textarea"
        placeholder='请输入停用词，多个词语用英文逗号","或者换行符隔开均可。'
      />
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button
          type="primary"
          @click="handleClickConfirm"
          :disabled="saveButtonDisable"
        >
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, defineExpose } from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR,
  ERROR_CODE_LANGUAGE_ERROR
} from "@/constant/error_code.js";
import { ElMessage } from "element-plus";
const emits = defineEmits(["submit"]);
const route = useRoute();
let objectId = route.params.dictionaryId;

const saveButtonDisable = ref(false);
const showSaveButtonDisable = () => {
  saveButtonDisable.value = true;
};
const hideSaveButtonDisable = () => {
  saveButtonDisable.value = false;
};
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("新增停用词");
const DLGDictionaryName = route.query.dictionaryName;
const openDLG = (params) => {
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  word.value = "";
};
const word = ref("");
// 点击确认
const handleClickConfirm = async () => {
  showSaveButtonDisable();
  let params = {
    wordString: word.value,
    objectId
  };
  let res = await httpPost("/config/tokenize/dictionary/stop/save", params);
  if (res.code == 0) {
    emits("submit");
    closeDLG();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR ||
    res.code == ERROR_CODE_LANGUAGE_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    // row.ifShowLoading = false;
  }
  hideSaveButtonDisable();
};

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped></style>
