<template>
  <PageTitle :title="title" />
  <EncodingDictionaryDataTable :info="dictionaryInfo" />
</template>

<script setup>
import { defineEmits, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import PageTitle from "@/components/base/PageTitle";
import EncodingDictionaryDataTable from "./components/EncodingDictionaryDataTable.vue";
import { httpPost } from "@/api/httpService";

const route = useRoute();
const title = ref(route.query.dictionaryName);
const objectId = ref(route.params.dictionaryId);
const dictionaryInfo = ref(null);
const getDictionaryInfo = async () => {
  let params = {
    objectId: objectId.value
  };
  let url = "/config/encoding/dictionary/get/name";
  let res = await httpPost(url, params);
  dictionaryInfo.value = res.data;
  title.value = dictionaryInfo.value.name;
  console.log(res);
};
getDictionaryInfo();

// 面包屑
const emits = defineEmits(["setBreadcrumbList"]);
const setBreadcrumbList = () => {
  let BreadcrumbList = [
    { title: "配置", to: { name: "AnalysisConfigIndex" } },
    { title: "编码词典", to: { name: "EncodingDictionaryIndex" } },
    { title: title },
    { title: "数据" }
  ];
  emits("setBreadcrumbList", BreadcrumbList);
};
setBreadcrumbList();
</script>

<style lang="scss" scoped></style>
