export default class Tag {
  constructor(
    options = {
      value: "", //值
      inputValue: "",
      ifEdit: false,
      ifSelected: false
    }
  ) {
    this.value = options.value || ""; // tag显示值
    this.inputValue = options.value || ""; //
    // this.closable = options.closable || false; // tag是否可关闭
    this.ifEdit = options.ifEdit || false; //
    this.ifSelected = options.ifSelected || false;
  }
}
