<template>
  <div class="table-wrapper list-table">
    <div class="option-bar">
      <div class="search-box">
        <el-input
          v-model="keywords"
          clearable
          placeholder="输入词典名称进行检索"
          style="width: 424px"
        ></el-input>
        <el-button type="primary" @click="getDictionaryList()">搜索</el-button>
      </div>

      <div class="button-group">
        <span>
          <!-- <el-button @click="handleClickBatchDeleteDictionary">
            批量删除
          </el-button> -->
        </span>

        <!-- <el-button @click="handleClickImportWordSegmentationDictionary">
          导入词典
        </el-button> -->
        <el-button
          type="primary"
          @click="handleClickCreateWordSegmentationDictionary"
        >
          新建词典
        </el-button>
      </div>
    </div>
    <el-table
      :data="dictionaryList"
      style="width: 100%"
      header-cell-class-name="list-table-header"
      @selection-change="handleSelectionChange"
      v-loading="dictionaryLoading"
    >
      <!-- <el-table-column type="selection" width="55" :selectable="ifSelectable" /> -->
      <el-table-column type="selection" width="55" />
      <el-table-column prop="name" label="词典名称">
        <template #default="scope">
          <TextEditor
            @go="handleClickToWordDictionaryData(scope.row)"
            :text="scope.row.name"
            :editable="scope.row.userId !== SYSTEM_USER_ID"
            v-model:edit="scope.row.ifEdit"
            v-model:loading="scope.row.ifShowLoading"
            @save="(text) => handleClickSaveDictionary(text, scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="tokenizeLanguage" label="语言">
        <template #default="scope">
          {{ DICTIONARY_LANGUAGE[scope.row.tokenizeLanguage] }}
        </template>
      </el-table-column>
      <el-table-column prop="userId" label="创建人">
        <template #default="scope">
          {{ scope.row.userId === SYSTEM_USER_ID ? "系统" : username }}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column label="操作" width="200">
        <template #default="scope">
          <div class="list-item-btn">
            <el-button
              link
              type="primary"
              @click="handleClickToWordDictionaryData(scope.row)"
            >
              数据
            </el-button>
            <el-button
              v-if="scope.row.userId !== SYSTEM_USER_ID"
              link
              type="primary"
              @click="handleClickRename(scope.row)"
            >
              重命名
            </el-button>
            <el-button
              link
              type="primary"
              v-if="scope.row.userId !== SYSTEM_USER_ID"
              @click="handleClickDeleteDictionary(scope.row)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
    <WordSegmentationDictionaryDialogAdd
      ref="WordSegmentationDictionaryDialogAddRef"
      @refresh="getDictionaryList"
    />
  </div>
  <DialogImport
    ref="dialogImportRef"
    :item-name="info ? info.name : ''"
    :upload-params="{
      id: objectId,
      resourceType: RESOURCE_TYPE.RESOURCE_TOKENIZE_DICTIONARY
    }"
    :template-obj="{
      tHeader: ['词组名称', '词性', '权重'],
      filterVal: ['name', 'category', 'value'],
      list: [{ name: '自定义词组', category: '动词', value: 1000 }],
      fileName: '自定义词模板文件'
    }"
    import-url="/config/tokenize/dictionary/importConfirm"
    @refresh="getSegmentationDictaryDataList"
  ></DialogImport>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  watch,
  onMounted,
  computed
} from "vue";
import { SYSTEM_USER_ID } from "@/constant/system.js";
import { DICTIONARY_LANGUAGE } from "@/constant/dictionary.js";
import { httpPost } from "@/api/httpService.js";
import TextEditor from "@/components/base/TextEditor.vue";
import { confirmDeleteBox } from "@/utils/messageBox";
import WordSegmentationDictionaryDialogAdd from "./WordSegmentationDictionaryDialogAdd.vue";
import { ElMessage } from "element-plus";
import VueCookies from "vue-cookies";
import { useRouter } from "vue-router";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
import { RESOURCE_TYPE } from "@/constant/upload/resource_type.js";

import DialogImport from "@/components/base/DialogImport.vue";

// 获取用户名
const username = ref("xxxxx");
let userInfo = VueCookies.get("userInfo");
if (userInfo) {
  username.value = userInfo.name;
}
//分页
const paginationState = reactive({
  pageNum: 1,
  pageSize: 10,
  pageSizeList: [10, 20, 50],
  total: 0
});
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getDictionaryList();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getDictionaryList();
};

const keywords = ref("");
// 文本库列表数据
const dictionaryList = ref([]); // 列表数据
//获取当前页面信息（列表数据、文件夹路径）
const getDictionaryList = async () => {
  showdictionaryLoading();
  let url = "/config/tokenize/dictionary/get/list";
  let params = {
    ifOnlyGetNotNull: false,
    keywords: keywords.value,
    // tokenizeLanguage: 0,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize
  };
  let res = await httpPost(url, params);
  hidedictionaryLoading();
  if (res.code == 0) {
    dictionaryList.value = res.data.tokenizeDictionaryList;
    // dictionaryList.value.forEach((item) => {
    //   item.ifEdit = false;
    // });
    // folderLevelList.value = res.data.folderLevelList;
    paginationState.total = res.data.total;
  }
};
//文本库加载
const dictionaryLoading = ref(false);
const showdictionaryLoading = () => {
  dictionaryLoading.value = true;
};
const hidedictionaryLoading = () => {
  dictionaryLoading.value = false;
};

const handleClickSaveDictionary = async (text, row) => {
  if (!text) {
    ElMessage.warning("名称不能为空");
    row.ifShowLoading = false;
    return;
  }
  let url = "/config/tokenize/dictionary/save";
  let params = {
    name: text,
    objectId: row.objectId,
    tokenizeLanguage: row.tokenizeLanguage
  };
  let res = await httpPost(url, params);
  if (res.code === 0) {
    ElMessage.success(res.message);
    row.name = text;
    row.ifEdit = false;
    getDictionaryList();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    row.ifShowLoading = false;
  } else {
    //系统未知错误,由外部捕获错误，然后重新获取列表数据
    getDictionaryList();
  }
};
const ifSelectable = (row) => {
  // console.log(row);
  return row.userId !== SYSTEM_USER_ID;
};
const handleClickRename = (row) => {
  row.ifEdit = true;
};
const handleClickDeleteDictionary = (row) => {
  batchDeleteDictionary([row.objectId]);
};
const handleClickBatchDeleteDictionary = () => {
  const arr = [];
  wordDictaryDataSelectionList.value.forEach((item) => {
    if (item.userId !== SYSTEM_USER_ID) {
      arr.push(item.objectId);
    }
  });
  console.log(arr);
  if (arr.length === 0) {
    ElMessage.warning("请选择您要删除的自定义分词词典");
    return;
  }
  batchDeleteDictionary(arr);
};
const batchDeleteDictionary = (list) => {
  confirmDeleteBox("此操作将删除您所选自定义分词词典，是否继续？").then(
    async () => {
      let url = "/config/tokenize/dictionary/delete";
      let params = {
        objectIdList: list
      };
      let res = await httpPost(url, params);
      if (res.code === 0) {
        ElMessage.success("删除成功");
        getDictionaryList();
      }
    }
  );
};
const wordDictaryDataSelectionList = ref([]);
const handleSelectionChange = (val) => {
  wordDictaryDataSelectionList.value = val;
};
const WordSegmentationDictionaryDialogAddRef = ref(null);
const handleClickCreateWordSegmentationDictionary = (val) => {
  WordSegmentationDictionaryDialogAddRef.value.openDLG();
  // wordDictaryDataSelectionList.value = val;
};
// 批量导入
const dialogImportRef = ref(null);
// 批量导入
const handleClickImportWordSegmentationDictionary = () => {
  console.log("open");
  dialogImportRef.value.openDLG();
};

const router = useRouter();
const handleClickToWordDictionaryData = (row) => {
  router.push({
    name: "WordSegmentationDictionaryData",
    params: {
      dictionaryId: row.objectId
    },
    query: {
      dictionaryName: row.name
    }
  });
};
onMounted(() => {
  getDictionaryList();
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
.table-wrapper {
  .library-name {
    @include pointer();
    @include no-selectable();
    .icon-fill-folder {
      color: $folder-icon-color;
    }
    .icon-textLibrary {
      color: $theme-color;
    }
  }
}
</style>
