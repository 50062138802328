<template>
  <div class="list-table">
    <div class="option-bar">
      <div>
        <el-input
          class="search-input"
          v-model="keywords"
          placeholder="输入词语表名称进行检索"
          @keyup.enter="getWordList"
          clearable
        />
        <el-button type="primary" @click="getWordList">搜索</el-button>
      </div>
      <div>
        <!-- <el-button plain @click="handleClickDeleteWord(true)">
          批量删除
        </el-button> -->
        <el-button type="primary" @click="handleClickAddWord">
          新建词语表
        </el-button>
      </div>
    </div>
    <el-table
      :data="wordList"
      style="width: 100%"
      header-cell-class-name="list-table-header"
      @selection-change="handleSelectionChange"
      v-loading="tableLoading"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="词语表名称">
        <template #default="scope">
          <TextEditor
            :text="scope.row.name"
            :editable="scope.row.userId != SYSTEM_USER_ID"
            v-model:edit="scope.row.ifEdit"
            v-model:loading="scope.row.ifShowLoading"
            @save="(text) => handleClickSaveListItemName(text, scope.row)"
            @cancel="handleClickCancelRename(scope)"
            @go="handleClickToWordListData(scope.row)"
          ></TextEditor>
        </template>
      </el-table-column>
      <el-table-column prop="userId" label="创建人">
        <template #default="scope">
          {{ ifSystem(scope.row.userId) ? "系统" : username }}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <div class="list-item-btn">
            <el-button
              link
              type="primary"
              @click="handleClickToWordListData(scope.row)"
            >
              数据
            </el-button>
            <el-button
              v-if="!ifSystem(scope.row.userId)"
              @click="showEditor(scope.row)"
              link
              type="primary"
            >
              重命名
            </el-button>
            <el-button
              link
              type="primary"
              v-if="!ifSystem(scope.row.userId)"
              @click="handleClickDeleteWord(false, scope.row)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import { httpPost } from "@/api/httpService.js";
import CommonWord from "./CommonWord";
import { SYSTEM_USER_ID } from "@/constant/system";
import { Debounce } from "@/utils/utils";
import VueCookies from "vue-cookies";
import TextEditor from "@/components/base/TextEditor.vue";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
// 表格loading效果
const tableLoading = ref(false);
const hideTableLoading = () => {
  tableLoading.value = false;
};
const showTableLoading = () => {
  tableLoading.value = true;
};
// 获取词语表列表
const wordList = ref([]);
const keywords = ref("");
const getWordList = async () => {
  showTableLoading();
  let params = {
    ifOnlyGetNotNull: false,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize,
    keywords: keywords.value
  };
  let res = await httpPost("/config/common/table/get/list", params);
  if (res.code == 0) {
    wordList.value = res.data.commonWordListList;
    paginationState.total = res.data.total;
    hideTableLoading();
  }
};
// 新增词语表
const handleClickAddWord = () => {
  let word = new CommonWord({
    ifEdit: true
  });
  wordList.value.push(word);
};
// 显示重命名输入框
const showEditor = (row) => {
  row.newName = row.name;
  row.ifEdit = true;
};
const hideEditor = (row) => {
  row.newName = "";
  row.ifEdit = false;
};
// 保存新增词语表
const handleClickSaveListItemName = Debounce(async (text, row) => {
  if (!text) {
    ElMessage.warning("名称不能为空");
    row.ifShowLoading = false;
    return;
  }
  let params = {
    objectId: row.objectId,
    name: text
  };
  let res = await httpPost("/config/common/table/save", params);
  if (res.code == 0) {
    //保存成功,更新单行，并重新获取最新列表数据
    ElMessage.success(res.message);
    row.name = text;
    row.ifEdit = false;
    getWordList();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    row.ifShowLoading = false;
  } else {
    //系统未知错误,由外部捕获错误，然后重新获取列表数据
    getWordList();
  }
});
// 取消新增词语表
const handleClickCancelRename = (scope) => {
  if (scope.row.objectId) {
    return;
  }
  let index = scope.$index;
  wordList.value.splice(index, 1);
};
// 删除操作
const handleClickDeleteWord = (ifAll, row) => {
  if (ifAll && wordListSelectionList.value.length == 0) {
    ElMessage.warning("请选择您要删除的词语表");
    return;
  }
  confirmDeleteBox("此操作将删除您所选词语表，是否继续？").then(async () => {
    let params;
    if (ifAll) {
      params = {
        objectIdList: wordListSelectionList.value
      };
    } else {
      params = {
        objectIdList: [row.objectId]
      };
    }
    let res = await httpPost("/config/common/table/delete", params);
    if (res.code == 0) {
      ElMessage.success("删除成功");
      getWordList();
    }
  });
};
// 获取用户名
const username = ref("xxxxx");
let userInfo = VueCookies.get("userInfo");
if (userInfo) {
  username.value = userInfo.name;
}
// 分页信息
const paginationState = reactive({
  pageNum: 1,
  pageSize: 10,
  pageSizeList: [10, 20, 30, 40],
  total: 0
});
// 改变页面容纳数据量
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getWordList();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getWordList();
};
// 多选操作
const wordListSelectionList = ref([]);
const handleSelectionChange = (val) => {
  wordListSelectionList.value = [];
  val.forEach((item) => {
    wordListSelectionList.value.push(item.objectId);
  });
};
// 跳转数据页面
const router = useRouter();
const handleClickToWordListData = (row) => {
  router.push({
    name: "CommonWordDictionaryData",
    params: {
      dictionaryId: row.objectId
    },
    query: {
      name: row.name
    }
  });
};
// 判断当前词语表是否为系统的词语表
const ifSystem = computed(() => (userId) => {
  return userId == SYSTEM_USER_ID;
});
onMounted(() => {
  getWordList();
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
.search-input {
  width: 424px;
}
</style>
