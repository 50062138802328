<template>
  <div class="list-table">
    <div class="option-bar">
      <!-- <TagDropdown
        ref="TagListRef"
        v-loading="categoryTagLoading"
        :data="categoryList"
        :checkedList="[categoryName]"
        :disabledList="[]"
        @checked-tag="handleClickTag"
        @add-tag="handleAddTag"
        @delete-tag="handleDeleteTag"
        @edit-tag="handleEditTag"
      ></TagDropdown> -->
      <TagList
        ref="TagListRef"
        v-loading="categoryTagLoading"
        :addable="!ifSystemUser"
        @tag-add="handleAddTag"
      >
        <TagListItem
          label="全部"
          :checked="isAllChecked"
          @tag-click="handleClickAll"
        ></TagListItem>
        <TagListItem
          v-for="(categoryItem, categoryIndex) in categoryList"
          ref="TagListItemRef"
          :key="categoryIndex"
          :label="categoryItem.name"
          :checked="categoryItem.isChecked"
          :dropable="!ifSystemUser"
          :ifEdit="categoryItem.ifEdit"
          @tag-edit="
            (oldVal, newVal) => handleEditTag(categoryItem, oldVal, newVal)
          "
          @tag-click="handleClickTag(categoryItem)"
        >
          <template #dropdown-menu>
            <el-dropdown-item @click="handleDeleteTag(categoryItem)">
              删除
            </el-dropdown-item>
            <el-dropdown-item @click="showEditInput(categoryItem)">
              重命名
            </el-dropdown-item>
          </template>
        </TagListItem>
      </TagList>
      <div class="bar-operate">
        <!-- <el-select
          class="select-input"
          v-model="categoryName"
          placeholder="请选择分类"
          @change="getEventsList"
        >
          <el-option
            v-for="categoryItem in getCategoryOptions(categoryList)"
            :key="categoryItem.value"
            :label="categoryItem.label"
            :value="categoryItem.value"
          ></el-option>
        </el-select> -->
        <el-input
          class="search-input"
          v-model="keyword"
          placeholder="请输入事件名称或者任一事件规则关键词"
          @keyup.enter="getEventsList"
          @input="getEventsList"
          clearable
        >
          <template #suffix>
            <el-icon @click="getEventsList" :size="14">
              <Search />
            </el-icon>
          </template>
        </el-input>
      </div>
      <div class="button-group">
        <span>
          <el-button @click="handleClickBatchExport" v-if="!ifSystemUser">
            全部导出
          </el-button>
          <el-dropdown @command="handleClickBatchCommand" v-if="!ifSystemUser">
            <el-button>
              批量操作
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="batchImport">
                  批量导入
                </el-dropdown-item>
                <!-- <el-dropdown-item command="batchExport">
                  批量导出
                </el-dropdown-item> -->
                <el-dropdown-item command="batchDelete">
                  批量删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button
            type="primary"
            @click="handleClickAddEvent"
            v-if="!ifSystemUser"
          >
            新增事件
          </el-button>
        </span>
      </div>
    </div>
    <el-table
      :data="eventsList"
      style="width: 100%"
      header-cell-class-name="list-table-header"
      @selection-change="handleSelectionChange"
      v-loading="tableLoading"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="categoryName" label="分类" />
      <el-table-column prop="encoding" label="事件编码" />
      <el-table-column prop="name" label="事件名称">
        <template #default="scope">
          <TextEditor
            :text="scope.row.name"
            :editable="scope.row.userId != SYSTEM_USER_ID"
            v-model:edit="scope.row.ifEdit"
            v-model:loading="scope.row.ifShowLoading"
            @save="(text) => handleClickSaveListItemName(text, scope.row)"
            @cancel="handleClickCancelRename(scope)"
          ></TextEditor>
        </template>
      </el-table-column>
      <el-table-column prop="eventRule" label="事件规则" width="350">
        <template #default="scope">
          <div class="cell" v-html="formatterRule(scope.row)"></div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" v-if="!ifSystemUser">
        <template #default="scope">
          <div class="list-item-btn">
            <el-button
              link
              type="primary"
              @click="handleClickEditEvent(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              link
              type="primary"
              @click="handleClickDeleteEvent(false, scope.row)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
    <!-- <EventsDialogManage
      ref="eventsDialogManageRef"
      @submit="getEventsList"
      width="60%"
    /> -->
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, nextTick, computed, defineProps } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { httpPost } from "@/api/httpService.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import TextEditor from "@/components/base/TextEditor.vue";
import TagList from "@/components/tagList/TagList.vue";
import TagListItem from "@/components/tagList/TagListItem.vue";
// import EventsDialogManage from "./EventsDialogManage.vue";
import { ArrowDown, Search } from "@element-plus/icons-vue";
import TagBox from "@/components/tag/TagBox.vue";
import { SYSTEM_USER_ID } from "@/constant/system";
import { Debounce } from "@/utils/utils";
import {
  DATA_ERROR,
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
import { downloadFile } from "@/utils/downloadFile.js";
import DialogImport from "@/components/base/DialogImport.vue";
import { RESOURCE_TYPE } from "@/constant/upload/resource_type.js";
import { async } from "q";
const route = useRoute();
const router = useRouter();
let objectId = route.params.dictionaryId;
let dictionaryName = route.query.dictionaryName;

const props = defineProps({
  info: {
    type: Object
  }
});

// 权限相关
// 该词典拥有者是否为系统
const ifSystemUser = computed(() => {
  console.log(props);
  if (props.info) {
    return props.info.userId == SYSTEM_USER_ID;
  } else {
    return true;
  }
});

// 表格loading效果
const tableLoading = ref(false);
const hideTableLoading = () => {
  tableLoading.value = false;
};
const showTableLoading = () => {
  tableLoading.value = true;
};

//表格事件规则单元格渲染
const formatterRule = (row) => {
  return `<div>参与者：${row.participantRule} </div><div>行为：${row.actionRuleList} </div>`;
};

const keyword = ref("");
const categoryName = ref(null);
const eventsList = ref([]);
//分类标签列表
const categoryList = ref([]);
// 获取事件词典事件数据列表
const getEventsList = async () => {
  showTableLoading();
  let categoryNameTemp = categoryName.value;
  if (categoryNameTemp == "全部") {
    categoryNameTemp = null;
  }
  let params = {
    keyword: keyword.value,
    categoryName: categoryNameTemp,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize,
    objectId
  };
  // console.log(params);
  let res = await httpPost("/config/event/dictionary/event/get/list", params);
  hideTableLoading();
  if (res.code == 0) {
    eventsList.value = res.data.eventList;
    paginationState.total = res.data.total;
  }
};

//控制标签的input编辑框
// const inputEditRef = ref(null);
// const handleInputRef = (ref) => {
//   inputEditRef.value = ref;
// };
const TagListItemRef = ref(null);
// const oldValue = ref("");
const showEditInput = (item) => {
  item.ifEdit = true;
  // oldValue.value = item.name;
  console.log(TagListItemRef.value);
  // nextTick(() => {
  //   TagListItemRef.value.inputEditRef.value.focus();
  // });
};
//子组件=>选中事件分类标签的名字
const isAllChecked = ref(true);
const handleClickTag = (item) => {
  //使所有tag处于未选中状态
  categoryList.value.forEach((tempItem) => {
    if (tempItem.name === item.name) {
      tempItem.isChecked = true;
    } else {
      tempItem.isChecked = false;
    }
  });
  isAllChecked.value = false;
  categoryName.value = item.name;
  getEventsList();
};
const handleClickAll = () => {
  //使所有tag处于未选中状态
  categoryList.value.forEach((tempItem) => {
    tempItem.isChecked = false;
  });
  categoryName.value = "全部";
  isAllChecked.value = true;
  getEventsList();
};

// 标签选择器的loading相关
const categoryTagLoading = ref(true);
const showTagLoading = () => {
  categoryTagLoading.value = true;
};
const hideTagLoading = () => {
  categoryTagLoading.value = false;
};
// 获取事件标签列表
const getEventCategoryList = async () => {
  showTagLoading();
  let params = {
    objectId
  };
  let res = await httpPost("/config/event/dictionary/get/name", params);
  if (res.code == 0) {
    categoryList.value = res.data.categoryList.map((item) => ({
      name: item.name,
      isChecked: false,
      ifEdit: false
    }));
    //如果没有“全部”标签则加上，如果有则改为选中
    // if (!categoryList.value.some((item) => item.name === "全部")) {
    //   categoryList.value.unshift({ name: "全部", isChecked: true, type: 0 });
    // } else {
    //   categoryList.value.forEach((item) => {
    //     if (item.name === "全部") {
    //       item.isChecked = true;
    //     }
    //   });
    // }
  }
  hideTagLoading();
};
// const getCategoryOptions = computed(() => (categoryList) => {
//   return categoryList.map((item) => ({
//     value: item.name,
//     label: item.name
//   }));
// });
// 新增事件分类标签接口调用
const handleAddTag = async (inputCategoryValue, inputCategoryVisible) => {
  let url = "/config/event/dictionary/save/category";
  let params = {
    objectId,
    categoryName: inputCategoryValue.value
  };
  showTagLoading();
  let res = await httpPost(url, params);
  if (res.code === 0) {
    ElMessage.success(res.message);
    inputCategoryVisible.value = false;
    inputCategoryValue.value = "";
    categoryName.value = "全部";
    isAllChecked.value = true;
    getEventCategoryList();
    getEventsList();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
  }
  hideTagLoading();
};
// 删除单个分类标签
const handleDeleteTag = Debounce((tag) => {
  confirmDeleteBox("是否确认删除选中分类？").then(async () => {
    let params = {
      objectId,
      name: tag.name
    };
    showTagLoading();
    let url = "/config/event/dictionary/category/delete";
    let res = await httpPost(url, params);
    if (res.code == 0) {
      if (tag.name === categoryName.value) {
        categoryName.value = "全部";
        isAllChecked.value = true;
      }
      ElMessage.success("删除成功");
    }
    getEventCategoryList();
    getEventsList();
    hideTagLoading();
  });
});
// 重命名事件分类标签
const handleEditTag = Debounce(async (item, oldValue, inputEditValue) => {
  if (inputEditValue.value !== "") {
    let params = {
      objectId,
      oldCategoryName: oldValue.value,
      categoryName: inputEditValue.value
    };
    showTagLoading();
    let url = "/config/event/dictionary/save/category";
    let res = await httpPost(url, params);
    if (res.code == 0) {
      ElMessage.success(res.message);
      item.ifEdit = false;
      inputEditValue.value = "";
      if (item.name === categoryName.value) {
        categoryName.value = "全部";
        isAllChecked.value = true;
      }
      getEventCategoryList();
      getEventsList();
    } else if (
      res.code == ERROR_CODE_NAME_DUPLICATE ||
      res.code == PARAM_VALID_ERROR
    ) {
      //系统已知错误,用户重新编辑
      ElMessage.warning(res.message);
    }
    hideTagLoading();
  } else {
    item.ifEdit = false;
  }
});

// const eventsDialogManageRef = ref(null);
// 编辑弹框
const handleClickEditEvent = (row) => {
  const resolved = router.resolve({
    name: "EventsEditIndex",
    params: {
      eventName: row.name
    },
    query: {
      dictionaryId: objectId,
      dictionaryName: dictionaryName
    }
  });
  const url = resolved.href;
  // router.push(resolved);
  window.open(url, "_blank");
  // eventsDialogManageRef.value.openDLG("EDIT", row);
};
// 新增弹框
const handleClickAddEvent = () => {
  const resolved = router.resolve({
    name: "EventsAddIndex",
    params: {
      dictionaryId: objectId
    },
    query: {
      dictionaryName: dictionaryName
    }
  });
  const url = resolved.href;
  // router.push(resolved);
  window.open(url, "_blank");
  // eventsDialogManageRef.value.openDLG("ADD");
};

const eventsSelectionList = ref([]);
// 多选操作
const handleSelectionChange = (val) => {
  var temp = {};
  eventsSelectionList.value = [];
  val.forEach((item) => {
    temp["eventName"] = item.name;
    temp["categoryName"] = item.categoryName;
    eventsSelectionList.value.push(temp);
  });
};
// 批量操作
const handleClickBatchCommand = (command) => {
  switch (command) {
    case "batchImport":
      handleClickBatchImport();
      break;
    case "batchExport":
      handleClickBatchExport();
      break;
    case "batchDelete":
      handleClickDeleteEvent(true, null);
      break;
  }
};
// 批量导入
const handleClickBatchImport = () => {};
// 批量导出
const handleClickBatchExport = () => {
  console.log("download");
  downloadFile(`config/event/dictionary/export?objectId=${objectId}`);
};
// 删除或批量删除
const handleClickDeleteEvent = (ifBatch, row) => {
  if (ifBatch && eventsSelectionList.value.length == 0) {
    ElMessage.warning("请选择您要删除的事件");
    return;
  }
  confirmDeleteBox("此操作将删除您所选事件，是否继续？").then(async () => {
    let params;
    if (ifBatch) {
      params = {
        objectId,
        eventNameList: eventsSelectionList.value
      };
    } else {
      var temp = {};
      temp["eventName"] = row.name;
      temp["categoryName"] = row.categoryName;
      params = {
        objectId,
        eventNameList: [temp]
      };
    }
    let res = await httpPost("/config/event/dictionary/event/delete", params);
    if (res.code == 0) {
      ElMessage.success("删除成功");
      getEventsList();
    }
  });
};
// 保存重命名操作
const handleClickSaveListItemName = Debounce(async (text, row) => {
  if (!text) {
    ElMessage.warning("名称不能为空");
    row.ifShowLoading = false;
    return;
  }
  let params = {
    objectId: objectId,
    categoryName: row.categoryName,
    oldEventName: row.name,
    name: text,
    participantRule: row.participantRule,
    actionRule: row.actionRule
  };
  let res = await httpPost("/config/event/dictionary/event/save", params);
  if (res.code == 0) {
    //保存成功,更新单行，并重新获取最新列表数据
    ElMessage.success(res.message);
    row.name = text;
    row.ifEdit = false;
    getEventsList();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    row.ifShowLoading = false;
  } else {
    //系统未知错误,由外部捕获错误，然后重新获取列表数据
    getEventsList();
  }
});
// 如不想重命名事件，则点击单元格取消
const handleClickCancelRename = (scope) => {
  let index = scope.$index;
  if (!scope.row.name) {
    eventsList.value.splice(index, 1);
  }
};

// 分页信息
const paginationState = reactive({
  pageNum: 1,
  pageSize: 10,
  pageSizeList: [10, 20, 30, 40],
  total: 0
});
// 改变页面容纳数据量
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getEventsList();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getEventsList();
};

onMounted(() => {
  getEventsList();
  getEventCategoryList();
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
.option-bar {
  display: flex;
  flex-wrap: wrap;
  // .bar-tag {
  //   display: flex;
  //   flex: 1 100%;
  //   flex-wrap: wrap;
  //   margin-bottom: 10px;
  //   .tag-input {
  //     margin-left: 15px;
  //     margin-bottom: 10px;
  //     width: 10%;
  //   }
  //   .tag-check {
  //     margin-bottom: 10px;
  //     margin-left: 15px;
  //     :deep(.el-button) {
  //       border: 0px;
  //       background-color: #f5f7fa;
  //     }
  //     .tag-dropdown {
  //       .el-icon--right {
  //         margin-left: 20px;
  //       }
  //     }
  //   }
  // }
  .bar-operate {
    flex: 1 auto;
    // margin-left: 15px;
    .select-input {
      width: 180px;
      margin-right: 20px;
    }
    .search-input {
      width: 320px;
    }
  }
  .button-group {
    display: flex;
    justify-content: flex-end;
    flex: 1 auto;
  }
}
</style>
