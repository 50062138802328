<template>
  <el-dialog
    v-model="DLGVisible"
    :before-close="closeDLG"
    width="40%"
    destroy-on-close
    class="base-dialog"
  >
    <template #header>
      <BaseDialogTitle title="新建分词词典" />
    </template>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
    >
      <el-form-item label="词典名称" prop="name">
        <el-input v-model="ruleForm.name" />
      </el-form-item>
      <el-form-item label="语言" prop="tokenizeLanguage">
        <el-select v-model="ruleForm.tokenizeLanguage" placeholder="Select">
          <el-option
            v-for="item in DICTIONARY_LANGUAGE_LIST"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button
          type="primary"
          :disabled="saveButtonDisable"
          @click="handleClickSave"
        >
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, defineExpose } from "vue";
import { httpPost } from "@/api/httpService.js";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import { DICTIONARY_LANGUAGE_LIST } from "@/constant/dictionary.js";
import { ElMessage } from "element-plus";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
// import {}

const emits = defineEmits(["refresh"]);

// 弹窗相关
const DLGVisible = ref(false);
const openDLG = (params) => {
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  resetForm();
};
const saveButtonDisable = ref(false);
const showSaveButtonDisable = () => {
  saveButtonDisable.value = true;
};
const hideSaveButtonDisable = () => {
  saveButtonDisable.value = false;
};
const ruleForm = ref({
  name: "",
  tokenizeLanguage: 0
});

const rules = ref({
  name: [{ required: true, message: "请输入词典名称", trigger: "change" }],
  tokenizeLanguage: [{ required: true }]
});
const ruleFormRef = ref(null);
const resetForm = () => {
  ruleFormRef.value.resetFields();
};
const handleClickSave = () => {
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      handleClickSaveDictionary();
    }
  });
};
const handleClickSaveDictionary = async (text, row) => {
  showSaveButtonDisable();
  let url = "/config/tokenize/dictionary/save";
  let params = {
    name: ruleForm.value.name,
    tokenizeLanguage: ruleForm.value.tokenizeLanguage
  };
  let res = await httpPost(url, params);
  if (res.code === 0) {
    emits("refresh");
    closeDLG();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    // row.ifShowLoading = false;
  }
  // else if(res.code===){
  //   ElMessage.warning(res.message);

  // }
  hideSaveButtonDisable();
};
defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped></style>
