<template>
  <div class="tag-item">
    <el-tooltip :content="tooltipContent" placement="top" effect="dark">
      <div
        :class="[
          'tag-content',
          selectable && tagState.ifSelected ? 'tag-content-selected' : '',
          disabled ? 'tag-content-disabled' : ''
        ]"
      >
        <el-input
          ref="inputRef"
          v-if="editable && tagState.ifEdit"
          v-model="tagState.inputValue"
          class="tag-input"
          @blur="handleChangeTagValue(tagState)"
          @keyup.enter="(event) => event.target.blur()"
        ></el-input>
        <div
          @click="handleClickTag(tagState)"
          @dblclick="handleDoubleClickTag(tagState)"
          v-else
        >
          {{ tagState.value }}
          <i
            :class="[
              'iconfont',
              'icon-chahao',
              closable ? 'close-btn' : 'hidden-close-btn'
            ]"
            @click.prevent="handleClickClose(tagState)"
          ></i>
        </div>
      </div>
    </el-tooltip>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  watch,
  nextTick,
  computed,
  defineExpose
} from "vue";
import Tag from "./Tag.js";
const props = defineProps({
  data: {
    type: Object
  },
  closable: {
    type: Boolean,
    default: () => false
  },
  editable: {
    type: Boolean,
    default: () => false
  },
  selectable: {
    type: Boolean,
    default: () => false
  },
  disabled: {
    type: Boolean,
    default: () => false
  },
  disabledTip: {
    type: String
  }
});

const emits = defineEmits(["change", "blur", "close"]);

const tagState = ref(new Tag({}));
watch(
  () => props.data,
  (newVal) => {
    // console.log("watch tag item data", newVal, tagState);

    if (newVal) {
      // tagState.value = JSON.parse(JSON.stringify(newVal));
      tagState.value = new Tag(newVal);
      // console.log("watch tag item data", newVal, tagState);
    }
  },
  {
    immediate: true,
    deep: true
  }
);
watch(
  () => props.disabled,
  (newVal) => {
    // console.log("watch props disabled", newVal);
    if (newVal) {
      tagState.value.ifEdit = false;
      tagState.value.ifSelected = false;
    }
  },
  {
    // immediate: true,
    deep: true
  }
);
const tooltipContent = computed(() => {
  return props.disabled && props.disabledTip
    ? props.disabledTip
    : tagState.value.value;
});

// 单击tag
const handleClickTag = (tag) => {
  if (props.disabled) {
    return;
  }
  if (props.selectable) {
    tagState.value.ifSelected = !tagState.value.ifSelected;
  }
};
const inputRef = ref(null);
// 双击tag
const handleDoubleClickTag = (tag) => {
  if (props.disabled) {
    return;
  }
  if (props.editable) {
    // editable为true时，支持编辑
    tag.inputValue = tag.value;
    tag.ifEdit = true;
    focus();
  }
};
const focus = () => {
  nextTick(() => {
    inputRef.value.focus();
  });
};
// 确认输入时
const handleChangeTagValue = (tag) => {
  // console.log("change tag value", tag);
  if (tag.value != tag.inputValue) {
    // 如果值没有变化则不emit
    emits("change", tag);
  } else {
    tag.ifEdit = false;
  }
  emits("blur");
};
// 点击关闭
const handleClickClose = (tag) => {
  emits("close", tag);
};

defineExpose({
  tag: tagState,
  focus
});
</script>

<style lang="scss" scoped>
$tag-height: 32.25px;
$input-height: 32px;
$font-color: #6c6c6c; //默认字体原色
$font-color-selected: rgba(233, 71, 67, 1); // 选中状态下字体颜色
$font-color-disabled: white;
$background-color: rgba(239, 239, 239, 0.6); // 默认背景颜色
$background-color-hover: rgba(190, 190, 190, 40); //hover背景颜色
$background-color-selected: rgba(233, 71, 67, 0.1); //选中背景颜色
$background-color-selected-hover: rgba(233, 71, 67, 0.2); //选中后hover背景颜色
$background-color-disabled: rgba(244, 143, 66, 50); // 禁用状态背景色
.tag-item {
  position: relative;
  display: inline-block;
  height: $tag-height;
  width: 125px;
  margin-bottom: 16px;
  margin-right: 16px;
  background-color: $background-color;
  @include round-corner(5px);
  color: $font-color;

  .tag-content {
    // padding: 0 2px;
    width: 100%;
    height: 100%;
    display: inline-block;
    line-height: $tag-height;
    text-align: center;
    @include hidden-with-ellipsis();
    @include no-selectable();
    @include round-corner(5px);
    &:hover {
      @include pointer();
      background: $background-color-hover;
      color: $font-color;
    }
  }
  // 选中后额外添加的样式
  .tag-content-selected {
    // @extend .tag-content;
    background-color: $background-color-selected;
    color: $font-color-selected;
    &:hover {
      @include pointer();
      background: $background-color-selected-hover;
      color: $font-color-selected;
    }
  }
  // 禁用状态
  .tag-content-disabled {
    background: $background-color-disabled;
    color: $font-color-disabled;
    cursor: not-allowed;
    &:hover {
      @extend .tag-content-disabled;
      cursor: not-allowed;
    }
  }

  .tag-input {
    width: 100%;
    position: relative;
    top: -1px + (32px - $tag-height);
    :deep(.el-input__wrapper) {
      border-radius: 5px;
    }
  }
  .hidden-close-btn {
    display: none;
  }
  .close-btn {
    // display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    width: 16px;
    height: 16px;
    @include round-corner(50%);
    color: white;
    background-color: #e94743;
    position: absolute;
    top: -8px;
    right: -8px;
    @include pointer();
  }
  &:hover {
    .close-btn {
      display: flex;
    }
  }
}
</style>
