// 字符串相关校验方法

// 判断是否所有字符都合法
export const isAllValid = (encodingLogicString) => {
  if (encodingLogicString.match("^.*[^\u4e00-\u9fa5\\w\\d()&|!]+.*$")) {
    return false;
  }
  return true;
};

// 成对符号匹配检查
export const isDoubleMatch = (encodingLogicString, left = "(", right = ")") => {
  let stack = [];
  for (let c of encodingLogicString) {
    if (c == left) {
      stack.push(c);
    } else if (c == right) {
      if (stack.length == 0 || stack.pop() != "(") {
        return false;
      }
    }
  }
  if (stack.length != 0) {
    return false;
  }
  return true;
};

// 检查逻辑关系符号是否只出现2次
export const isLogicSymbolTwice = (encodingLogicString) => {
  if (encodingLogicString.match("^.*[&|!][&|!][&|!].*$")) {
    return false;
  }
  let andCount = 0;
  let orCount = 0;
  let notCount = 0;
  for (let i = 0; i < encodingLogicString.length; i++) {
    let c = encodingLogicString[i];
    if (c == "&") {
      andCount++;
      // 判断下一个字符是否也是 &
      if (
        i + 1 < encodingLogicString.length &&
        encodingLogicString[i + 1] == "&"
      ) {
        andCount++;
        i++; // 跳过下一个字符
      } else if (
        andCount == 1 ||
        i == encodingLogicString.length - 1 ||
        encodingLogicString[i + 1] != "&"
      ) {
        return false;
      }
    } else if (c == "|") {
      orCount++;
      // 判断下一个字符是否也是 |
      if (
        i + 1 < encodingLogicString.length &&
        encodingLogicString[i + 1] == "|"
      ) {
        orCount++;
        i++; // 跳过下一个字符
      } else if (
        orCount == 1 ||
        i == encodingLogicString.length - 1 ||
        encodingLogicString[i + 1] != "|"
      ) {
        return false;
      }
    } else if (c == "!") {
      notCount++;
      // 判断下一个字符是否也是 !
      if (
        i + 1 < encodingLogicString.length &&
        encodingLogicString[i + 1] == "!"
      ) {
        orCount++;
        i++; // 跳过下一个字符
      } else if (
        notCount == 1 ||
        i == encodingLogicString.length - 1 ||
        encodingLogicString[i + 1] == "!"
      ) {
        return false;
      }
    }
  }
  return true;
};
