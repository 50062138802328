<template>
  <div class="upload-container">
    <el-upload
      class="upload-file"
      drag
      action=""
      ref="uploadRef"
      :file-list="uploadState.fileList"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      :auto-upload="false"
      :on-change="handleChange"
      :limit="1"
      :on-exceed="handleExceed"
      :on-remove="handleRemove"
      :disabled="loading"
    >
      <div v-show="!loading">
        <i class="iconfont icon-daoru upload-icon"></i>
        <div class="upload-text">将文件拖拽到此或点击添加</div>
      </div>
      <div v-show="loading">
        <i class="iconfont icon-icon_loading upload-icon"></i>
        <div class="upload-text">文件正在解析中</div>
      </div>

      <template #tip>
        <div class="upload-tip">
          请参考模板，上传具有相同表头标题的Excel文件，单次上传的文件大小不能超过500KB，详见
          <el-link
            :underline="false"
            type="danger"
            @click="handleClickDownLoadTemplateFile()"
          >
            模板文件
          </el-link>
          。
        </div>
      </template>
    </el-upload>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, defineExpose } from "vue";
import { httpPost } from "@/api/httpService.js";
import { export2Excel, exportSheets2Excel } from "@/utils/excel.js";

const props = defineProps({
  // 上传时携带的参数
  uploadParams: {
    type: Object
  },
  // 模板相关内容
  templateObj: {
    type: Object
  },
  loading: {
    type: Boolean
  }
});
const emits = defineEmits(["change", "remove"]);

// 上传文件相关
const uploadRef = ref(null);
const uploadState = reactive({
  wx: [],
  errorList: [],
  tableData: [],
  errorTableData: [],
  fileList: []
});

const handleClickDownLoadTemplateFile = () => {
  if (!Array.isArray(props.templateObj.sheets)) {
    let { tHeader, filterVal, list, fileName } = props.templateObj;
    export2Excel(tHeader, filterVal, list, fileName);
  } else {
    let { sheets, fileName } = props.templateObj;
    exportSheets2Excel(sheets, fileName);
  }
};

const handleChange = async (file) => {
  console.log("handlechage", file);
  emits("change", file);
};

const handleExceed = (files) => {
  uploadRef.value.clearFiles();
  const file = files[0];
  uploadRef.value.handleStart(file);
};

const handleRemove = () => {
  console.log("remove");
  emits("remove");
};
defineExpose({ handleClickDownLoadTemplateFile });
</script>

<style lang="scss" scoped>
.upload-container {
  margin-bottom: 10px;
  .upload-file {
    margin-bottom: 16px;
    :deep(.el-upload-dragger) {
      padding-top: 16px;
      padding-bottom: 20px;
      @include default-border($width: 0);
      background-color: rgba(233, 71, 67, 0.11);
      margin-bottom: 32px;
    }
    :deep(.el-upload-list) {
      // width: 150px;
    }
    .upload-icon {
      display: block;
      font-size: 30px;
      color: $theme-color;
      margin-bottom: 12px;
    }
    .upload-tip {
      .el-link {
        margin-top: -2px;
      }
    }
  }
}
</style>
