<template>
  <div class="tag-selector-box">
    <div class="tag-selector-box__header">
      <slot name="header"></slot>
    </div>
    <div class="tag-selector-container" :style="{ 'min-height': minHeight }">
      <div class="tag-list" v-if="tagList && tagList.length != 0">
        <TagSelectorBoxTagItem
          v-for="(tagItem, tagIndex) in tagList"
          :key="tagIndex"
          ref="tagItemRef"
          :data="tagItem"
          :closable="closable"
          :editable="editable"
          :selectable="selectable"
          :disabled="tagItem.ifDisabled"
          :disabled-tip="disabledTip"
          @change="(tag) => handleChangeTagValue(tag, tagIndex)"
          @close="(tag) => handleClickClose(tag, tagIndex)"
        ></TagSelectorBoxTagItem>

        <div class="suffix-box">
          <slot name="suffix"></slot>
        </div>
      </div>
      <div v-else>
        <EmptyStatus></EmptyStatus>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  watch,
  defineExpose,
  nextTick
} from "vue";
import { httpPost } from "@/api/httpService.js";
import TagSelectorBoxTagItem from "./TagSelectorBoxTagItem.vue";
import EmptyStatus from "@/components/base/EmptyStatus.vue";

import Tag from "./Tag.js";
import { ElMessage } from "element-plus";
const props = defineProps({
  data: {
    type: Array,
    default: () => []
  }, // 源数据
  editable: {
    type: Boolean,
    default: () => false
  }, //是否可编辑
  selectable: {
    type: Boolean,
    default: () => false
  }, // 是否可选择

  tagWidth: {
    type: [String, Number],
    default: () => "125px"
  }, // 标签宽度
  closable: {
    type: Boolean,
    default: () => false
  }, // 是否显示关闭按钮
  disabledTip: {
    type: String
  },
  minHeight: {
    type: String,
    default: "340px"
  }
});

const emits = defineEmits(["changeTag", "delete"]);

const tagList = ref([]);
watch(
  () => props.data,
  (newVal) => {
    // console.log("watch taglist data", newVal);
    if (newVal && newVal instanceof Array) {
      tagList.value = newVal.map((tag) => {
        return new Tag({
          value: tag
        });
      });
    }
  },
  {
    immediate: true,
    deep: true
  }
);
const init = () => {
  if (props.data && props.data instanceof Array) {
    tagList.value = props.data.map((tag) => {
      return new Tag({
        value: tag
      });
    });
  }
};
// tag值改变
const handleChangeTagValue = (tag, tagIndex) => {
  // console.log("receive change tag", tag, tagIndex);
  if (!tag.inputValue) {
    ElMessage.warning("不能为空！");
    tagItemRef.value[tagIndex].focus();
    tag.inputValue = tag.value;
    return;
  }
  let oldValue = tag.value;
  tag.ifEdit = false;
  emits("changeTag", tag.inputValue, oldValue, tagIndex);
  tag.value = tag.inputValue;
};
// 禁用/解除index位的tag
const setTagDisabled = (index, value = true) => {
  let tag = tagList.value[index];
  if (tag) {
    nextTick(() => {
      tagList.value[index].ifDisabled = value;
    });
  }
  // console.log("settagdisabled", index, value, tagList);
};

const handleClickClose = (tag, tagIndex) => {
  emits("delete", tag.value, tagIndex);
};
const tagItemRef = ref(null);
const getSelectedTagList = () => {
  let selectedTagList = tagItemRef.value
    .filter((item) => item.tag.ifSelected)
    .map((item) => item.tag.value);
  // console.log("selected", selectedTagList, tagList.value, tagItemRef);
  return selectedTagList;
};
defineExpose({
  setTagDisabled,
  getSelectedTagList,
  init
});
</script>

<style lang="scss" scoped>
$suffix-font-color: #bebebe;
.tag-selector-box {
  .tag-selector-box__header {
  }
  .tag-selector-container {
    position: relative;
    padding: 26px 20px;
    @include default-border();
    @include round-corner(5px);
    .suffix-box {
      color: $suffix-font-color;
      position: absolute;
      bottom: 8px;
    }
  }
}
</style>
