<template>
  <PageTitle :title="title" />
  <ConfigBox />
</template>

<script setup>
import { reactive, ref, defineEmits, onMounted } from "vue";
import PageTitle from "@/components/base/PageTitle";
import ConfigBox from "./components/ConfigBox.vue";
const title = "配置";
// 面包屑
const emits = defineEmits(["setBreadcrumbList"]);
const setBreadcrumbList = () => {
  let BreadcrumbList = [{ title: "配置" }];
  emits("setBreadcrumbList", BreadcrumbList);
};
setBreadcrumbList();
</script>

<style lang="scss" scoped></style>
