<template>
  <el-dialog v-model="DLGVisible" :before-close="closeDLG" class="base-dialog">
    <template #header>
      <BaseDialogTitle :title="DLGTitle" :item-name="DLGDictionaryName" />
    </template>
    <el-form ref="formRef" :model="form" :rules="formRules" label-width="120px">
      <el-form-item label="编码名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入编码名称" clearable />
      </el-form-item>
      <el-form-item label="编码逻辑" prop="logic">
        <el-input v-model="form.logic" placeholder="请输入编码逻辑" clearable />
      </el-form-item>
      <el-form-item>
        <span>支持且&&，或||，否定!! 逻辑和()</span>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button type="primary" @click="handleClickConfirm">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  nextTick
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import { Debounce } from "@/utils/utils";
import { ElMessage } from "element-plus";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR,
  ERROR_CODE_LANGUAGE_ERROR
} from "@/constant/error_code.js";
import {
  isAllValid,
  isDoubleMatch,
  isLogicSymbolTwice
} from "@/utils/string.js";
const emits = defineEmits(["submit"]);
const route = useRoute();
const form = ref({
  name: "",
  logic: "",
  oldEncodingName: ""
});

const formRef = ref(null);
let objectId = route.params.dictionaryId;
//输入信息规则
const validateLogic = (rule, value, callback) => {
  console.log("validate", rule, value, callback);
  if (!isAllValid(value)) {
    callback(new Error("存在非法字符"));
  }
  if (!isDoubleMatch(value)) {
    callback(new Error("括号不匹配"));
  }
  if (!isLogicSymbolTwice(value)) {
    callback(new Error("逻辑关系符号（&、|、!）不成对出现"));
  }
  callback();
};
const formRules = ref({
  name: [{ required: true, message: "请输入词典名称", trigger: "change" }],
  logic: [
    { required: true, message: "请输入编码逻辑" },
    { validator: validateLogic, trigger: ["blur", "change"] }
  ]
});
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("编辑编码");
const DLGDictionaryName = route.query.dictionaryName;
const openDLG = (type, params) => {
  // console.log("params11", params);

  if (type == "EDIT") {
    // DLGTitle.value = "编辑编码";
    form.value = JSON.parse(JSON.stringify(params));
    form.value.oldEncodingName = params.name;
  }
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  nextTick(() => {
    // resetForm();
  });
};
// 点击保存
const handleClickConfirm = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      handleClickEditEncodingDictionaryData();
    }
  });
};

// 编辑功能接口调用
const handleClickEditEncodingDictionaryData = Debounce(async () => {
  let params = {
    ...form.value,
    objectId
  };
  let res = await httpPost("/config/encoding/dictionary/encoding/save", params);
  if (res.code == 0) {
    ElMessage.success("编辑成功");
    emits("submit");
    closeDLG();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR ||
    res.code == ERROR_CODE_LANGUAGE_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
  }
});
defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
.custom-input {
  width: 40%;
  margin-left: 10px;
}
</style>
