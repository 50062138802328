<template>
  <div class="list-table">
    <div class="option-bar">
      <div>
        <el-input
          class="search-input"
          v-model="keyword"
          placeholder="输入关键词"
          @keyup.enter="getCodeDictionaryDataList"
          clearable
        />
        <el-button type="primary" @click="getCodeDictionaryDataList">
          查询
        </el-button>
      </div>
      <div class="button-group" v-if="!ifSystemUser">
        <span>
          <el-dropdown @command="handleClickBatchCommand">
            <el-button>
              批量操作
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="batchImport">
                  批量导入
                </el-dropdown-item>
                <el-dropdown-item command="batchExport">
                  批量导出
                </el-dropdown-item>
                <el-dropdown-item command="batchDelete">
                  批量删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button type="primary" @click="handleClickAddCodeDictionaryData">
            新增
          </el-button>
        </span>
      </div>
    </div>
    <el-table
      :data="emotionDictaryDataList"
      style="width: 100%"
      header-cell-class-name="list-table-header"
      @selection-change="handleSelectionChange"
      v-loading="tableLoading"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="name" label="编码名称">
        <template #default="scope">
          <TextEditor
            :text="scope.row.name"
            :editable="scope.row.userId != SYSTEM_USER_ID"
            v-model:edit="scope.row.ifEdit"
            v-model:loading="scope.row.ifShowLoading"
            @save="(text) => handleClickSaveListItemName(text, scope.row)"
          ></TextEditor>
        </template>
      </el-table-column>
      <el-table-column prop="logic" label="编码逻辑" />
      <el-table-column label="操作" width="250" v-if="!ifSystemUser">
        <template #default="scope">
          <div class="list-item-btn" v-if="!ifSystemUser">
            <el-button
              link
              type="primary"
              @click="handleClickEditDataRecord(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              v-show="!ifSystemUser"
              link
              type="primary"
              @click="handleClickRename(scope.row)"
            >
              重命名
            </el-button>
            <el-button
              v-show="!ifSystemUser"
              link
              type="primary"
              @click="handleClickDelete(false, scope.row)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
    <EncodingDictionaryDataDialogAdd
      ref="codeDictionaryDataDialogAddRef"
      @refresh="getCodeDictionaryDataList"
    />
    <EncodingDictionaryDataDialogEdit
      ref="codeDictionaryDataDialogManageRef"
      @submit="getCodeDictionaryDataList"
    />
  </div>
  <DialogImport
    ref="encodingDictionaryDataDialogImportRef"
    :item-name="info ? info.name : ''"
    :upload-params="{
      id: objectId,
      resourceType: RESOURCE_TYPE.RESOURCE_ENCODING_DICTIONARY
    }"
    :template-obj="{
      tHeader: ['编码名称', '编码逻辑'],
      filterVal: ['name', 'logic'],
      list: [{ name: '名称', logic: '逻辑' }],
      fileName: '编码词汇模板文件'
    }"
    import-url="/config/encoding/dictionary/importConfirm"
    @refresh="getCodeDictionaryDataList"
  ></DialogImport>
</template>

<script setup>
import { reactive, ref, onMounted, defineProps, computed } from "vue";
import { useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { httpPost } from "@/api/httpService.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import DialogImport from "@/components/base/DialogImport.vue";
import TextEditor from "@/components/base/TextEditor.vue";
import EncodingDictionaryDataDialogAdd from "./EncodingDictionaryDataDialogAdd.vue";
import EncodingDictionaryDataDialogEdit from "./EncodingDictionaryDataDialogEdit.vue";
import { ArrowDown } from "@element-plus/icons-vue";
import { SYSTEM_USER_ID } from "@/constant/system";
import { Debounce } from "@/utils/utils";
import { RESOURCE_TYPE } from "@/constant/upload/resource_type.js";
import { downloadFile } from "@/utils/downloadFile.js";

import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
const props = defineProps({
  info: {
    type: Object
  }
});
// 权限相关
// 该词典拥有者是否为系统
const ifSystemUser = computed(() => {
  if (props.info) {
    return props.info.userId == SYSTEM_USER_ID;
  } else {
    return true;
  }
});
const route = useRoute();
// 表格loading效果
const tableLoading = ref(false);
const keyword = ref("");
let objectId = route.params.dictionaryId;
const emotionDictaryDataList = ref([]);
const codeDictionaryDataDialogManageRef = ref(null);
const codeDictionaryDataDialogAddRef = ref(null);
const codeDictaryDataSelectionList = ref([]);

const hideTableLoading = () => {
  tableLoading.value = false;
};
const showTableLoading = () => {
  tableLoading.value = true;
};
// 获取编码词典数据列表
const getCodeDictionaryDataList = async () => {
  showTableLoading();
  let url = "/config/encoding/dictionary/encoding/get/list";
  let params = {
    keyword: keyword.value,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize,
    objectId
  };
  let res = await httpPost(url, params);
  hideTableLoading();
  if (res.code == 0) {
    emotionDictaryDataList.value = res.data.encodingList;
    paginationState.total = res.data.total;
  }
};
// 编辑数据
const handleClickEditDataRecord = (row) => {
  // console.log("11111", row);
  codeDictionaryDataDialogManageRef.value.openDLG("EDIT", row);
};
// 保存重命名
const handleClickRename = (row) => {
  row.ifEdit = true;
};
const handleClickSaveListItemName = Debounce(async (text, row) => {
  if (!text) {
    ElMessage.warning("名称不能为空");
    row.ifShowLoading = false;
    return;
  }
  let params = {
    name: text,
    objectId: objectId,
    oldEncodingName: row.name
  };
  let res = await httpPost("/config/encoding/dictionary/encoding/save", params);
  if (res.code == 0) {
    //保存成功,更新单行，并重新获取最新列表数据
    ElMessage.success(res.message);
    row.name = text;
    row.ifEdit = false;
    getCodeDictionaryDataList();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    row.ifShowLoading = false;
  } else {
    //系统未知错误,由外部捕获错误，然后重新获取列表数据
    getCodeDictionaryDataList();
  }
});
// 点击新增按钮，获取弹框
const handleClickAddCodeDictionaryData = (val) => {
  codeDictionaryDataDialogAddRef.value.openDLG();
};
// 多选操作
const handleSelectionChange = (val) => {
  codeDictaryDataSelectionList.value = [];
  val.forEach((item) => {
    codeDictaryDataSelectionList.value.push(item.name);
  });
};
// 批量操作
const handleClickBatchCommand = (command) => {
  switch (command) {
    case "batchImport":
      handleClickBatchImport();
      break;
    case "batchExport":
      handleClickBatchExport();
      break;
    case "batchDelete":
      handleClickDelete(true, null);
      break;
  }
};
// 批量导入
const encodingDictionaryDataDialogImportRef = ref(null);
const handleClickBatchImport = () => {
  encodingDictionaryDataDialogImportRef.value.openDLG();
};
// 批量导出
const handleClickBatchExport = async () => {
  downloadFile("config/encoding/dictionary/export?objectId=" + objectId);
};
// 删除或批量删除
const handleClickDelete = (ifBatch, row) => {
  if (ifBatch && codeDictaryDataSelectionList.value.length == 0) {
    ElMessage.warning("请选择您要删除的编码词");
    return;
  }
  confirmDeleteBox("此操作将删除您所选编码词，是否继续？").then(async () => {
    let params;
    if (ifBatch) {
      params = {
        objectId,
        nameList: codeDictaryDataSelectionList.value
      };
    } else {
      params = {
        objectId,
        nameList: [row.name]
      };
    }
    let res = await httpPost(
      "/config/encoding/dictionary/encoding/delete",
      params
    );
    if (res.code == 0) {
      ElMessage.success("删除成功");
      getCodeDictionaryDataList();
    }
  });
};
// 分页信息
const paginationState = reactive({
  pageNum: 1,
  pageSize: 10,
  pageSizeList: [10, 20, 30, 40],
  total: 0
});
// 改变页面容纳数据量
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getCodeDictionaryDataList();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getCodeDictionaryDataList();
};

onMounted(() => {
  getCodeDictionaryDataList();
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
.search-input {
  width: 216px;
}
.search-conditon-input {
  width: 116px;
  margin: 0 16px;
}
</style>
