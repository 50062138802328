<template>
  <el-dialog v-model="DLGVisible" :before-close="closeDLG" class="base-dialog">
    <template #header>
      <BaseDialogTitle :title="DLGTitle" :item-name="DLGDictionaryName" />
    </template>
    <el-form
      ref="formRef"
      :model="form"
      :rules="formRules"
      label-width="120px"
      style="width: 90%"
    >
      <el-form-item prop="word" label="词组名称：">
        <el-input
          :disabled="formType === 'EDIT'"
          v-model="form.word"
          placeholder="请输入词组名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="natureString" label="词性：">
        <el-select
          v-model="form.natureString"
          class="search-conditon-select"
          @change="handleSelectChange"
          placeholder="请选择词性"
        >
          <el-option
            v-for="natureItem in natureList"
            :key="natureItem.label"
            :label="natureItem.label"
            :value="natureItem.label"
          />
        </el-select>
        <el-input
          v-model="customNature"
          placeholder="请输入自定义词性名称"
          class="custom-input"
          clearable
          v-if="form.natureString === '自定义'"
        ></el-input>
      </el-form-item>
      <el-form-item prop="weight" label="权重：">
        <el-input-number
          v-model="form.weight"
          :min="0"
          size="large"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button
          type="primary"
          @click="handleClickConfirm"
          :disabled="saveButtonDisable"
        >
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  nextTick
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import { Debounce } from "@/utils/utils";
import { ElMessage } from "element-plus";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR,
  ERROR_CODE_LANGUAGE_ERROR
} from "@/constant/error_code.js";
const emits = defineEmits(["submit"]);
const route = useRoute();
// 表格相关
const form = ref({
  word: "",
  weight: 0,
  natureString: ""
});
const customNature = ref("");
const natureStringOri = ref(null); // 原始词性
const formRef = ref(null);
let formType; //新增or编辑
const saveButtonDisable = ref(false);
const showSaveButtonDisable = () => {
  saveButtonDisable.value = true;
};
const hideSaveButtonDisable = () => {
  saveButtonDisable.value = false;
};
// 正数校验
// const validateScore = (rule, value, callback) => {
//   let reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
//   if (reg.test(value)) {
//     callback();
//   } else {
//     callback(new Error("情感值需为正数"));
//   }
// };
const validateNature = (rule, value, callback) => {
  if (form.value.natureString === "") {
    callback(new Error("请选择词性"));
  } else if (
    form.value.natureString === "自定义" &&
    customNature.value === ""
  ) {
    callback(new Error("请输入自定义词性名称"));
  } else {
    callback();
  }
};
const formRules = ref({
  word: [{ required: true, message: "请输入词组名称", trigger: "change" }],
  weight: [
    { required: true, message: "请输入权重", trigger: "change" }
    // { validator: validateScore, trigger: "blur" }
  ],
  natureString: [
    { required: true, validator: validateNature, trigger: "blur" }
    // { validator: validateNature, trigger: "blur" }
  ]
});

// 重置表格
const resetForm = () => {
  formRef.value.resetFields();
  natureStringOri.value = null;
  form.value = {
    word: "",
    weight: 0,
    natureString: ""
  };
  formType = "";
};
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("新增自定义词");
console.log("----------", route);
const DLGDictionaryName = route.query.dictionaryName;
const openDLG = (type, params) => {
  getNatureList();
  formType = type;
  if (type == "EDIT") {
    DLGTitle.value = "编辑自定义词";
    form.value = JSON.parse(JSON.stringify(params));
    natureStringOri.value = form.value.natureString;
  } else {
    DLGTitle.value = "新增自定义词";
  }
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  nextTick(() => {
    resetForm();
  });
};
// 点击确认
const handleClickConfirm = () => {
  formRef.value.validate((valid) => {
    if (valid) {
      showSaveButtonDisable();
      handleClickAddSegmentationDictionaryData();
      // hideSaveButtonDisable();
    }
  });
};
// 获取词性
const natureList = ref([]);
const getNatureList = async () => {
  let res = await httpPost(
    `config/tokenize/dictionary/nature/get/list/${objectId}`
  );
  if (res.code == 0) {
    natureList.value = res.data;
    natureList.value.push({ value: "custom", label: "自定义" });
  }
};
// 新增自定义词
let objectId = route.params.dictionaryId;
const handleClickAddSegmentationDictionaryData = Debounce(async () => {
  // let natureArr = natureList.value.find((_) => _.label === form.value.nature);
  let params = {
    ...form.value,
    // natureCode: natureArr.value,
    natureStringOri: natureStringOri.value,
    objectId
  };
  // 如果是自定义的，就不传natureCode
  if (params.natureString === "自定义") {
    params.natureString = customNature.value;
  }
  // if (formType == "EDIT") {
  //   params.objectId = objectId;
  // }
  let res = await httpPost("/config/tokenize/dictionary/custom/save", params);
  if (res.code == 0) {
    if (formType == "ADD") {
      ElMessage.success("新增成功");
    } else {
      ElMessage.success("编辑成功");
    }
    emits("submit");
    closeDLG();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR ||
    res.code == ERROR_CODE_LANGUAGE_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    // row.ifShowLoading = false;
  }
  hideSaveButtonDisable();
});
const handleSelectChange = (val) => {
  if (val === "自定义") {
    customNature.value = "";
  }
};
defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
.custom-input {
  width: 40%;
  margin-left: 10px;
}
</style>
