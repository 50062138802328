<template>
  <!-- <el-check-tag
    class="tag-check"
    v-for="(item, index) in data"
    :checked="item.isChecked || checkedList.includes(item.name) ? true : false"
    :key="index"
    @click="handleClickTag(item, index)"
  > -->
  <el-check-tag
    class="tag-check"
    :style="ifEdit ? 'padding: 0px;position:relative;top:-1px' : ''"
    :checked="checked"
    :key="key"
    @click="handleClickTag"
    :disable-transitions="true"
  >
    <!-- <div class="tag-edit" v-if="inputEditVisible">
      <el-input
        ref="inputEditRef"
        class="tag-input"
        size="small"
        v-model="inputEditValue"
        :placeholder="oldValue"
        @keyup.enter="handleEditTag"
        @blur="handleEditTag"
      />
    </div> -->
    <!-- <div class="tag-item" v-else>
      <div class="tag-name">
        <el-tooltip :content="dataItem.name" placement="top">
          <div class="ellipsis-text">{{ dataItem.name }}</div>
        </el-tooltip>
      </div>
      <div class="tag-dropdown" v-if="dataItem.type === 1"> -->
    <!-- '全部'标签不显示图标 -->
    <!-- <el-dropdown>
          <span>
            <el-icon class="el-icon--right">
              <i class="iconfont icon-More" />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleDeleteTag(dataItem)">
                删除
              </el-dropdown-item>
              <el-dropdown-item @click="showEditInput(dataItem)">
                重命名
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div> -->
    <div class="tag-edit" v-if="ifEdit">
      <el-input
        ref="inputEditRef"
        class="tag-input"
        v-model="inputEditValue"
        :placeholder="label"
        @keyup.enter="handleEditTag"
        @blur="handleEditTag"
      />
    </div>
    <div class="tag-item" v-else>
      <div class="tag-name">
        <el-tooltip :content="label" placement="top">
          <div class="ellipsis-text">{{ label }}</div>
        </el-tooltip>
      </div>
      <div class="tag-dropdown" v-if="dropable">
        <!-- '全部'标签不显示图标 -->
        <el-dropdown>
          <span>
            <el-icon class="el-icon--right">
              <i class="iconfont icon-More" />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <slot name="dropdown-menu" />
              <!-- <el-dropdown-item @click="handleDeleteTag(dataItem)">
                删除
              </el-dropdown-item>
              <el-dropdown-item @click="showEditInput(dataItem)">
                重命名
              </el-dropdown-item> -->
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </el-check-tag>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  watch,
  defineExpose,
  nextTick
} from "vue";

const emits = defineEmits(["tag-click", "tag-edit", "inputRef"]);

const props = defineProps({
  //   //父组件的categoryListItem
  //   dataItem: {
  //     type: Object,
  //     default: () => {}
  //   }
  key: {
    type: Number,
    default: () => 0
  },
  label: {
    type: String,
    default: () => ""
  },
  ifEdit: {
    type: Boolean,
    default: () => false
  },
  dropable: {
    type: Boolean,
    default: () => false
  },
  checked: {
    type: Boolean,
    default: () => false
  }
});

//分类标签
const inputEditValue = ref("");
// const inputEditVisible = ref(false);
const inputEditRef = ref(null);
const oldValue = ref(props.label);
// const showEditInput = (item) => {
//   inputEditVisible.value = true;
//   oldValue.value = item.name;
//   nextTick(() => {
//     inputEditRef.value.input.focus();
//   });
// };
// 子组件向父组件发射事件
// const emitInputRef = () => {
//   emits("inputEditRef", inputEditRef);
// };

//触发父组件的事件分类标签接口调用
// const handleDeleteTag = (item) => {
//   emits("delete", item);
// };
const handleClickTag = () => {
  emits("tag-click");
};
const handleEditTag = () => {
  emits("tag-edit", oldValue, inputEditValue);
};
defineExpose({ inputEditRef });
</script>

<style lang="scss" scoped>
.tag-check {
  transition: none;
  margin-bottom: 10px;
  margin-left: 15px;
  line-height: 19px;
  :deep(.el-button) {
    border: 0px;
    background-color: #f5f7fa;
  }
  // .tag-dropdown {
  //   .el-icon--right {
  //     margin-left: 20px;
  //   }
  // }
}
.tag-edit {
  .tag-input {
    width: 100px;
  }
}
.tag-item {
  display: flex;
  .tag-name {
    max-width: 70px;
    text-align: center;
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .tag-dropdown {
    .icon-More {
      position: relative;
      top: 2px;
    }
  }
}
</style>
