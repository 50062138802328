<template>
  <PageTitle :title="title" />
  <CategoryDataSetTextTable :info="dictionaryInfo" />
</template>

<script setup>
import {
  onMounted,
  ref,
  defineProps,
  defineEmits,
  shallowRef,
  watch,
  nextTick
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import PageTitle from "@/components/base/PageTitle";
import CategoryDataSetTextTable from "./components/CategoryDataSetTextTable.vue";
const route = useRoute();
const title = route.query.DataSetName;
const objectId = ref(route.params.categoryTrainDatasetObjectId);
const dictionaryInfo = ref(null);
const getDictionaryInfo = async () => {
  let params = {
    objectId: objectId.value
  };
  let res = await httpPost("/config/category/dataset/get/name", params);
  dictionaryInfo.value = res.data;
};
getDictionaryInfo();
// 面包屑
const emits = defineEmits(["setBreadcrumbList"]);
const setBreadcrumbList = () => {
  let BreadcrumbList = [
    { title: "配置", to: { name: "AnalysisConfigIndex" } },
    { title: "分类训练集", to: { name: "CategoryDataSetIndex" } },
    { title: title },
    { title: "数据" }
  ];
  emits("setBreadcrumbList", BreadcrumbList);
};
setBreadcrumbList();
</script>

<style lang="scss" scoped></style>
