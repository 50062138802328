<template>
  <PageTitle :title="title" />
  <EventsManageForm />
</template>

<script setup>
import { onMounted, defineEmits, ref } from "vue";
import { useRoute } from "vue-router";
import EventsManageForm from "./components/EventsManageForm.vue";
import PageTitle from "@/components/base/PageTitle";
const route = useRoute();
// 面包屑
const dictionaryName = ref("");
dictionaryName.value = route.query.dictionaryName;
const title = ref("");
if (route.path.split("/")[2] == "edit") {
  title.value = "编辑事件";
} else {
  title.value = "新增事件";
}
const emits = defineEmits(["setBreadcrumbList"]);
const setBreadcrumbList = () => {
  let BreadcrumbList = [
    { title: "配置", to: { name: "AnalysisConfigIndex" } },
    { title: "事件词典", to: { name: "EventDictionaryIndex" } },
    { title: dictionaryName },
    { title: "数据" },
    { title: title }
  ];
  emits("setBreadcrumbList", BreadcrumbList);
};
setBreadcrumbList();
</script>

<style lang="scss" scoped></style>
