<template>
  <div class="list-table">
    <div class="option-bar">
      <div>
        <el-select
          class="select-input"
          v-model="keyType"
          placeholder="请选择同义词类型"
          @change="getSynonymWordsList"
        >
          <el-option
            v-for="synonymItem in synonymOptions"
            :key="synonymItem.value"
            :label="synonymItem.label"
            :value="synonymItem.value"
          ></el-option>
        </el-select>
        <el-input
          class="search-input"
          v-model="keyword"
          placeholder="请输入同义词名称"
          @keyup.enter="getSynonymWordsList"
          @input="getSynonymWordsList"
          clearable
        >
          <template #suffix>
            <el-icon @click="getSynonymWordsList" :size="14">
              <Search />
            </el-icon>
          </template>
        </el-input>
      </div>
      <div class="button-group">
        <span>
          <el-button v-if="!ifSystemUser">全部导出</el-button>
          <el-dropdown @command="handleClickBatchCommand" v-if="!ifSystemUser">
            <el-button>
              批量操作
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="batchImport">
                  批量导入
                </el-dropdown-item>
                <el-dropdown-item command="batchExport">
                  批量导出
                </el-dropdown-item>
                <el-dropdown-item command="batchDelete">
                  批量删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-button
            type="primary"
            @click="handleClickAddSynonymWords"
            v-if="!ifSystemUser"
          >
            新增
          </el-button>
        </span>
      </div>
    </div>
    <el-table
      :data="synonymWordsList"
      style="width: 100%"
      header-cell-class-name="list-table-header"
      @selection-change="handleSelectionChange"
      v-loading="tableLoading"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="name" label="同义词名称">
        <template #default="scope">
          <TextEditor
            :text="scope.row.name"
            :editable="scope.row.userId != SYSTEM_USER_ID"
            v-model:edit="scope.row.ifEdit"
            v-model:loading="scope.row.ifShowLoading"
            @save="(text) => handleClickSaveListItemName(text, scope.row)"
            @cancel="handleClickCancelRename(scope)"
          ></TextEditor>
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        label="同义词类型"
        :formatter="(row) => (row.type === 1 ? '参与者同义词' : '行为同义词')"
      />
      <el-table-column prop="alias" label="同义词集" show-overflow-tooltip>
        <template #tooltip="{ row }">
          {{ row }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" v-if="!ifSystemUser">
        <template #default="scope">
          <div class="list-item-btn">
            <el-button
              link
              type="primary"
              @click="handleClickEditDataRecord(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              link
              type="primary"
              @click="handleClickDelete(false, scope.row)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
    <SynonymWordsDialogManage
      ref="synonymWordsDialogManageRef"
      @submit="getSynonymWordsList"
    />
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, defineProps, computed } from "vue";
import { useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { httpPost } from "@/api/httpService.js";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import TextEditor from "@/components/base/TextEditor.vue";
import SynonymWordsDialogManage from "./SynonymWordsDialogManage.vue";
import { ArrowDown, Search } from "@element-plus/icons-vue";
import { SYSTEM_USER_ID } from "@/constant/system";
import { Debounce } from "@/utils/utils";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
import { async } from "q";
const route = useRoute();

let objectId = route.params.dictionaryId;

const props = defineProps({
  info: {
    type: Object
  }
});

// 权限相关
// 该词典拥有者是否为系统
const ifSystemUser = computed(() => {
  console.log(props);
  if (props.info) {
    return props.info.userId == SYSTEM_USER_ID;
  } else {
    return true;
  }
});

// 表格loading效果
const tableLoading = ref(false);
const hideTableLoading = () => {
  tableLoading.value = false;
};
const showTableLoading = () => {
  tableLoading.value = true;
};

const keyword = ref("");
const keyType = ref("");
const synonymWordsList = ref([]);
//同义词类型
const synonymOptions = ref([
  {
    value: "",
    label: "全部"
  },
  {
    value: 1,
    label: "参与者同义词"
  },
  {
    value: 2,
    label: "行为同义词"
  }
]);
// 获取事件词典同义词数据列表
const getSynonymWordsList = async () => {
  showTableLoading();
  let params = {
    keyword: keyword.value,
    type: keyType.value,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize,
    objectId
  };
  let res = await httpPost("/config/event/dictionary/synonym/get/list", params);
  hideTableLoading();
  if (res.code == 0) {
    synonymWordsList.value = res.data.synonymList;
    paginationState.total = res.data.total;
  }
};

const synonymWordsDialogManageRef = ref(null);
// 编辑弹框
const handleClickEditDataRecord = (row) => {
  synonymWordsDialogManageRef.value.openDLG("EDIT", row);
};
// 新增弹框
const handleClickAddSynonymWords = (val) => {
  synonymWordsDialogManageRef.value.openDLG("ADD");
};

const synonymWordsSelectionList = ref([]);
// 多选操作
const handleSelectionChange = (val) => {
  synonymWordsSelectionList.value = [];
  val.forEach((item) => {
    synonymWordsSelectionList.value.push(item.name);
  });
};
// 批量操作
const handleClickBatchCommand = (command) => {
  switch (command) {
    case "batchImport":
      handleClickBatchImport();
      break;
    case "batchExport":
      handleClickBatchExport();
      break;
    case "batchDelete":
      handleClickDelete(true, null);
      break;
  }
};
// 批量导入
const synonymWordsDialogImportRef = ref(null);
const handleClickBatchImport = () => {
  synonymWordsDialogImportRef.value.openDLG();
};
// 批量导出
const handleClickBatchExport = () => {};
// 删除或批量删除
const handleClickDelete = (ifBatch, row) => {
  if (ifBatch && synonymWordsSelectionList.value.length == 0) {
    ElMessage.warning("请选择您要删除的同义词");
    return;
  }
  confirmDeleteBox("此操作将删除您所选同义词，是否继续？").then(async () => {
    let params;
    if (ifBatch) {
      params = {
        objectId,
        synonymNameList: synonymWordsSelectionList.value
      };
    } else {
      params = {
        objectId,
        synonymNameList: [row.name]
      };
    }
    let res = await httpPost("/config/event/dictionary/synonym/delete", params);
    if (res.code == 0) {
      ElMessage.success("删除成功");
      getSynonymWordsList();
    }
  });
};
// 保存重命名操作
const handleClickSaveListItemName = Debounce(async (text, row) => {
  if (!text) {
    ElMessage.warning("名称不能为空");
    row.ifShowLoading = false;
    return;
  }
  let params = {
    objectId: objectId,
    oldSynonymName: row.name,
    name: text,
    type: row.type,
    alias: row.alias
  };
  let res = await httpPost("/config/event/dictionary/synonym/save", params);
  if (res.code == 0) {
    //保存成功,更新单行，并重新获取最新列表数据
    ElMessage.success(res.message);
    row.name = text;
    row.ifEdit = false;
    getSynonymWordsList();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    row.ifShowLoading = false;
  } else {
    //系统未知错误,由外部捕获错误，然后重新获取列表数据
    getSynonymWordsList();
  }
});
// 如不想重命名同义词，则点击单元格取消
const handleClickCancelRename = (scope) => {
  let index = scope.$index;
  if (!scope.row.name) {
    synonymWordsList.value.splice(index, 1);
  }
};

// 分页信息
const paginationState = reactive({
  pageNum: 1,
  pageSize: 10,
  pageSizeList: [10, 20, 30, 40],
  total: 0
});
// 改变页面容纳数据量
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getSynonymWordsList();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getSynonymWordsList();
};

onMounted(() => {
  getSynonymWordsList();
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
.search-input {
  width: 216px;
}
.select-input {
  width: 180px;
  margin-right: 20px;
}
</style>
